import React from "react";

import Errorbutton from "../Assests/images/Error.png"



class Error404 extends React.Component {
  constructor(props) {
    super(props);

  }
  render() {
    return (
      <div className="App">



        <section className=" internal-page-padding-top pb-5">
          <div className="container">
            <div className="row">
              <div className="col text-center">
                <img src={"https://www.d-ecosystem.io/static/media/comingsoon.94b55f65e6042aa482e4.png"} alt="Error404" className="Error404-image mx-auto" />
                <br />
                <a href="/"><img src={Errorbutton} alt="Error404" className="Error404-image-home-button mx-auto top-0 mt-5" /></a>
              </div>
            </div>
          </div>
        </section>





      </div>
    );
  }
}

export default Error404;