import React, { useState, useEffect } from "react";
import { MdOutlineChevronRight } from "react-icons/md";
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";
import { CiShare1 } from "react-icons/ci";
// import Pancakeimage from "../src/Asset/images/pancakeimage.png"
import Pancakeimage from "../../src/Assests/images/dimage.svg";
import Rosepan from "../../src/Assests/images/Group 2.png";
import yellowpan from "../../src/Assests/images/Group 3.png";
// import yellowpan from "../src/Asset/images/Group 3.png"
import Navbar from "../Pages/Separate/Navbar";
import Leafimage from "../Assests/images/leaf.png";
import config from "../config/config";
import axios from "axios";
import { CiSearch } from "react-icons/ci";
import Dropdown from "react-bootstrap/Dropdown";
import { data, error } from "jquery";
import Spinner from "react-bootstrap/Spinner";

function Leadership() {
  const [page, setPage] = useState(1);
  const [ranklist, setRanklist] = useState([]);
  const [pagesize, setPageSize] = useState(5);
  const [selectvalue, setSelectvalue] = useState("");
  const [inputText, setInputText] = useState();
  const [usercheck, setUsercheck] = useState("");
  const [liveprice, setliveprice] = useState(0);
  const [findaddress, setfindaddress] = useState("");
  const [issearchloader, setIssearchloader] = useState(false);
  const [betarray, setBetarray] = useState([]);
  const [userdetails, setUserdetails] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [sizerecords, setSizerecords] = useState(0);
  const [isviewloader, setIsviewloader] = useState(false);

  const getchangevalue = async () => {
    try {
      console.log("input", inputText);
      await axios
        .post(`${config.BACKEND_PREDICITON}/addrcheck`, {
          useraddress: inputText,
        })
        .then((response) => {
          setIssearchloader(response.data.status);
          console.log("response", response.data);
          setUsercheck(response.data.status);
          setfindaddress(response.data.useraddress);
          console.log("checkadrr++++", findaddress);
          console.log("loadrecheck----", issearchloader);
        })
        .catch((err) => {
          console.log("error", err);
          setUsercheck(false);
        });
    } catch (error) {
      console.log("getchangevalue", error);
    }
  };

  useEffect(() => {
    getchangevalue();
  }, [inputText]);

  useEffect(() => {
    if (usercheck === true) {
      console.log("status===", usercheck);
      displayText();
    } else {
      console.log("status----", usercheck);
      displayText();
    }
  }, [usercheck]);

  const handlelastfive = async () => {
    try {
      setUsercheck(false);
      setInputText("");
      console.log("find address", findaddress);
      await axios
        .post(`${config.BACKEND_PREDICITON}/lastfive`, {
          useraddress: findaddress,
        })
        .then((response) => {
          console.log("lastfives", response.data.roundstatus);
          console.log("userdetails====", response.data.alldata);
          setBetarray(response.data.roundstatus);
          setUserdetails(response.data.alldata);
          console.log("lllllll", Array.isArray(userdetails));
        })
        .catch((error) => {
          console.log("error", error);
        });
    } catch (error) {
      console.log("handlelastfive---", error);
    }
  };

  const rankRound = async () => {
    try {
      // setPage(1)
      const response = await axios.get(
        `${config.BACKEND_PREDICITON}/getroundplay?page=${page}&pageSize=${pagesize}`
      );
      console.log("response", response.data.data);
      console.log("response-------", response.data.totalRecords);
      console.log("response-=====", response.data.pageSizerecords);
      const res = response.data.data;
      setSizerecords(response.data.pageSizerecords);
      setRanklist(res);
      setTotalRecords(response.data.totalRecords);
    } catch (error) {
      console.log("error" + error);
    }
  };

  const rankwinRate = async () => {
    try {
      // setPage(1)
      const response = await axios.get(
        `${config.BACKEND_PREDICITON}/getwinrate?page=${page}&pageSize=${pagesize}`
      );
      console.log("response", response.data.data);
      const res = response.data.data;
      console.log("res", res);
      setRanklist(res);
      setSizerecords(response.data.pageSizerecords);
      setTotalRecords(response.data.totalRecords);
    } catch (error) {
      console.log("error" + error);
    }
  };

  const rankDcxwinnings = async () => {
    try {
      // setPage(1)
      const response = await axios.get(
        `${config.BACKEND_PREDICITON}/getnetwining?page=${page}&pageSize=${pagesize}`
      );
      console.log("response", response.data.data);
      const res = response.data.data;
      // for (let i = 0; i < res.length; i++) {
      //   const val = res.slice(0, 2)
      //   console.log("firstval", val);

      //   const val2 = res.slice(3)
      //   console.log("seconds", val2);
      // }
      setSizerecords(response.data.pageSizerecords);
      setRanklist(res);
      setTotalRecords(response.data.totalRecords);
    } catch (error) {
      console.log("error" + error);
    }
  };

  const getprice = async () => {
    const response = await axios.get(`${config.BACKEND_URL}/getPriceApi`);
    const result = response.data;
    const onedcxpriceusd = result;
    setliveprice(onedcxpriceusd);
  };
  getprice();

  useEffect(() => {
    rankRound();
    console.log("initial call round play");
  }, [page]);

  async function handledropdown(event) {
    const selectval = event.target.value;
    // setSelectvalue(selectval)
    console.log("checkvalue", event.target.value);

    if (selectval === "2") {
      setPage(1);
      await rankDcxwinnings();
      console.log("clicked Net Winnings");
      // console.log("selectval",selectvalue);
    } else if (selectval == "3") {
      setPage(1);
      await rankwinRate();
      console.log("clicked Win Rate");
      // console.log("selectval",selectvalue);
    } else if (selectval == "Rounds Played") {
      setPage(1);
      await rankRound();
      console.log("round played");
      // console.log("selectval",selectvalue);
    } else {
    }
    setSelectvalue(selectval);
    console.log("selectvalue", selectvalue);
  }

  const handleNextPage = () => {
    setIsviewloader(true);
    setPage((prevPage) => prevPage + 1);
    setTimeout(() => {
      setIsviewloader(false);
    }, 2000);
  };

  const lastfivestatus = async (addr) => {
    console.log("lastaddr", addr);
    try {
      const response = await axios.post(
        `${config.BACKEND_PREDICITON}/lastfive?useraddress=${addr}`
      );
      // console.log("response", response.data);
      if (response.data) {
        setBetarray(response.data.roundstatus);
        setUserdetails(response.data.alldata);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const buttonStyle = {
    background: "none",
    border: "none",
    padding: "0",
    button: "none",
  };

  const displayText = () => {
    const outputElement = document.getElementById("output");
    console.log("part1", outputElement);
    if (usercheck == true) {
      const valueaddr = (outputElement.innerText = inputText);
      console.log("valueaddr", valueaddr);
      // classadd.classList.add()
      // classadd.add("leadership-output")
      outputElement.classList.add("leadership-output");
      setTimeout(() => {
        setIssearchloader(false);
      }, 1000);
    } else if (usercheck == false) {
      outputElement.innerText = "";
      outputElement.classList.remove("leadership-output");
      // classadd.remove("leadership-output")
      // outputElement.classList.remove("leadership-output");
    } else {
      console.log("errorsss");
    }
  };
  return (
    <div className="leadership">
      <Navbar />
      <section className="mx-0  leadership-header py-5">
        <div className="container">
          <div className="row">
            <div className="">
              <div className="leadership-header-child">
                <div className="home-prediction">
                  <a
                    className="me-2 fw-bold leader-home-sec-lk"
                    href="/dashboard"
                  >
                    Home{" "}
                    <MdOutlineChevronRight
                      style={{ color: "grey" }}
                      className="ms-3"
                    />
                  </a>
                  <a className="me-2 fw-bold leader-home-sec-lk" href="/win">
                    Prediction{" "}
                    <MdOutlineChevronRight
                      style={{ color: "grey" }}
                      className="ms-3"
                    />
                  </a>
                  <a
                    className="fw-bold leader-board-sec-lk"
                    style={{ color: "#280d5f" }}
                  >
                    Leaderboard
                  </a>
                </div>
                <h1
                  className="display-3 fw-bold mt-3"
                  style={{ letterSpacing: "3px" }}
                >
                  Leaderboard
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className=" mb-5 leadership-main-sec-1">
        <div className="container">
          <p
            className="pt-4 fw-bold"
            style={{ color: "white", fontSize: "13px" }}
          >
            RANK BY
          </p>
          <div className="row py-3 d-flex justify-content-between">
            <div className="col-lg-3 mb-3 mb-lg-0">
              <div class="">
                <select
                  class="form-select leadership-rounds-play-1 py-2 rrr-1"
                  // style={{ borderRadius: "11px" }}
                  aria-label="Default select example"
                  onChange={handledropdown}
                >
                  <option selected className="py-2 bg-transparent">
                    Rounds Played
                  </option>
                  {/* <option className='text white ' value="1" >Rounds Played</option> */}
                  <option className="text white " value="2">
                    Net Winnings
                  </option>
                  <option className="text white " value="3">
                    Win Rate
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-3 ttttt-1 mt-0">
              <div class="col-auto">
                {/* <label for="inputPassword6" class="col-form-label">Password</label> */}
              </div>
              <div class="col d-flex align-items-center">
                <div class="input-group mb-3">
                  <input
                    type="text"
                    placeholder="search address"
                    id="inputPassword6"
                    class="form-control leadership-input-sec-1 py-2 chatinput"
                    aria-describedby="passwordHelpInline"
                    value={inputText}
                    onChange={(event) => {
                      setInputText(event.target.value);
                    }}
                    spellcheck="false"
                  />
                  {issearchloader ? (
                    <span
                      onClick={displayText}
                      className="leadership-search-icon pt-2 pe-2 leadership-input-sec-1"
                    >
                      <div class="spinner-border text-light" role="status">
                        <span class="visually-hidden ">Loading...</span>
                      </div>
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div
                id="output"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                style={{ cursor: "pointer" }}
                onClick={handlelastfive}
              ></div>
            </div>
          </div>
          {/* <button
            type="button"
            class="btn btn-primary"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
          >
            modal
          </button>  */}
        </div>
      </section>
      {/* modal */}
      <section className="leadership-modal-section">
        <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title fs-5" id="exampleModalLabel">
                  <img src={Pancakeimage} className="leader-tab-img-001 me-2" />{" "}
                  {userdetails && Object.keys(userdetails).length > 0 ? (
                    <a
                      href={`https://mainnet-explorer.d-ecosystem.io/address/${userdetails.useraddress}`}
                      target="_blank"
                    >
                      <span className="leader-modal-title fw-bold mt-auto">
                        {userdetails.useraddress.substring(0, 5)}...
                        {userdetails.useraddress.substring(
                          userdetails.useraddress.length - 4
                        )}{" "}
                        <CiShare1 />
                      </span>
                    </a>
                  ) : (
                    <span className="leader-modal-title fw-bold mt-auto">
                      0x31...e123 <CiShare1 />
                    </span>
                  )}
                </h1>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <div className="container-fluid">
                  <div className="row row-cols-4 bottom-border">
                    <div className="col">
                      <p
                        className=" text-white"
                        style={{ color: "#7a6eaa", fontSize: "11px" }}
                      >
                        NET WINNINGS
                      </p>
                      {userdetails && Object.keys(userdetails).length > 0 ? (
                        <div>
                          <h6
                            className="leader-tab-num text-center mb-auto fw-bold"
                            style={{ color: "white" }}
                          >
                            {userdetails.netwining.toFixed(5)}
                          </h6>
                          <p
                            className="leader-sec-tab-num text-center "
                            style={{ color: "#4c88fd" }}
                          >
                            ~$
                            {(
                              userdetails.netwining * liveprice.toFixed(6)
                            ).toFixed(5)}
                          </p>
                        </div>
                      ) : (
                        <div>
                          <h6
                            className="leader-tab-num text-end mb-auto fw-bold"
                            style={{ color: "white" }}
                          >
                            -1,693.65
                          </h6>
                          <p
                            className="leader-sec-tab-num text-end "
                            style={{ color: "#4c88fd" }}
                          >
                            ~$527,133
                          </p>
                        </div>
                      )}
                    </div>
                    <div className="col ">
                      <p
                        className=" text-white"
                        style={{ color: "#7a6eaa", fontSize: "11px" }}
                      >
                        WIN RATE
                      </p>
                      {userdetails && Object.keys(userdetails).length > 0 ? (
                        <h6
                          className="leader-tab-num  mb-auto fw-bold"
                          style={{ color: "white" }}
                        >
                          {userdetails.winrate.toFixed(2)}%
                        </h6>
                      ) : (
                        <h6
                          className="leader-tab-num  mb-auto fw-bold"
                          style={{ color: "white" }}
                        >
                          42.8%
                        </h6>
                      )}
                    </div>
                    <div className="col ">
                      <p
                        className=" text-white"
                        style={{ color: "#7a6eaa", fontSize: "11px" }}
                      >
                        ROUNDS WON
                      </p>
                      {userdetails && Object.keys(userdetails).length > 0 ? (
                        <h6
                          className="leader-tab-num  mb-auto fw-bold"
                          style={{ color: "white" }}
                        >
                          {userdetails.roundwon}
                        </h6>
                      ) : (
                        <h6
                          className="leader-tab-num  mb-auto fw-bold"
                          style={{ color: "white" }}
                        >
                          3
                        </h6>
                      )}
                    </div>
                    <div className="col">
                      <p
                        className=" text-white"
                        style={{ color: "#7a6eaa", fontSize: "11px" }}
                      >
                        ROUNDS PLAYED
                      </p>
                      {userdetails && Object.keys(userdetails).length > 0 ? (
                        <h6
                          className="leader-tab-num  mb-auto fw-bold"
                          style={{ color: "white" }}
                        >
                          {userdetails.roundplay}
                        </h6>
                      ) : (
                        <h6
                          className="leader-tab-num  mb-auto fw-bold"
                          style={{ color: "white" }}
                        >
                          4
                        </h6>
                      )}
                    </div>
                  </div>

                  <h6 className="pt-4" style={{ color: "#4c88fd" }}>
                    LAST 5 BETS
                  </h6>
                  <div className="card-back">
                    <div className="row row-cols-3 ">
                      <div className="col">
                        <p
                          className="leader-sec-tab-num "
                          style={{
                            color: "#4c88fd",
                          }}
                        >
                          ROUNDS
                        </p>
                      </div>
                      <div className="col text-center">
                        <p
                          className="leader-sec-tab-num  "
                          style={{
                            color: "#4c88fd",
                          }}
                        >
                          DIRECTION
                        </p>
                      </div>
                      <div className="col text-end">
                        <p
                          className=" leader-sec-tab-num "
                          style={{
                            color: "#4c88fd",
                          }}
                        >
                          WINNINGS
                        </p>
                      </div>
                    </div>
                    {Array.isArray(betarray) && betarray.length > 0 ? (
                      betarray.map((data) => (
                        <div className="row row-cols-3">
                          <div className="col">
                            <h6
                              className="leader-tab-num  fw-bold"
                              style={{ color: "white" }}
                            >
                              {data.Round}
                            </h6>
                          </div>
                          <div className="col text-center">
                            {data.Position === "UP" ? (
                              <button type="button" className="btn-back-win-up">
                                <AiOutlineArrowUp className="win-icon-4" />{" "}
                                {data.Position}
                              </button>
                            ) : (
                              <button
                                type="button"
                                className="btn-back-win-down"
                              >
                                <AiOutlineArrowDown className="win-icon-4" />{" "}
                                {data.Position}
                              </button>
                            )}
                          </div>
                          <div className="col text-end">
                            <h6
                              className="leader-tab-num mb-auto fw-bold"
                              style={{ color: "white" }}
                            >
                              {data && data.winstatus === "WIN" ? (
                                <span style={{ color: "#1fc7d4" }}>
                                  +{data.reward && data.reward.toFixed(5)}
                                </span>
                              ) : (
                                <span style={{ color: "#1fc7d4" }}>
                                  -{data.betamount && data.betamount.toFixed(5)}
                                </span>
                              )}
                            </h6>

                            <p
                              className="leader-sec-tab-num  "
                              style={{ color: "#4c88fd" }}
                            >
                              ~$
                              {data.winstatus == "WIN"
                                ? (data.reward * liveprice.toFixed(6)).toFixed(
                                    5
                                  )
                                : (
                                    data.betamount * liveprice.toFixed(6)
                                  ).toFixed(5)}
                            </p>
                          </div>
                        </div>
                      ))
                    ) : (
                      <h4>No Data...</h4>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* modal end */}

      {/* <====================================> */}

      <section className="my-4">
        <div className="container ">
          <div className="row gy-3 d-flex">
            {Array.isArray(ranklist) && ranklist.length > 0
              ? ranklist.slice(0, 3).map((data, index) => (
                  <div className="col-lg-4  leadership-cards" key={index}>
                    <div className="leadership-points-card-2 position-relative p-4 overflow-hidden">
                      <div
                        className="leade-clr-line-1"
                        // style={{ backgroundColor: "#ffc700" }}
                      >
                        <span className="text-white leader-cnum-pnt-1 fw-bold">
                          #{index + 1}
                        </span>
                      </div>
                      <div className="text-center position-relative mt-2">
                        {/* {/* <img
                          src={Pancakeimage}
                          className="leader-tab-img-001 me-2"
                        /> */}

                        <Dropdown>
                          <Dropdown.Toggle
                            id="dropdown-basic"
                            style={buttonStyle}
                          >
                            <svg
                              viewBox="0 0 20 20"
                              color="gold"
                              width="32px"
                              class="leader-leaf-left"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M4.68179 7.04592C5.06573 7.78505 5.47899 8.47752 5.91436 9.12605C4.76866 8.20293 3.15483 7.94918 1.72791 8.60664L0.865295 9.00408L0.949991 9.1879C1.87444 11.1943 4.25037 12.0714 6.25678 11.147L7.1194 10.7495C7.7588 11.5306 8.42708 12.2337 9.10661 12.8671C7.54917 12.0868 5.60297 12.3946 4.36752 13.7424L4.07604 14.0604L5.17158 15.0646C6.8001 16.5573 9.33037 16.4472 10.8231 14.8187L11.0984 14.5183C11.8534 15.0744 12.6001 15.5528 13.3138 15.9628C13.5418 16.0939 13.7667 16.218 13.9874 16.3354C12.2933 15.9571 10.485 16.7202 9.6092 18.3189L9.47337 18.5669L10.514 19.137C12.4515 20.1983 14.8825 19.4881 15.9439 17.5507L16.0715 17.3177C16.4836 17.4877 16.862 17.6297 17.1977 17.747C17.6961 17.9213 18.1016 18.0417 18.3855 18.1191L18.8453 18.2339C19.2507 18.3192 19.6484 18.0598 19.7338 17.6545C19.819 17.2494 19.56 16.8519 19.1551 16.7662L19.1524 16.7657C19.1551 16.7662 19.0729 16.7518 18.7802 16.6719C18.527 16.6029 18.1551 16.4928 17.6927 16.3311C17.1224 16.1317 16.416 15.8546 15.6253 15.4802L15.9997 14.8317C17.1043 12.9186 16.4488 10.4722 14.5356 9.36762L13.8989 9.00001L13.4324 9.80801C12.4895 11.4411 12.8291 13.4627 14.1387 14.7066C14.1128 14.6919 14.0869 14.6771 14.0609 14.6622C12.797 13.9361 11.4285 12.9847 10.1078 11.7498L10.5391 11.324C12.1111 9.77192 12.1274 7.23931 10.5753 5.66723L9.93826 5.02195L9.41242 5.5411C8.11055 6.82638 7.87565 8.78411 8.71479 10.3119C7.73795 9.1976 6.82187 7.90232 6.03634 6.39938C7.08943 4.4961 6.42732 2.09216 4.53566 1.00001L3.4039 0.346588L2.82248 1.35364C1.71791 3.26682 2.37341 5.71318 4.28658 6.81775L4.68179 7.04592Z"></path>
                            </svg>
                            <span>
                              <img
                                src={Pancakeimage}
                                className="leader-tab-img-001 me-2"
                              />
                            </span>
                            {/* <img src={Leafimage} className="leader-leaf-img-01" /> */}
                            <svg
                              viewBox="0 0 20 20"
                              color="gold"
                              width="32px"
                              class="leader-leaf-right"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M15.3182 7.04592C14.9343 7.78505 14.521 8.47752 14.0856 9.12605C15.2313 8.20293 16.8452 7.94918 18.2721 8.60664L19.1347 9.00408L19.05 9.1879C18.1256 11.1943 15.7496 12.0714 13.7432 11.147L12.8806 10.7495C12.2412 11.5306 11.5729 12.2337 10.8934 12.8671C12.4508 12.0868 14.397 12.3946 15.6325 13.7424L15.924 14.0604L14.8284 15.0646C13.1999 16.5573 10.6696 16.4472 9.17692 14.8187L8.90162 14.5183C8.1466 15.0744 7.39992 15.5528 6.68625 15.9628C6.45816 16.0939 6.23335 16.218 6.01259 16.3354C7.70668 15.9571 9.51501 16.7202 10.3908 18.3189L10.5266 18.5669L9.486 19.137C7.54854 20.1983 5.11749 19.4881 4.05611 17.5507L3.92849 17.3177C3.51637 17.4877 3.13804 17.6297 2.80235 17.747C2.30389 17.9213 1.89839 18.0417 1.61448 18.1191L1.15466 18.2339C0.749332 18.3192 0.351572 18.0598 0.26624 17.6545C0.180958 17.2494 0.440012 16.8519 0.844932 16.7662L0.847556 16.7657C0.844932 16.7662 0.927067 16.7518 1.21981 16.6719C1.47301 16.6029 1.84485 16.4928 2.30733 16.3311C2.87756 16.1317 3.58397 15.8546 4.3747 15.4802L4.0003 14.8317C2.89573 12.9186 3.55123 10.4722 5.4644 9.36762L6.10112 9.00001L6.56763 9.80801C7.51049 11.4411 7.1709 13.4627 5.86133 14.7066C5.88719 14.6919 5.9131 14.6771 5.93906 14.6622C7.20296 13.9361 8.57146 12.9847 9.89223 11.7498L9.46094 11.324C7.88886 9.77192 7.87263 7.23931 9.42468 5.66723L10.0617 5.02195L10.5876 5.5411C11.8895 6.82638 12.1243 8.78411 11.2852 10.3119C12.2621 9.1976 13.1781 7.90232 13.9637 6.39938C12.9106 4.4961 13.5727 2.09216 15.4643 1.00001L16.5961 0.346588L17.1775 1.35364C18.2821 3.26682 17.6266 5.71318 15.7134 6.81775L15.3182 7.04592Z"></path>
                            </svg>
                            <p
                              className="position-absolute fw-bold leadership-card-points"
                              style={{ color: "#1fc7d4" }}
                            >
                              {data.useraddress.substring(0, 5)}...
                              {data.useraddress.substring(
                                data.useraddress.length - 4
                              )}
                            </p>
                          </Dropdown.Toggle>

                          <Dropdown.Menu
                            style={{
                              backgroundColor: "rgb(22 49 129)",
                              color: "#c5c55b",
                            }}
                          >
                            <Dropdown.Item
                              href="#/action-1"
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModal"
                              onClick={() => lastfivestatus(data.useraddress)}
                            >
                              View Status
                            </Dropdown.Item>
                            <Dropdown.Item
                              href={`https://mainnet-explorer.d-ecosystem.io/address/${data.useraddress}`}
                              target="_blank"
                            >
                              View On Explorer
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      <div className="">
                        <div className="d-flex align-items-center justify-content-between my-1">
                          <p
                            className="mb-auto text-white"
                            style={{ color: "#7a6eaa", fontSize: "12px" }}
                          >
                            Win Rate
                          </p>
                          <h6
                            className="fw-bold text-white"
                            style={{ color: "#280d5f" }}
                          >
                            {data.winrate.toFixed(2)}%
                          </h6>
                        </div>
                        <div className="d-flex justify-content-between my-1">
                          <div>
                            <p
                              className="mb-auto text-white"
                              style={{ color: "#7a6eaa", fontSize: "12px" }}
                            >
                              Net Winnings (DCX)
                            </p>
                          </div>
                          <div className="">
                            <h5
                              className="mb-auto fw-bold"
                              style={{ color: "#1fc7d4", fontSize: "16px" }}
                            >
                              {data.netwining.toFixed(5)}
                            </h5>
                            <p
                              className="mt-auto text-end text-white"
                              style={{ color: "#7a6eaa", fontSize: "12px" }}
                            >
                              ~$
                              {(data.netwining * liveprice.toFixed(6)).toFixed(
                                5
                              )}
                            </p>
                          </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-between mt-1">
                          <p
                            className="mb-auto text-white"
                            style={{ color: "#7a6eaa", fontSize: "12px" }}
                          >
                            Rounds Won
                          </p>
                          <h6
                            className="fw-bold text-white"
                            style={{ color: "#280d5f", fontSize: "16px" }}
                          >
                            {data.roundwon}/{data.roundplay}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              : ""}
          </div>
        </div>
      </section>

      {/* <============================> */}
      <section className="my-5 leader-main-table-box">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <table className="leadership-tab-1">
                <thead>
                  <tr>
                    <th scope="col" className="leader-sno-child-1">
                      Round Id
                    </th>
                    <th
                      scope="col"
                      className=" leadership-tab-child-1"
                      style={{ color: "rgb(31, 199, 212)" }}
                    >
                      {" "}
                      USER
                    </th>
                    <th
                      scope="col"
                      className=" leadership-tab-child-2"
                      style={{ color: "rgb(31, 199, 212)" }}
                    >
                      NET WINNINGS (DCX)
                    </th>
                    <th
                      scope="col"
                      className=" leadership-tab-child-3"
                      style={{ color: "rgb(31, 199, 212)" }}
                    >
                      WIN RATE
                    </th>
                    <th
                      scope="col"
                      className=" leadership-tab-child-4"
                      style={{ color: "rgb(31, 199, 212)" }}
                    >
                      ROUNDS WON
                    </th>
                    <th
                      scope="col"
                      className=" leadership-tab-child-5"
                      style={{ color: "rgb(31, 199, 212)" }}
                    >
                      ROUNDS PLAYED
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(ranklist) && ranklist.length > 0
                    ? ranklist.slice(3).map((data, index) => (
                        <tr key={index}>
                          <td
                            className="text-center fw-bold"
                            style={{ fontSize: "16px", color: "white" }}
                          >
                            #{index + 4}
                          </td>
                          <td
                            data-label="Account"
                            className="leader-smd-align-01"
                          >
                            <div className="d-flex  align-items-center leader-cross-small-end">
                              <Dropdown>
                                <Dropdown.Toggle
                                  id="dropdown-basic"
                                  style={buttonStyle}
                                >
                                  <div className="d-flex align-items-center">
                                    <img
                                      src={Pancakeimage}
                                      className="leader-tab-img me-2"
                                    />
                                    <h6 className="leader-tab-img-title fw-bold display-flex">
                                      {data.useraddress.substring(0, 5)}...
                                      {data.useraddress.substring(
                                        data.useraddress.length - 4
                                      )}
                                    </h6>
                                  </div>
                                </Dropdown.Toggle>

                                <Dropdown.Menu
                                  style={{
                                    backgroundColor: "#506ec7",
                                    color: "#c5c55b",
                                  }}
                                >
                                  <Dropdown.Item
                                    href="#/action-1"
                                    data-bs-toggle="modal"
                                    data-bs-target="#exampleModal"
                                    onClick={() =>
                                      lastfivestatus(data.useraddress)
                                    }
                                  >
                                    View Status
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    href={`https://mainnet-explorer.d-ecosystem.io/address/${data.useraddress}`}
                                    target="_blank"
                                  >
                                    View On Explorer
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>

                              {/* <h6 className="leader-tab-img-title fw-bold mt-auto">
                              {data.useraddress.substring(0, 5)}...
                              {data.useraddress.substring(
                                data.useraddress.length - 4
                              )}
                            </h6> */}
                            </div>
                          </td>
                          <td
                            data-label="NET WINNINGS (BNB)"
                            // style={{ padding: "16px" }}
                          >
                            <div>
                              <h6
                                className="leader-tab-num  mb-auto fw-bold"
                                style={{ color: "white" }}
                              >
                                {data.netwining.toFixed(5)}
                              </h6>
                              <p
                                className="leader-sec-tab-num mb-auto"
                                style={{ color: "#4c88fd" }}
                              >
                                ~$
                                {(
                                  data.netwining * liveprice.toFixed(6)
                                ).toFixed(5)}
                              </p>
                            </div>
                          </td>
                          <td
                            data-label="WIN RATE"
                            className="text-center text-white leader-alter-amt-pay"
                            style={{  color: "#280d5f" }}
                          >
                            {data.winrate.toFixed(2)}%
                          </td>
                          <td
                            data-label="ROUNDS WON"
                            className="fw-bold text-center text-white leader-alter-amt-pay"
                            style={{ color: "#280d5f" }}
                          >
                            {data.roundwon}
                          </td>
                          <td
                            data-label="Period"
                            className=" text-center text-white leader-alter-amt-pay"
                            style={{ color: "#280d5f" }}
                          >
                            {data.roundplay}
                          </td>
                        </tr>
                      ))
                    : ""}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>

      <div className="container d-flex justify-content-center align-items-center my-4">
        {totalRecords > sizerecords ? (
          isviewloader ? (
            <button
              className="leader-view-more-btn py-2"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "#4c88fd",
                border: "2px solid #4c88fd",
                borderRadius: "16px",
                padding: "0px 24px",
                backgroundColor: "transparent",
              }}
            >
              <Spinner animation="border" />
            </button>
          ) : (
            <button
              className="leader-view-more-btn py-2"
              style={{
                color: "#4c88fd",
                border: "2px solid  #4c88fd",
                borderRadius: "16px",
                padding: "0px 24px",
                backgroundColor: "transparent",
              }}
              onClick={handleNextPage}
            >
              View More
            </button>
          )
        ) : (
          <button
            className="leader-view-more-btn py-2"
            style={{
              color: "#4c88fd",
              border: "2px solid  #4c88fd",
              borderRadius: "16px",
              padding: "0px 24px",
              backgroundColor: "transparent",
            }}
          >
            View More
          </button>
        )}
      </div>
    </div>
  );
}

export default Leadership;
