import config from "./config/config"

const DCX = {
  id: config.DCXCHAIN_ID,
  name: 'D Chain Mainnet',
  network: 'D-Chain',
  nativeCurrency: {
    decimals: 18,
    name: 'D-Chain',
    symbol: config.DCX_SYMBOL,
  },
  rpcUrls: {
    public: { http: [config.DCXRPC_URL] },
    default: { http: [config.DCXRPC_URL] },
  },
  blockExplorers: {
    etherscan: { name: 'D-Chain', url: config.DCX_BLOCKEXPLORER },
    default: { name: 'D-Chain', url: config.DCX_BLOCKEXPLORER },
  },

}
export default DCX
