// import React, { useState, useEffect } from 'react';
// import { Line } from 'react-chartjs-2';
// import Chart from 'chart.js/auto';

// const CryptoChart = () => {
//   const [chartData, setChartData] = useState(null);

//   useEffect(() => {
//     let chartInstance = null;

//     // Fetch data from the API
//     fetch('https://api.coinstore.com/api/v1/market/kline/BTCUSDT')
//       .then(response => response.json())
//       .then(data => {
//         const rawTimestamps = data.data.item.map(item => item.endTime);

//         // Process the API response data
//         const chartLabels = data.data.item.map(item => {
//           const timestampInMilliseconds = item.endTime * 1000;
//           return new Date(timestampInMilliseconds).toLocaleString();
//         });
//         const chartDataPoints = data.data.item.map(item => parseFloat(item.close));

//         // Check if the chart instance exists and destroy it before creating a new one
//         if (chartInstance) {
//           chartInstance.destroy();
//         }

//         // Create a new chart instance with the fill option for area chart
//         chartInstance = new Chart(document.getElementById('cryptoChart'), {
//           type: 'line',
//           data: {
//             labels: chartLabels,
//             datasets: [
//               {
//                 label: 'Crypto Price',
//                 data: chartDataPoints,
//                 fill: true, // Set to true for an area chart
//                 backgroundColor: 'rgba(75,192,192,0.4)', // Set the fill color
//                 borderColor: 'rgba(75,192,192,1)',
//                 borderWidth: 2,
//               },
//             ],
//           },
//         });

//         // Set the chart data
//         setChartData(chartInstance);
//       })
//       .catch(error => console.error('Error fetching data:', error));

//     return () => {
//       // Destroy the chart instance on component unmount
//       if (chartInstance) {
//         chartInstance.destroy();
//       }
//     };
//   }, []); // Empty dependency array to ensure the effect runs once on mount

//   return (
//     <div>
//       <h2>Crypto Area Chart</h2>
//       <canvas id="cryptoChart"></canvas>
//     </div>
//   );
// };

// export default CryptoChart;
// TradingViewWidget.jsx
import React, { useEffect, useRef, memo } from 'react';

function TradingViewWidget() {
  const container = useRef();
  const scriptLoaded = useRef(false);

  useEffect(() => {
    if (!scriptLoaded.current) {
      const script = document.createElement("script");
      script.src = "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
      script.type = "text/javascript";
      script.async = true;
      script.innerHTML = `
        {
          "autosize": true,
          "symbol": "BINANCE:BTCUSDT",
          "interval": "3",
          "timezone": "Etc/UTC",
          "theme": "dark",
          "style": "3",
          "locale": "in",
          "enable_publishing": false,
          "allow_symbol_change": true,
          "support_host": "https://www.tradingview.com"
        }`;
      container.current.appendChild(script);

      // Set the scriptLoaded ref to true to prevent re-execution
      scriptLoaded.current = true;
    }
  }, []); // Empty dependency array to ensure the effect runs once on mount

  return (
    <div className="tradingview-widget-container" ref={container} style={{ height: "100%", width: "100%" }}>
      <div className="tradingview-widget-container__widget" style={{ height: "calc(100% - 32px)", width: "100%" }}></div>
    </div>
  );
}

export default memo(TradingViewWidget);

