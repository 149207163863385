import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import lineglow from "../Assests/images/line-glow.png";
import { BiCopy } from "react-icons/bi";
import "react-image-upload/dist/index.css";
import $, { type } from "jquery";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CopyToClipboard } from "react-copy-to-clipboard";
import moment from "moment";
import { AiOutlineCopy } from "react-icons/ai";
import { BsFacebook, BsInstagram, BsDownload } from "react-icons/bs";
import Web3 from "web3";
import { extractPublicId } from "cloudinary-build-url";
import config from "../config/index";
import configLottery from "../config/config"
import { SlStar } from "react-icons/sl";
import { BsRocketTakeoff } from "react-icons/bs";
import Navbar from "../Pages/Separate/Navbar";
import {
  useAccount,
  useSwitchNetwork,
} from "wagmi";
import { useNavigate } from "react-router-dom";
import ResponsivePagination from 'react-responsive-pagination';
import 'react-responsive-pagination/themes/bootstrap.css';
import Newreferearn from "../Assests/images/new-dash-refer-an-earn.webp";
import Newactiveearn from "../Assests/images/new-dash-active-earn.webp";
import Newpromoteearn from "../Assests/images/new-dash-promote-earn.webp";
import Lottery from "../Assests/images/new-dash-lottery.png";
import Prediction from "../Assests/images/new-dash-prediction.webp";
import Lineglowgreen from "../Assests/images/line-glow-green.webp";
import Lineglowviolet from "../Assests/images/line-glow-violet.webp";
import Lineglowpink from "../Assests/images/line-glow-pink.webp";

function App() {
  const [userAddress, setUserAddress] = useState("");
  const [content, setContent] = useState([]);
  const [facebook, setFacebook] = useState([{ id: "", value: "" }]);
  const [facebookErr, setFacebookErr] = useState("");
  const [instagram, setInstagram] = useState([{ id: "", value: "" }]);
  const [instagramErr, setInstagramErr] = useState("");
  const [twitter, setTwitter] = useState([{ id: "", value: "" }]);
  const [twitterErr, setTwitterErr] = useState("");
  const [index, setIndex] = useState("");
  const [index1, setIndex1] = useState("");
  const [copied, setCopied] = useState(false);
  const [userId, setUserId] = useState("");
  const [referal, setReferal] = useState([]);
  const [referalPage, setReferalPage] = useState("");
  const [referalLimit, setReferalLimit] = useState(5);
  const [referalTotalPage, setReferalTotalPage] = useState("");
  const [verified, setVerified] = useState([]);
  const [limit, setLimit] = useState(4);
  const [totalPage, setTotalPage] = useState("");
  const [rewards, setRewards] = useState("");
  const [tempReward, setTempReward] = useState("");
  const [loader, setLoader] = useState(0);
  const [readMore, setReadmore] = useState(140);
  const [index2, setIndex2] = useState();
  const [level, setLevel] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [arrayPage, setArrayPage] = useState([]);
  const [readMorecontent, setreadMorecontent] = useState(false);
  const [currentPage, setCurrentPage] = useState("");
  const [walletStatus, setWalletStatus] = useState("");
  const [totalPost, setTotalPost] = useState(0);
  const [postId, setPostId] = useState();
  const [postStatus, setPostStatus] = useState();
  const [lanchPadTransactionData, setLanchPadTransactionData] = useState([]);
  const [d_swapTransactionData, setD_SwapTransactionData] = useState([]);
  const [nftTransactionData, setNftTransactionData] = useState([]);
  const [userRewards, setUserRewards] = useState(null);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [celebrationPopUp, setCelebrationPopUp] = useState(false);
  const [userTotalTransaction, setUserTotalTransaction] = useState(0);
  const [showPercentage, setShowPercentage] = useState();
  const navigate = useNavigate();
  const { address } = useAccount();
  const { chains, switchNetwork } = useSwitchNetwork();
  const MAX_VISIBLE_PAGES = 4; // Adjust this value based on your preference
  const inputRef = useRef(null);

  const handleSwitchNetWorkLot = () => {
    let userMetaAddress = localStorage.getItem("wagmi.store");
    if (userMetaAddress) {
      let userMeta = JSON.parse(userMetaAddress);
      let addr = userMeta.state.data.account;
      if (addr) {
        let chainid = userMeta.state.data.chain.id;
        if (chainid == configLottery.DCXCHAIN_ID) {
          // console.log(configLottery.DCXCHAIN_ID);
          navigate("/lottery");
        } else {

          setInterval(() => {
            switchNetwork?.(chains[0].id);
            navigate("/lottery")
          }, 2000);
        }
      } else {
        navigate("/lottery")
      }
    }
  };

  const handleSwitchNetWorkPre = () => {
    let userMetaAddress = localStorage.getItem("wagmi.store");
    if (userMetaAddress) {
      let userMeta = JSON.parse(userMetaAddress);
      let addr = userMeta.state.data.account;
      if (addr) {
        let chainid = userMeta.state.data.chain.id;
        if (chainid == configLottery.DCXCHAIN_ID) {
          navigate("/win");
        } else {
          setInterval(() => {
            switchNetwork?.(chains[0].id);
            navigate("/win")
          }, 2000);
        }
      } else {
        navigate("/win")
      }
    }
  };
  const referDetails = async (userAddress, userObject, id) => {
    await axios({
      method: 'POST',
      url: `${config.API_URL}/create-refer?userKey=${id}`,
      data: {
        toUserAddress: userAddress
      },
      headers: {
        "Access-Control-Allow-Origin": "*",
        'Content-Type': 'application/json',
      }
    }).then(async (res) => {
      if (res) await userRegister(userObject)
      // window.location.href = "/dashboard"
      localStorage.removeItem("referrerId")
    })
      .catch((err) =>
        // window.location.href = "/dashboard"
        localStorage.removeItem("referrerId"),
      );
  }
  const getAddress = async () => {
    let referrerId = localStorage.getItem("referrerId");
    // const account = await window.ethereum.request({
    //   method: "eth_requestAccounts",
    // });
    let userMetaMaskAddress = Web3.utils.toChecksumAddress(address);
    // var useraddress = account[0];
    let percentage = 50;
    var userObject = {
      userMetaMaskAddress,
      percentage
    };
    setUserAddress(userMetaMaskAddress);
    if (referrerId) {
      await referDetails(userMetaMaskAddress, userObject, referrerId)
    } else {
      await userRegister(userObject)
    }
    axios.get(`${config.API_URL}/getuseraddress/${userObject.userMetaMaskAddress}`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    }).then(async (res) => {
      const ids = res.data.message._id;
      setUserId(ids);
      setRewards(res.data.message.totalRewards);
      setTempReward(res.data.message.rewards);
      setLevel(res.data.message.level);
      setPercentage(res.data.message.levelPercentage);
      if (res.data.message.totalRewards === 100) {
        setShow(true);
      } else {
        setShow(false);
      }
      axios({
        method: "GET",
        url: config.API_URL + `/verified-post/${ids}`,
      })
        .then((response) => {
          setVerified(response.data.message);
        })
        .catch((err) => {
          console.log(err.response);
        });
    })
      .catch((err) => console.log(err));
  };
  const getUserLanchPadTransaction = async () => {
    if (userAddress) {
      await axios({
        method: "POST",
        url: `${config.API_URL}/getUserLanchPadTransactions`,
        data: {
          userAddress: userAddress,
        },
      })
        .then(function (response) {
          let resData = response.data.data;
          // console.log("response from axios===============", response.config.data);
          //         // setUserRewards(resData)
          if (resData == null) {
            setLanchPadTransactionData([]);
          } else {
            setLanchPadTransactionData(resData);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };
  const getUserD_SwapTransaction = async () => {
    if (userAddress) {
      await axios({
        method: "POST",
        url: `${config.API_URL}/getUserD-SwapTransactions`,
        data: {
          useraddress: userAddress,
        },
      })
        .then(function (response) {
          let resData = response.data.data;
          // setUserRewards(resData)
          if (resData == null) {
            setD_SwapTransactionData([]);
          } else {
            setD_SwapTransactionData(resData);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };
  const getUserNftTransaction = async () => {
    if (userAddress) {
      await axios({
        method: "POST",
        url: `${config.API_URL}/getAllUserNFT-Transaction`,
        data: {
          userAddress: userAddress,
        },
      })
        .then(function (response) {
          let resData = response.data.data;
          if (resData == null) {
            setNftTransactionData([]);
          } else {
            setNftTransactionData([]);
            //   setNftTransactionData(resData) -----> if nft api received
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };
  useEffect(() => {
    if (address != undefined) {
      let page = undefined
      getAddress();
      referList(page)
      let userMetaAddress = localStorage.getItem("wagmi.store");
      if (userMetaAddress) {
        let userMeta = JSON.parse(userMetaAddress);
        let addr = userMeta.state.data.account;
        if (addr) {
          let chainid = userMeta.state.data.chain.id;
          if (chainid == configLottery.DCXCHAIN_ID) {
            // navigate("/win");
          } else {
            setInterval(() => {
              switchNetwork?.(chains[0].id);
              // navigate("/win")
            }, 2000);
          }
        } else {
          // navigate("/win")
        }
      }
    } else {
      setShowPercentage(0)
      setUserId(0);
      setRewards(0);
      setTempReward(0);
      setLevel(0);
      setPercentage(0);
      setVerified([])
      setReferal([])
      setReferalPage(1)
      setReferalTotalPage(0)
    }
  }, [address])
  useEffect(() => {
    let showRewards = parseFloat(rewards).toFixed(3);
    let convertRewards = showRewards.indexOf(".");
    let newRewards = showRewards.slice(0, convertRewards);
    let updateRewards = newRewards == "Na" ? 0 : newRewards
    setShowPercentage(updateRewards);
  }, [rewards]);
  useEffect(() => {
    if (rewards) {
    }
    let totalTransactions =
      lanchPadTransactionData.length +
      d_swapTransactionData.length +
      nftTransactionData.length;
    setUserTotalTransaction(totalTransactions);
    if (userAddress) {
      axios({
        method: "POST",
        url: `${config.API_URL}/updateActiveCount`,
        data: {
          userAddress: userAddress,
          activeEarnCount: totalTransactions,
        },
      })
        .then((response) => { })
        .catch((err) => {
          console.log("err", err);
        });
    }

    // update earn Amt
    if (userAddress) {
      if (totalTransactions == 100) {
        setCelebrationPopUp(true);
      }
    }
  }, [
    userAddress,
    lanchPadTransactionData?.length ||
    d_swapTransactionData?.length ||
    nftTransactionData?.length,
  ]);
  function resetTotalTransaction() {
    axios({
      method: "POST",
      url: `${config.API_URL}/updateEarnRewards`,
      data: {
        userAddress: userAddress,
        activeEarnCount: userTotalTransaction,
      },
    })
      .then((response) => {
        setCelebrationPopUp(false);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }
  useEffect(() => {
    // getUserLanchPadTransaction();
    // getUserD_SwapTransaction();
    // getUserNftTransaction();
    // if (window.ethereum) {
    //   window.ethereum.on("accountsChanged", async function (accounts) {
    //     if (accounts.length == 0) {
    //       sessionStorage.removeItem("walletAddress");
    //       // window.location.href = "/";
    //     }
    //   });
    // }
    if (!sessionStorage.getItem("walletAddress")) {
      // window.location.href = "/"
    }
    // if (userAddress) {
    //   referList()
    // }
  }, [userAddress]);

  useEffect(() => {
    if (address != null) {

      let page = undefined
      // getAddress();
      referList(page)
    }
    getContent();
    $("#refer-earn-button").click(function () {
      $(".refer-earn-section").removeClass("order-1");
      $(".active-earn-section").removeClass("order-3");
      $(".promote-earn-section").removeClass("order-4");
      $(".refer-earn-section").addClass("order-1");
      $(".active-earn-section").addClass("order-3");
      $(".promote-earn-section").addClass("order-4");
      $(".group-collapse-section").addClass("order-2");
    });
    $("#active-earn-button").click(function () {
      $(".refer-earn-section").removeClass("order-1");
      $(".active-earn-section").removeClass("order-3");
      $(".promote-earn-section").removeClass("order-4");
      $(".refer-earn-section").addClass("order-1");
      $(".active-earn-section").addClass("order-2");
      $(".promote-earn-section").addClass("order-4");
      $(".group-collapse-section").addClass("order-3");
    });
    $("#promote-earn-button").click(function () {
      $(".refer-earn-section").removeClass("order-1");
      $(".active-earn-section").removeClass("order-3");
      $(".promote-earn-section").removeClass("order-4");
      $(".refer-earn-section").addClass("order-1");
      $(".active-earn-section").addClass("order-2");
      $(".promote-earn-section").addClass("order-3");
      $(".group-collapse-section").addClass("order-4");
    });
    // ** referal address fetch the refers details*/
  }, []);
  const userRegister = async (userObject) => {
    await axios
      .post(`${config.API_URL}/register-user`, userObject, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        // if (res.data.message == "User address already taken") {
        //     window.location.href = "/dashboard";
        // } else {
        //     window.location.href = "/dashboard";
        // }
      });
  };
  const download = async (image) => {
    const cloudinaryUrl = `https://res.cloudinary.com/dwkhvqdic/image/upload/${extractPublicId(
      image
    )}.jpg`;
    try {
      const response = await fetch(cloudinaryUrl);
      const blob = await response.blob();
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `${Date.now()}.jpg`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading image:", error);
    }
  };
  function referList(page, identity) {
    if (identity == "next") {
      let totalPageReferralArray = [];
      Array.from(Array(referalTotalPage), (e, i) => {
        totalPageReferralArray.push(++i);
      });
      let currentIndex = totalPageReferralArray.indexOf(page);
      if (currentIndex !== -1 && currentIndex < totalPageReferralArray.length - 1) {
        const nextElement = totalPageReferralArray[currentIndex + 1];
        page = nextElement;
      } else {
        console.log(
          "Next element not found or it is the last element in the array."
        );
      }
    } else if (identity == "previous") {
      if (referalPage == "") {
        page = 1;
        setReferalPage(page);
      } else {
        page = referalPage - 1;
        setReferalPage(page);
      }
    }
    axios({
      method: "GET",
      url: config.API_URL + `/refer-list/${address}?limit=${referalLimit}&page=${page ? (typeof page == "number" ? page : "l") : 1}`,
    }).then((response) => {
      setReferal(response.data.message);
      setReferalPage(response.data.currentPage)
      setReferalTotalPage(response.data.totalPage)
    }).catch((err) => {
      console.log(err.response);
    });
  }
  function getContent(pageNo, identity) {
    if (identity == "next") {
      let totalPageArray = [];
      Array.from(Array(totalPage), (e, i) => {
        totalPageArray.push(++i);
      });
      let currentIndex = totalPageArray.indexOf(pageNo);
      if (currentIndex !== -1 && currentIndex < totalPageArray.length - 1) {
        const nextElement = totalPageArray[currentIndex + 1];
        pageNo = nextElement;
      } else {
        console.log(
          "Next element not found or it is the last element in the array."
        );
      }
    } else if (identity == "previous") {
      pageNo = currentPage - 1;
      setCurrentPage(pageNo);
    }
    axios({
      method: "GET",
      url: `${config.API_URL}/get-users-post?limit=${limit}&page=${pageNo ? (typeof pageNo == "number" ? pageNo : "l") : 1
        }`,
      headers: {
        "Content-Type": "text/html",
      },
    }).then((response) => {
      let totalPageNo = [];
      let pages = response.data.totalPage;
      setContent(response.data.message);
      setCurrentPage(response.data.currentPage);
      setTotalPage(pages);
      setTotalPost(response.data.totalPost);
      Array.from(Array(pages), (e, i) => {
        totalPageNo.push(++i);
      });
      setArrayPage(totalPageNo);
    });
  }
  function sendLinks(number, id) {
    setIndex(id);
    if (address) {
      if (twitter[0].id == id) {
        let append = "https://";
        if (twitter[0].value.length != 0) {
          if (!twitter[0].value.includes(append)) {
            toast.error("Please include https://", {
              position: "top-right",
            });
          } else {
            setButtonDisabled(true);
            axios({
              method: "POST",
              url: config.API_URL + "/social-media-posts",
              data: {
                userMetaAddress: userAddress,
                postId: id,
                instaPostUrl: instagram[0].value,
                twitterPostUrl: twitter[0].value,
                facebookPostUrl: facebook[0].value,
              },
            })
              .then((response) => {
                toast.success(response.data.message, {
                  position: "top-right",
                });
                setPostId(response.data.postId);
                setPostStatus(response.data.status);
                getAddress();
                setButtonDisabled(false);
              })
              .catch((err) => {
                toast.error(err.response.data.message, {
                  position: "top-right",
                });
              });
          }
        } else {
          toast.error("Please fill the fields", {
            position: "top-right",
          });
        }
      } else {
        toast.error("Please fill the fields", {
          position: "top-right",
        });
      }
    } else {
      toast.error("Connect Your Wallet", {
        position: "top-right",
      });
    }
  }
  function getFacebookLink(id, event) {
    const updateFields = facebook.map((fields) => {
      return { ...fields, id: id, value: event.target.value };
    });
    setFacebook(updateFields);
    //update to empty state when field has values
    if (instagram[0].id != id) {
      const updateInstagram = instagram.map((fields) => {
        return { ...fields, id: "", value: "" };
      });
      setInstagram(updateInstagram);
    }
    if (twitter[0].id != id) {
      const updateTwitter = twitter.map((fields) => {
        return { ...fields, id: "", value: "" };
      });
      setTwitter(updateTwitter);
    }
  }
  function getInstagramLink(id, event) {
    const updateFields = instagram.map((fields) => {
      return { ...fields, id: id, value: event.target.value };
    });
    setInstagram(updateFields);
    //update to empty state when field has values
    if (facebook[0].id != id) {
      const updateFacebookFields = facebook.map((fields) => {
        return { ...fields, id: "", value: "" };
      });
      setFacebook(updateFacebookFields);
    }
    if (twitter[0].id != id) {
      const updateTwitter = twitter.map((fields) => {
        return { ...fields, id: "", value: "" };
      });
      setTwitter(updateTwitter);
    }
  }
  function getTwitterLink(id, event) {
    console.log('id, event', id, event.target.value)
    // console.log("id", id, "event", event.target.value);
    const updateFields = twitter.map((fields) => {
      return { ...fields, id: id, value: event.target.value };
    });
    // console.log("updateFields", updateFields);
    setTwitter(updateFields);
    //update to empty state when field has values
    // if (instagram[0].id != id) {
    //   const updateInstagram = instagram.map((fields) => {
    //     return { ...fields, id: "", value: "" };
    //   });
    //   setInstagram(updateInstagram);
    // }
    // if (instagram[0].id != id) {
    //   const updateInstagram = instagram.map((fields) => {
    //     return { ...fields, id: "", value: "" };
    //   });
    //   setInstagram(updateInstagram);
    // }
  }
  function copying(boolean, id) {
    setCopied(boolean);
    setIndex1(id);
  }
  function claimReward() {
    setLoader(1);
    axios({
      method: "POST",
      url: config.API_URL + "/transfer-rewards",
      data: {
        userMetaAddress: userAddress,
      },
    })
      .then((response) => {
        toast.success(response.data.message, {
          position: "top-right",
        });
        getContent();
        setLoader(0);
        setTempReward(0);
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          position: "top-right",
        });
        setLoader(0);
      });
  }
  function reSubmit(id) {
    axios({
      method: "POST",
      url: config.API_URL + "/resetPost",
      data: {
        id: id,
      },
    })
      .then((response) => {
        if (response.data.message) {
          getAddress();
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          position: "top-right",
        });
      });
  }
  const shareContent = (imageUrl) => {
    // Handle sharing logic here using the social media sharing APIs or other libraries
    // Example: using the Facebook sharing API
  };
  const shareContentTwitter = (imageUrl, content, title) => {
    const url = `https://twitter.com/intent/tweet?url=${encodeURIComponent(imageUrl)}&text=${encodeURIComponent(content)}`;
    window.open(url, '_blank');
  };
  const shareToInstagram = (imageURL, content) => { };
  const readMorefn = (id) => {
    setShow1(true);
    setreadMorecontent(id);
  };
  const nextLevel = async () => {
    axios({
      method: "POST",
      url: `${config.API_URL}/nextlevel`,
      data: { address: userAddress },
    })
      .then((response) => {
        setShow(false);
        window.location.reload();
      })
      .catch((err) => {
        if (err) throw err;
      });
  };
  // const totalRewards = lanchPadTransactionData.reduce((sum, obj) => sum + obj.value, 0)
  // post pagination
  const showFirstEllipsis = currentPage >= MAX_VISIBLE_PAGES;
  const showLastEllipsis = currentPage < totalPage - 3;
  let firstPages = Array.from(Array(Math.min(MAX_VISIBLE_PAGES, totalPage)), (e, i) => i + 1);
  let middlePages = [];
  let lastPage = totalPage;
  if (showFirstEllipsis && showLastEllipsis) {
    middlePages = Array.from(Array(MAX_VISIBLE_PAGES - 1), (e, i) => currentPage - Math.floor(MAX_VISIBLE_PAGES / 4) + i + 1);
    firstPages = [firstPages[0]]
  } else if (showFirstEllipsis) {
    middlePages = Array.from(Array(MAX_VISIBLE_PAGES), (e, i) => totalPage - Math.floor(MAX_VISIBLE_PAGES / 4) - i + 1);
    middlePages.sort((a, b) => a - b)
    firstPages = [firstPages[0]]
  } else {
    firstPages = Array.from(Array(Math.min(MAX_VISIBLE_PAGES, totalPage)), (e, i) => i + 1);
  }

  //referral pagination
  const showFirstReferralEllipsis = referalPage >= MAX_VISIBLE_PAGES;
  const showLastReferralEllipsis = referalPage < referalTotalPage - 3;
  let firstReferralPages = Array.from(Array(Math.min(MAX_VISIBLE_PAGES, referalTotalPage)), (e, i) => i + 1);
  let middleReferralPages = [];
  let lastReferralPage = referalTotalPage;
  if (showFirstReferralEllipsis && showLastReferralEllipsis) {
    middleReferralPages = Array.from(Array(MAX_VISIBLE_PAGES - 1), (e, i) => referalPage - Math.floor(MAX_VISIBLE_PAGES / 4) + i + 1);
    firstReferralPages = [firstReferralPages[0]]
  } else if (showFirstReferralEllipsis) {
    middleReferralPages = Array.from(Array(MAX_VISIBLE_PAGES), (e, i) => referalTotalPage - Math.floor(MAX_VISIBLE_PAGES / 4) - i + 1);
    middleReferralPages.sort((a, b) => a - b)
    firstReferralPages = [firstReferralPages[0]]
  } else {
    firstReferralPages = Array.from(Array(Math.min(MAX_VISIBLE_PAGES, referalTotalPage)), (e, i) => i + 1);
  }
  return (
    <>
      <section className="dashboard-new-dao-section">
        <div className="container-fluid">
          <div className="row">
            <ToastContainer />
            <div className="dashboard-new-dao-left-side-section">
              <Navbar />
              <div className="row mt-lg-4">
                <div className="col-lg-11 mx-auto">
                  <div className="dashboard-new-dao-inner-card-1">
                    <div className="d-flex flex-row gap-3">
                      <div className="d-flex flex-column mt-0 dashboard-new-dao-inner-card-1-inner-1">
                        <div>
                          <p className="dashboard-left-text-2 me-2 mb-0"><SlStar className="me-1 position-relative" style={{ top: "-2px" }} />Total Earned Rewards </p>
                        </div>
                        <div className="mt-lg-2">
                          <p className="dashboard-left-text-1 mb-0">
                            {parseFloat(rewards).toFixed(3) == 'NaN' ? parseFloat(0).toFixed(3) : parseFloat(rewards).toFixed(3)}</p>
                        </div>
                      </div>
                      <div className="d-flex flex-column mt-0 dashboard-new-dao-inner-card-1-inner-2">
                        <div>
                          <p className="dashboard-left-text-2 me-2 mb-0"><BsRocketTakeoff className="me-1 position-relative" style={{ top: "-1px" }} />Claimable Rewards </p>
                        </div>
                        <div className="mt-lg-2">
                          <p className="dashboard-left-text-1 mb-0">
                            {parseFloat(tempReward).toFixed(3) == 'NaN' ? parseFloat(0).toFixed(3) : parseFloat(tempReward).toFixed(3)}</p>
                        </div>
                      </div>
                    </div>
                    {
                      tempReward == 0 ?
                        ''
                        :
                        loader == 1 ?
                          <div className=" mt-4">
                            <button className="new-dashboard-claim-button" disabled onClick={claimReward} type="button">
                              Claim Reward
                              <div class="spinner-border" role="status">
                                <span class="sr-only"></span>
                              </div>
                            </button>
                          </div>
                          :
                          <div className=" mt-4">
                            <button className="new-dashboard-claim-button" onClick={claimReward} type="button">
                              Claim Reward
                            </button>
                          </div>
                    }
                  </div>
                </div>
              </div>

              <div className="row mt-lg-3">
                <div className="col-lg-11 mx-auto ">
                  <div className="dashboard-new-dao-inner-card-2">
                    <div className="row justify-content-center g-4">
                      <div className="col-lg-4 ">
                        <div className="dashboard-new-dao-inner-card-2-1" style={{ backgroundImage: `url(${Newreferearn})`, }}>
                          <h4 className="mb-0 dashboard-new-dao-text-1">Refer & Earn</h4>
                          <button class="btn-new-dao-collapse-hide-show mt-2" id="refer-earn-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-b1" aria-expanded="false" aria-controls="collapse-b1">JOIN</button>
                        </div>
                      </div>
                      <div className="col-lg-4 ">
                        <div className="dashboard-new-dao-inner-card-2-1" style={{ backgroundImage: `url(${Newactiveearn})`, }}>
                          <h4 className="mb-0 dashboard-new-dao-text-1">Active & Earn</h4>
                          <button class="btn-new-dao-collapse-hide-show mt-2" id="active-earn-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-b2" aria-expanded="false" aria-controls="collapse-b2">JOIN</button>
                        </div>
                      </div>
                      <div className="col-lg-4 ">
                        <div className="dashboard-new-dao-inner-card-2-1" style={{ backgroundImage: `url(${Newpromoteearn})`, }}>
                          <h4 className="mb-0 dashboard-new-dao-text-1">Promote & Earn</h4>
                          <button class="btn-new-dao-collapse-hide-show mt-2" id="promote-earn-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-b3" aria-expanded="false" aria-controls="collapse-b3">JOIN</button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 order-lg-4 group-collapse-section">
                    <div id="myGroup" className=" mb-3 mt-4">
                      <div
                        className="collapse"
                        id="collapse-b1"
                        data-bs-parent="#myGroup"
                      >
                        <div className="dashboard-left-innerbox-3" style={{ borderColor: "#01890E", background: "radial-gradient(ellipse at center, #01890f44 0%, rgba(255, 255, 255, 0) 100%)" }}>
                          <h4 className="dashboard-left-text-5 mb-0">
                            Refer & Earn
                          </h4>
                          <img
                            src={Lineglowgreen}
                            className="lineglow-image-1"
                            alt="dashboardimage2"
                          />
                          <div className="d-flex flex-row">
                            {
                              address != undefined ?
                                <div className="ms-auto text-end dashboard-left-innerbox-3-border">
                                  <CopyToClipboard
                                    text={`${window.location.protocol +
                                      "//" +
                                      window.location.host
                                      }/referral/${userId}`}
                                  >
                                    <button
                                      type="submit"
                                      onClick={() => setCopied(true)}
                                      className="ms-2 Referral-Link-button"
                                    >
                                      {copied == true
                                        ? "Copied"
                                        : "Copy Referral Link"}
                                    </button>
                                  </CopyToClipboard>
                                </div>
                                :
                                <p className="text-center  mx-auto" style={{fontSize:"20px"}}>Connect your Wallet to get Referral Link</p>
                            }
                          </div>
                          <div className="refer-section-table">
                            <div className="row referral-table-section-head mt-4">
                              <div className="col">
                                <span className="mb-0 dashboard-left-text-6">
                                  User Address
                                </span>
                              </div>
                              <div className="col">
                                <span className="mb-0 dashboard-left-text-6">
                                  Earnings
                                </span>
                              </div>
                              <div className="col">
                                <span className="mb-0 dashboard-left-text-6">
                                  Time of Entry
                                </span>
                              </div>
                            </div>
                            {referal.length > 0 ? (
                              referal.map((data) => {
                                return (
                                  <>
                                    <div className="row referral-table-section-body">
                                      <div className="col">
                                        <span className="mb-0 dashboard-left-text-6">
                                          {data.toUserAddress}
                                        </span>
                                      </div>
                                      <div className="col">
                                        <span className="mb-0 dashboard-left-text-6">
                                          {data.amountEarned
                                            ? parseFloat(
                                              data.amountEarned
                                            ).toFixed(3)
                                            : 0}
                                        </span>
                                      </div>
                                      <div className="col">
                                        <span className="mb-0 dashboard-left-text-6">
                                          {moment(data.createdAt)
                                            .utc()
                                            .format("YYYY MMM DD hh:mm a")}{" "}
                                          UTC
                                        </span>
                                      </div>
                                    </div>
                                  </>
                                );
                              })
                            ) : (
                              <p className="text-center p-5">
                                No Data Available
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="mt-3 d-flex justify-content-center">
                          {/* <ResponsivePagination
                            total={referalTotalPage}
                            current={referalPage}
                            onPageChange={page => referList(page)}
                          /> */}
                          <nav aria-label="Page navigation  example">
                            <ul className="pagination">
                              <li className="page-item">
                                <a className="page-link" href="#collapse-b3" style={{ "cursor": "pointer" }} onClick={(e) => referList(lastReferralPage, "previous")} aria-label="Previous">
                                  <span aria-hidden="true">&laquo;</span>
                                </a>
                              </li>
                              {
                                firstReferralPages.map((pageNumber) => {
                                  return (
                                    <li key={pageNumber} className="page-item">
                                      <a
                                        href="#collapse-b3"
                                        className={`page-link ${referalPage === pageNumber ? 'highlightNumber' : ''}`}
                                        style={{ cursor: "pointer" }}
                                        onClick={(e) => referList(pageNumber)}
                                      >
                                        {pageNumber}
                                      </a>
                                    </li>
                                  )
                                })
                              }
                              {showFirstReferralEllipsis && (
                                <li className="page-item">
                                  <span className="page-link text-white">...</span>
                                </li>
                              )}
                              {middleReferralPages.map((pageNumber) => {
                                return (
                                  <li key={pageNumber} className="page-item">
                                    <a
                                      href="#collapse-b3"
                                      className={`page-link ${referalPage === pageNumber ? 'highlightNumber' : ''}`}
                                      style={{ cursor: "pointer" }}
                                      onClick={(e) => referList(pageNumber)}
                                    >
                                      {pageNumber}
                                    </a>
                                  </li>
                                )
                              })}
                              {showLastReferralEllipsis && (
                                <li className="page-item">
                                  <span className="page-link text-white">...</span>
                                </li>
                              )}
                              {showLastReferralEllipsis && (
                                <li className="page-item">
                                  <a
                                    href="#collapse-b3"
                                    className={`page-link ${referalPage === lastReferralPage ? 'highlightNumber' : ''}`}
                                    style={{ cursor: "pointer" }}
                                    onClick={(e) => referList(lastReferralPage)}
                                  >
                                    {lastReferralPage}
                                  </a>
                                </li>
                              )}
                              {/* {
                                  Array.from(Array(totalPage), (e, i) => {
                                    // const pageNumber  = startPage + i;
                                    return (
                                      <>
                                        <li className="page-item">
                                          <a 
                                            href="#collapse-b3" 
                                            className={`page-link ${currentPage == i++ ? 'highlightNumber' : ''}`} 
                                            style={{ "cursor": "pointer" }} 
                                            onClick={(e) => getContent(pageNumber)}
                                          >
                                            {i}
                                          </a>
                                        </li>
                                      </>
                                    )
                                  })
                                }  */}
                              <li className="page-item">
                                <a className="page-link" href="#collapse-b3" style={{ "cursor": "pointer" }} onClick={(e) => referList(lastReferralPage, "next")} aria-label="Next">
                                  <span aria-hidden="true">&raquo;</span>
                                </a>
                              </li>
                            </ul>
                          </nav>
                        </div>
                      </div>
                      <div
                        className="collapse "
                        id="collapse-b2"
                        data-bs-parent="#myGroup"
                      >
                        <div className="dashboard-left-innerbox-3" style={{ borderColor: "#6C54C3", background: "radial-gradient(ellipse at center, #6c54c342 0%, rgba(255, 255, 255, 0) 100%)" }}>
                          <h4 className="dashboard-left-text-5 mb-0">
                            Active & Earn
                          </h4>
                          <img
                            src={Lineglowviolet}
                            className="lineglow-image-1"
                            alt="dashboardimage2"
                          />
                          <h4>Activity</h4>
                          <div className="row mt-4 g-4">
                            <div className="col-lg-8">
                              <ul className="chart">
                                <li>
                                  <p className="text-center mb-0">
                                    {lanchPadTransactionData.length}
                                  </p>
                                  <span
                                    style={{
                                      height: `${lanchPadTransactionData.length}%`,
                                    }}
                                    title="Transaction"
                                  ></span>
                                </li>
                                {/* <li>
                                      <p className="text-center mb-0">42</p>
                                      <span style={{ height: "42%" }} title="Smart Contract Deployment"></span>
                                  </li> */}
                                <li>
                                  <p className="text-center mb-0">{d_swapTransactionData.length}</p>
                                  <span style={{ height: `${d_swapTransactionData.length}%` }} title="Swap Interaction"></span>
                                </li>
                                <li>
                                  <p className="text-center mb-0">{nftTransactionData.length}</p>
                                  <span style={{ height: `${nftTransactionData.length}%` }} title="Nft Deployment"></span>
                                </li>
                              </ul>
                            </div>
                            <div className="col-lg-4 text-center">
                              <h4>Involvements</h4>
                              <div className="circle">
                                <p>{lanchPadTransactionData.length + d_swapTransactionData.length + nftTransactionData.length}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="collapse" id="collapse-b3" data-bs-parent="#myGroup">
                        <div className="dashboard-left-innerbox-3" style={{ borderColor: "#B70355", background: "radial-gradient(ellipse at center, #B7035522 0%, rgba(255, 255, 255, 0) 100%)" }}>
                          <h4 className="dashboard-left-text-5 mb-0">Promote & Earn</h4>
                          <img src={lineglow} className="lineglow-image-1" alt="dashboardimage2" />
                          <div className="">
                            {content.length == 0 ? <p className="text-center p-5">No Data Available</p> : ''}
                            <div className="row row-cols-xxl-2 row-cols-xl-2 row-cols-1 dashboard-left-box-2 g-4">
                              {
                                content.map((data, n) => {
                                  var split = data.post_tag.split(',');
                                  let postit = verified.find(element => element.post_id == data._id);
                                  let para;
                                  let tags = [];
                                  return (
                                    <>
                                      <div className="col">
                                        {
                                          <div className="card h-100">
                                            <div className="promote-image-section">
                                              {
                                                <>
                                                  <div className="row g-4">
                                                    <div className="col-lg-4">
                                                      <img src={`${data.post_img}`} className="img-dashboard-blog-size ps-lg-2 mx-auto"></img>
                                                      <div className="d-flex justify-content-end">
                                                        <div className="btn-group" role="group">
                                                          <a className="btn-download-image" role="button" onClick={() =>
                                                            download(data.post_img)}
                                                          >
                                                            Download
                                                            <BsDownload className="ps-2" style={{ fontSize: "24px" }} />
                                                          </a>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    {
                                                      split.map((tag) => {
                                                        tags.push(`#${tag}`);
                                                        let tagsString = tags.toString();
                                                        para = data.post_meta_desc + '\n' + tagsString.split(',').join(" ");
                                                      })
                                                    }
                                                    <div className="col-lg-8">
                                                      <p className="line-height-dashboard-para  mb-0 " style={{ textAlign: "justify" }}>
                                                        {data.post_meta_desc.slice(0, 140)}<span style={{ cursor: 'pointer' }} onClick={() =>
                                                          readMorefn(data._id)}>
                                                          {index2 === n ? readMore > 140 ? <span><b>Read Less</b></span> :
                                                            data.post_meta_desc.length > 140 ? <b>...Read More</b> : '' :
                                                            data.post_meta_desc.length > 140 ? <b className="body-scroll-stop">...Read More</b>
                                                              : index2 === n ? <b>Read Less</b> : data.post_meta_desc.length > 140 ? <b>...Read
                                                                More</b> : <>
                                                                <CopyToClipboard text={para}>
                                                                  <AiOutlineCopy style={{ cursor: 'pointer' }} size={22} onClick={() =>
                                                                    copying(true, n)} />
                                                                </CopyToClipboard>{index1 === n ? copied == true ? "Copied" : "" : ""}
                                                              </>}</span>
                                                      </p>
                                                      <div className="">
                                                        <div
                                                          className="d-flex flex-row flex-wrap gap-1 dashboard-para-hashtag justify-content-start text-start ">
                                                          {
                                                            split.map((tag) => {
                                                              return (
                                                                <div className="">
                                                                  <span className=" ">#{tag}</span>
                                                                </div>
                                                              )
                                                            })
                                                          }
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="modal modal-read-more-less" tabindex="-1" style={{
                                                    display: `${show1 == true &&
                                                      data._id == readMorecontent ? data.post_meta_desc.length > 140 ? 'block' : 'none' : 'none'}`,
                                                    color: 'black', textAlign: "justify"
                                                  }}>
                                                    <div className="modal-dialog modal-dialog-centered">
                                                      <div className="modal-content">
                                                        <div className="modal-header border-0">
                                                          <h5 className="modal-title" id="exampleModalLabel"></h5>
                                                          <button type="button" className="btn-close btn-close-white" onClick={() =>
                                                            setShow1(false)}></button>
                                                        </div>
                                                        <div className="modal-body">
                                                          <p className="modal-dialog-readmore-text">{data.post_meta_desc}</p>
                                                          <CopyToClipboard text={para}>
                                                            <button type="button" style={{ cursor: 'pointer' }} size={32} onClick={() =>
                                                              copying(true, n)} className="btn-modal-copy-button">{index1 == n ? copied ==
                                                                true ? "Copied" : "Copy" : "Copy"}
                                                              <BiCopy />
                                                            </button>
                                                          </CopyToClipboard><br />
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  {
                                                    postit == undefined ?
                                                      <form className="mt-3" onSubmit={(e) => e.preventDefault()}>
                                                        <div className="mt-3 mt-lg-5">
                                                          <div className="">
                                                            <p className="mb-0 text-start fs-18">Twitter Link:</p>
                                                            {
                                                              twitter.map((fields, index) => {
                                                                
                                                                return (
                                                                  <div className="material-textfield" key={index}>
                                                                    <input
                                                                      type="text"
                                                                      onChange={(event) => getTwitterLink(data._id, event)}
                                                                      value={fields.id === data._id ? fields.value : ''}
                                                                    />
                                                                  </div>
                                                                )
                                                              })
                                                            }
                                                            <p className="text-danger">{index == data._id ? twitterErr : ''}</p>
                                                          </div>
                                                        </div>
                                                        <div className="row text-center mb-3 d-none d-lg-block">
                                                          <div className="col ">
                                                            {
                                                              buttonDisabled == true ?
                                                                <input type="button" className="btn-collapse-hide-show-2 py-2" onClick={() => sendLinks(n, data._id)} value="Submit" disabled></input>

                                                                :
                                                                <input type="button" className="btn-collapse-hide-show-2 py-2" onClick={() => sendLinks(n, data._id)} value="Submit"></input>
                                                            }
                                                          </div>
                                                        </div>
                                                      </form>
                                                      :
                                                      <div className=" my-5 py-4 px-4 card">
                                                        {
                                                          verified.map((post) => {
                                                            return (
                                                              <>
                                                                {
                                                                  data._id === post.post_id ?
                                                                    post.overall_post_status == 1 ?
                                                                      <span>
                                                                        <p className="mb-0">Promotion Status: </p>
                                                                        <p className="text-success mb-0">Approved, 0.1 DCX added to your Wallet</p>
                                                                      </span>
                                                                      :
                                                                      post.overall_post_status == 2 ?
                                                                        <>
                                                                          <p className="m-0">Promotion Status: <span className="text-danger">Denied</span></p>
                                                                          <p className="mb-0 mt-3">{post.reason ? `Reason: ${post.reason}` : ''} <button className="btn-collapse-hide-show-2 mt-4"
                                                                            onClick={() => reSubmit(post._id)}>Click to Resubmit</button></p>
                                                                        </>
                                                                        :
                                                                        <span>
                                                                          <p className="mb-0">Promotion Status: </p>
                                                                          <p className="mb-0" style={{ color: 'yellow' }}>Pending for Admin Approval</p>
                                                                        </span>
                                                                    :
                                                                    ""
                                                                }
                                                              </>
                                                            )
                                                          })
                                                        }
                                                      </div>
                                                  }
                                                  <div className="text-center d-flex flex-row flex-lg-column justify-content-between">
                                                    <div className="d-lg-none d-block">
                                                      <input type="button" className="btn-collapse-hide-show-2 px-4 py-2" onClick={() => sendLinks(n, data._id)} value="Submit"></input>

                                                    </div>
                                                    <div>
                                                      {/* <BsTwitterX className="mx-2" id="twitterShareButton" onClick={() => shareContentTwitter(data.post_img,
                                                        data.post_meta_desc, data.post_title)} style={{
                                                          padding: "5px", fontSize: '30px',
                                                          cursor: "pointer"
                                                        }} /> */}
                                                    </div>
                                                  </div>
                                                </>
                                              }
                                            </div>
                                          </div>
                                        }
                                      </div>
                                    </>
                                  )
                                })
                              }
                            </div>
                          </div>
                          <div className="d-flex flex-row  justify-content-end mt-3 me-1">

                            <nav aria-label="Page navigation  example">
                              <ul className="pagination">
                                <li className="page-item">
                                  <a className="page-link" href="#collapse-b3" style={{ "cursor": "pointer" }} onClick={(e) => getContent(currentPage, "previous")} aria-label="Previous">
                                    <span aria-hidden="true">&laquo;</span>
                                  </a>
                                </li>
                                {
                                  firstPages.map((pageNumber) => {
                                    return (
                                      <li key={pageNumber} className="page-item">
                                        <a
                                          href="#collapse-b3"
                                          className={`page-link ${currentPage === pageNumber ? 'highlightNumber' : ''}`}
                                          style={{ cursor: "pointer" }}
                                          onClick={(e) => getContent(pageNumber)}
                                        >
                                          {pageNumber}
                                        </a>
                                      </li>
                                    )
                                  })
                                }
                                {showFirstEllipsis && (
                                  <li className="page-item">
                                    <span className="page-link text-white">...</span>
                                  </li>
                                )}
                                {middlePages.map((pageNumber) => {
                                  return (
                                    <li key={pageNumber} className="page-item">
                                      <a
                                        href="#collapse-b3"
                                        className={`page-link ${currentPage === pageNumber ? 'highlightNumber' : ''}`}
                                        style={{ cursor: "pointer" }}
                                        onClick={(e) => getContent(pageNumber)}
                                      >
                                        {pageNumber}
                                      </a>
                                    </li>
                                  )
                                })}
                                {showLastEllipsis && (
                                  <li className="page-item">
                                    <span className="page-link text-white">...</span>
                                  </li>
                                )}
                                {showLastEllipsis && (
                                  <li className="page-item">
                                    <a
                                      href="#collapse-b3"
                                      className={`page-link ${currentPage === lastPage ? 'highlightNumber' : ''}`}
                                      style={{ cursor: "pointer" }}
                                      onClick={(e) => getContent(lastPage)}
                                    >
                                      {lastPage}
                                    </a>
                                  </li>
                                )}

                                <li className="page-item">
                                  <a className="page-link" href="#collapse-b3" style={{ "cursor": "pointer" }} onClick={(e) => getContent(currentPage, "next")} aria-label="Next">
                                    <span aria-hidden="true">&raquo;</span>
                                  </a>
                                </li>
                              </ul>
                            </nav>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-lg-3 pb-5">
                <div className="col-lg-11 mx-auto ">
                  <div className="dashboard-new-dao-inner-card-3">
                    <div className="row row-cols-3 row-cols-lg-5 g-4">
                      <div className="col">
                        <a
                          // href="#" 
                          onClick={handleSwitchNetWorkLot}><img src={Lottery} alt="Lottery" /></a>
                      </div>
                      <div className="col">
                        <a
                          // href="/win"
                          onClick={handleSwitchNetWorkPre}
                        ><img src={Prediction} alt="Prediction" /></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div className="dashboard-new-dao-right-side-section">
              <div className="wrapper position-relative">
                <div className="charger">
                  <div className="box">
                    <div className="wave wave-three" style={{ bottom: `${Number(showPercentage) > 0 ? 100 - Number(showPercentage) + '%' : 100 - Number(showPercentage) + '%'}`, background: `${level == 0 ? "linear-gradient(180deg, #39bcb3, #0d2669)" : level == 1 ? "linear-gradient(180deg, #CD7F32, #BE7023)" : level == 2 ? "linear-gradient(180deg, #C0C0C0, #B1B1B1)" : level == 3 ? "linear-gradient(180deg, #CBA135, #BC9226)" : level == 4 ? "linear-gradient(180deg, #E5E4E2, #D6D5D3)" : level == 5 ? "linear-gradient(180deg, #B9F2FF, #AAE3F0)" : "linear-gradient(180deg, #39bcb3, #0d2669)"}` }}></div>
                    <div className="bubble x1"></div>
                    <div className="bubble x2"></div>
                    <div className="bubble x3"></div>
                    <div className="bubble x4"></div>
                    <div className='title'>{Number(showPercentage)}%</div>
                    <p className="mb-0 positioning-the-level text-center">Level: {level}</p>
                    <div className="mb-0 positioning-the-level-2 text-center" style={{ top: "80vh" }}>Diamond</div>
                    <div className="mb-0 positioning-the-level-2 text-center" style={{ top: "60vh" }}>Gold</div>
                    <div className="mb-0 positioning-the-level-2 text-center" style={{ top: "40vh" }}>Silver</div>
                    <div className="mb-0 positioning-the-level-2 text-center" style={{ top: "20vh" }}>Bronze</div>
                  </div>
                </div>
              </div>
              {
                Number(showPercentage) == 100 ?
                  <div className="modal" tabindex="-1" style={{ display: `${show == true ? 'block' : 'none'}`, color: 'black' }}>
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-body">
                          <p>You have moved to next level</p>
                          <button type="button" className="btn btn-info" onClick={nextLevel}>ok</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  :
                  ''
              }
              {
                celebrationPopUp == true ?
                  <div className="modal" tabindex="-1" style={{ display: 'block', color: 'black' }}>
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-body">
                          <p>You have moved to next level</p>
                          <button type="button" className="btn btn-info" onClick={resetTotalTransaction}>ok</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  :
                  ''
              }
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default App;