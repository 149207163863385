const {
    // API START
    // REACT_APP_API_URL = "https://staging.api.lotteryandprediction.d-ecosystem.io",
    // REACT_APP_CHAIN_ID_NUMBER = '80001',
    // REACT_APP_RPC_URL = 'https://mainnetcoin.d-ecosystem.io/',
    // REACT_APP_NETWORK_NAME = 'MATIC',
    // REACT_APP_CURRENCY_NAME = 'MATIC',
    // REACT_APP_CURRENCY_SYMBOL = 'MATIC',
    // REACT_APP_EXPLORER_URL = 'https://mumbai.polygonscan.com',
    REACT_APP_API_URL = "https://api-dao.d-ecosystem.io",
    REACT_APP_CHAIN_ID_NUMBER = '5272',
    REACT_APP_RPC_URL = 'https://mainnetcoin.d-ecosystem.io',
    REACT_APP_NETWORK_NAME = 'D Chain Mainnet',
    REACT_APP_CURRENCY_NAME = 'DCX',
    REACT_APP_CURRENCY_SYMBOL = 'DCX',
    REACT_APP_EXPLORER_URL = 'https://mainnet-explorer.d-ecosystem.io',
} = process.env

const KEY = {
    API_URL: REACT_APP_API_URL,
    CHAIN_ID: REACT_APP_CHAIN_ID_NUMBER,
    RPC_URL: REACT_APP_RPC_URL,
    NETWORK_NAME: REACT_APP_NETWORK_NAME,
    CURRENCY_NAME: REACT_APP_CURRENCY_NAME,
    CURRENCY_SYMBOL: REACT_APP_CURRENCY_SYMBOL,
    EXPLORER_URL: REACT_APP_EXPLORER_URL
}

export default KEY