
import './Assests/css/Style.css';

import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,

} from "react-router-dom";
import Banner from "./Pages/Banner"
import Dashboard from "./Pages/Dashboard"
import Referral from './Pages/referrals'
import Error404 from './Pages/Error404'
import { AuthProvider } from './config/context'
import Win from "./Pages/Win";
import Reward from "./Pages/Reward";
import Lottery from "./Pages/Lottery";
import Games from "./Pages/Games";
import Leadership from "./Pages/Leadership.js"
import { WagmiConfig, createConfig, configureChains } from 'wagmi'
import { useEffect, useState } from 'react';
import { alchemyProvider } from 'wagmi/providers/alchemy'
import { publicProvider } from 'wagmi/providers/public'

import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
// import { LedgerConnector } from 'wagmi/connectors/ledger';
import { mainnet, polygon, bsc } from "wagmi/chains";
import DCX from './DCX';
import 'react-js-cron/dist/styles.css'
// import 'bootstrap/dist/css/bootstrap.min.css'
import $ from "jquery"
import ComingSoonPage from './Pages/comingsoon.js';

function App() {

  const { chains, publicClient, webSocketPublicClient } = configureChains(
    [DCX],
    [alchemyProvider({ apiKey: 'f4FBGOtlTPoqJ2ZR-zanG3k9lgU38KCu' }), publicProvider()],
  )

  const wagmiConfig = createConfig({
    autoConnect: true,
    connectors: [
      new MetaMaskConnector({ chains }),
      // new LedgerConnector({
      //   chains,
      //   options: {
      //     projectId: '1f0b9efb280db82bff5edc4689ffa76a',
      //     enableDebugLogs: true,
      //   }
      // }),
      new WalletConnectConnector({
        chains,
        options: {
          projectId: '1f0b9efb280db82bff5edc4689ffa76a',
        },
      }),
      new CoinbaseWalletConnector({
        chains,
        options: {
          appName: 'D-Ecosystem',
        },
      }),
      new InjectedConnector({
        chains,
        options: {
          name: "trustwallet",
          shimDisconnect: true,
          getProvider: () =>
            typeof window !== "undefined" ? window.trustwallet : undefined
        },
      }),
    ],
    publicClient,
    webSocketPublicClient,
  })
  return (
    <div className="App">
      
      {/* <AuthProvider> */}
      <Router>
      <WagmiConfig config={wagmiConfig}>
        <Routes>
          <Route exact path="/" element={<Banner />} />
          <Route exact path='/comingsoon' element={<ComingSoonPage/>}/>
          <Route exact path="/dashboard" element={<Dashboard />} />
          <Route exact path="/referral/:id" element={<Banner />} />
          <Route exact path='/lottery' element={<Lottery />}></Route>
          <Route exact path='/win' element={<Win />}></Route>
          <Route exact path='/reward' element={<Reward />}></Route>
          <Route exact path='/games' element={<Games />}></Route>
          <Route path="*" element={<Error404 />} />
          <Route exact path="/leadership" element={<Leadership/>} />
        </Routes>
        </WagmiConfig>
      </Router>
     
      {/* </AuthProvider> */}
    </div>
  );
}

export default App;
