import { useEffect, useState } from 'react';
import Logo from "../Assests/images/d-dao-logo.png"
import '../App.css';
import Web3 from 'web3';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import config from '../config/index'
import Navbar from './Separate/Navbar'
import { useAccount } from "wagmi";
import User from "../Assests/images/new-dao/icons/user.gif"
import Claim from "../Assests/images/new-dao/icons/claim.gif"
import Postings from "../Assests/images/new-dao/icons/Postings.gif"
import Refer from "../Assests/images/new-dao/icons/refer.gif"
import PromoteReferEarn from "../Assests/images/new-dao/icons/main-promote.webp"
import PromoteEarn from "../Assests/images/new-dao/icons/PromoteEarn.gif"
import Lotterie from "../Assests/images/new-dao/icons/Lotterie.gif"
import ActiveEarn from "../Assests/images/new-dao/icons/ActiveEarn.gif"
import ReferEarn from "../Assests/images/new-dao/icons/ReferEarn.gif"
import Validator from "../Assests/images/new-dao/icons/Validator.gif"
import Vote from "../Assests/images/new-dao/icons/Vote.gif"
import $ from "jquery"

function App() {
  const {id} = useParams();
  const [refs, setRefs] = useState("");
  const [canvasRef, setCanvasRef] = useState("");
  const [accountaddress, setaccountaddress] = useState('');
  const [userAddress, setUserAddress] = useState("");
  const [walletStatus, setWalletStatus] = useState('');
  const { address, isConnected } = useAccount();

  useEffect(() => {
    if(window.ethereum) {
      window.ethereum.on("accountsChanged", async function (accounts) {
        if (accounts.length == 0) {
          sessionStorage.removeItem("walletAddress");
            // window.location.href = "/";
        }
      });
      setRefs(window.location.href.split('?')[1]);
      if(refs) setCanvasRef(refs.slice(2));
    }
  }, [refs?.length]);
  
  const userRegister = async (userObject) => {
    await axios.post(`${config.API_URL}/register-user`, userObject, {
      "headers": {
        "Access-Control-Allow-Origin": "*",
        'Content-Type': 'application/json',
      }
    }).then((res) => {
      if (res.data.message == "Useraddressexists") {
        window.location.href = "/dashboard";
      } else {
        window.location.href = "/dashboard"
      }
    });
  }
  const referDetails = async (userAddress, userObject) => {
    await axios({
      method: 'POST',
      url: `${config.API_URL}/create-refer?userKey=${id}`,
      data: {
        toUserAddress: userAddress
      },
      headers: {
        "Access-Control-Allow-Origin": "*",
        'Content-Type': 'application/json',
      }
    }).then(async (res) => {
      if (res) await userRegister(userObject)
      window.location.href = "/dashboard"
    })
    .catch(err =>
      window.location.href = "/dashboard"
    );
  }

  // const addChain = async () => {
  //   try {
  //     // const web3js = new Web3("https://ethereum-sepolia.blockpi.network/v1/rpc/public")
  //     const web3js = new Web3(window.ethereum)
    
  //     if (!window.ethereum) {
  //       toast.error("Metamask not detected please install metamask extension");
  //     } else {
  //       let metamaskAddress = sessionStorage.getItem("walletAddress");
  //       if(!metamaskAddress) {
  //         const chainIdnumber = config.CHAIN_ID;
  //         const rpcURL = config.RPC_URL;
  //         const networkName = config.NETWORK_NAME;
  //         const currencyName = config.CURRENCY_NAME;
  //         const currencySymbol = config.CURRENCY_SYMBOL;
  //         const explorerURL = config.EXPLORER_URL;
  //         const chainId = await web3js.utils.toHex(chainIdnumber);
          
  //         await window.ethereum.request({
  //           method: 'wallet_addEthereumChain',
  //           params: [
  //             {
  //               chainId: chainId,
  //               chainName: networkName,
  //               rpcUrls: [rpcURL],
  //               blockExplorerUrls: [explorerURL],
    
  //               nativeCurrency: {
  //                 name: currencyName,
  //                 symbol: currencySymbol,
  //                 decimals: 18,
  //               },
  //             },
  //           ],
  //         });
  //         const account = await window.ethereum.request({ method: 'eth_requestAccounts' });
  //         setWalletStatus('connected');
  //         var userMetaMaskAddress = account[0];
  //         var userPercentage = 50;
  //         var userObject = {
  //           userMetaMaskAddress,
  //           userPercentage
  //         };
  //         setUserAddress(userObject.userMetaMaskAddress);
  //         sessionStorage.setItem("walletAddress", userObject.userMetaMaskAddress)
          
  //         if (id) {
  //           await referDetails(account[0], userObject)
  //         } else {
  //           await userRegister(userObject)
  //         }
  //       } else {
  //         const account = await window.ethereum.request({ method: 'eth_requestAccounts' });
  //         setWalletStatus('connected');
  //         var userMetaMaskAddress = account[0];
  //         var userPercentage = 50;
  //         var userObject = {
  //           userMetaMaskAddress,
  //           userPercentage
  //         };
  //         setUserAddress(userObject.userMetaMaskAddress);
  //         if (id) {
  //           await referDetails(account[0], userObject)
  //         } else {
  //           await userRegister(userObject)
  //         }
  //       }
  //       // ** referal address fetch the refers details*/
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }

  const getaddress = async () => {
    try {
      const web3js = new Web3(window.ethereum);
      const chainid =await web3js.eth.getChainId()
    
      if (chainid == '5272') {
        const account = await window.ethereum.request({ method: 'eth_requestAccounts' });
        var useraddress = account[0];
        var percentage = 50;
        var userObject = {
          useraddress,
          percentage
        };
        //** referal address fetch the refers details*/ 

        setaccountaddress(account[0])
      } else {
        setaccountaddress(1)
      }
    } catch (err) {
      console.log(err);
    } 
  }
  const Disconnect=() => {
    sessionStorage.clear();
    window.location.replace("/");
  }
  useEffect(() => {
    if (address != undefined) {
      var userMetaMaskAddress = address;
      var userPercentage = 50;
      var userObject = {
        userMetaMaskAddress,
        userPercentage
      };
      referDetails(address, userObject)
      // setTimeout(() => {
      //   window.location.href = "/dashboard"
      // }, 2000)
    }
  }, [address])

  useEffect(() => {
    $(document).ready(function () {
      $(window).scroll(function () {
        if ($(document).scrollTop() > 20) {
          $('#new-navbar').hide();
        } else {
          $('#new-navbar').show();
        }
      });
    });
  }, []);
  return (
    <div className="App ">
      <ToastContainer />
      {/* <nav className="navbar navbar-expand-lg navbar-light bg-transparent fixed-top">
        <div className="container-fluid d-flex">
          <a className="navbar-brand ms-lg-5 ms-lg-2 " href="/"><img src={Logo} alt="Logo" /></a>
          <div>
            <ul className="navbar-nav d-flex flex-row mx-auto mb-2 mb-lg-0">
              {
                sessionStorage.getItem("walletAddress") ?
                  <>
                    <li className="nav-item">
                      <a type="button" className="nav-link navbar-connect-wallet-button" onClick={addChain}>Dashboard</a>
                    </li>
                    <li className="nav-item mx-2">
                      <a type="button" className="nav-link navbar-connect-wallet-button">{sessionStorage.getItem("walletAddress") ? sessionStorage.getItem("walletAddress") : 'Connect Wallet'}</a>
                    </li>
                    <li className="nav-item mx-2">
                      <a type="button" className="nav-link navbar-connect-wallet-button" onClick={Disconnect}>Disconnect</a>
                    </li>
                  </>
                :
                  <li className="nav-item mx-2 ">
                    <a type="button" className="nav-link navbar-connect-wallet-button" onClick={addChain}>{sessionStorage.getItem("walletAddress") ? sessionStorage.getItem("walletAddress") : 'Connect Wallet'}</a>
                  </li>
              }
            </ul>
          </div>
        </div>
      </nav> */}
      <div className='banner-bg-image-section'>
      <Navbar />
      <div className="container referral-top-banner-section">
          <div className="row min-vh-100 justify-content-center align-items-center">
            <div className="col-lg-6 text-center new-dao-home-card-section">
              <h2 className="banner-text-2 mb-2">
                Welcome to the DAO Center
              </h2>
              <p className="banner-text-3 mb-4">
                Where Your Engagement Directly Rewards You!
              </p>
              <div className="mt-lg-5">
                <a
                  href="/dashboard"
                  type="button"
                  className="new-dao-enter-button"
                >
                  JOIN Now
                </a>
              </div>
            </div>
          </div>
        </div>
        </div>

        <div className="container py-5 banner-new-dao-section-2">
        <div class="row row-cols-1 row-cols-md-4 row-cols-lg-4 g-4">
          <div class="col">
            <div class="card h-100">
              <div class="card-body p-0">
                <div className="d-flex flex-row gap-3 align-items-center">
                  <div>
                    <img src={User} alt="User" className="banner-new-dao-count-1" />
                  </div>
                  <div>
                    <h5 class="banner-new-dao-count-2 mb-0">1827</h5>
                    <h5 class="banner-new-dao-count-3 mb-0">Total Users</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card h-100">
              <div class="card-body p-0">
                <div className="d-flex flex-row gap-3 align-items-center">
                  <div>
                    <img src={Postings} alt="Postings" className="banner-new-dao-count-1" />
                  </div>
                  <div>
                    <h5 class="banner-new-dao-count-2 mb-0">$DCX 85</h5>
                    <h5 class="banner-new-dao-count-3 mb-0">Total DCX Claimed</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card h-100">
              <div class="card-body p-0">
                <div className="d-flex flex-row gap-3 align-items-center">
                  <div>
                    <img src={Claim} alt="Claim" className="banner-new-dao-count-1" />
                  </div>
                  <div>
                    <h5 class="banner-new-dao-count-2 mb-0">1,384</h5>
                    <h5 class="banner-new-dao-count-3 mb-0">Total Postings</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card h-100">
              <div class="card-body p-0">
                <div className="d-flex flex-row gap-3 align-items-center">
                  <div>
                    <img src={Refer} alt="Refer" className="banner-new-dao-count-1" />
                  </div>
                  <div>
                    <h5 class="banner-new-dao-count-2 mb-0">414</h5>
                    <h5 class="banner-new-dao-count-3 mb-0">Total Referral</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container py-5 banner-new-dao-section-3">
        <div class="row g-4 align-items-center">
          <div class="col-lg-6">
            <h2 className="mb-4">Promote, Refer & Earn</h2>
            <p className="text-justify" style={{ opacity: "0.8" }}>At D-Ecosystem, we believe that your contribution to the growth and
              vibrancy of our platform should be recognized and rewarded. That’s
              why we’ve introduced three dynamic programs designed to reward
              you for engaging with our community and spreading the word about
              our revolutionary platform.</p>
            <p className="text-justify" style={{ opacity: "0.8" }}>Together, these programs form the backbone of our user-centric
              approach, where every action and interaction within D-Ecosystem is
              an opportunity to earn.</p>
          </div>
          <div class="col-lg-6">
            <img src={PromoteReferEarn} alt="PromoteReferEarn" />
          </div>
        </div>
      </div>
      <div className="container py-5 banner-new-dao-section-5">
        <div class="row row-cols-1 row-cols-md-2 g-4 justify-content-center">
          <div class="col">
          <h2 className="mb-4">Lottery</h2>
            <p className="text-justify" style={{ opacity: "0.8" }}>It's your chance to turn a ticket into a fortune. With every number drawn, the excitement builds, and dreams of winning big come alive. Purchase your ticket, pick your numbers, and join the suspenseful journey as we await the winning combination. Good luck, and let the lottery magic unfold!</p>
          </div>
          <div class="col">
          <h2 className="mb-4">Prediction</h2>
            <p className="text-justify" style={{ opacity: "0.8" }}>Unleash your inner oracle in DAO Center's Prediction Game. Test your predictive prowess and compete against friends or fellow enthusiasts. Make your picks wisely, as the thrill of victory awaits those who can foresee the future. Let the predictions begin!"</p>
          </div>
        </div>
      </div>

      <div className="container py-5 banner-new-dao-section-4">
        <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
          <div class="col">
            <div class="card h-100">
              <div class="card-body p-0">
                <img src={PromoteEarn} alt="PromoteEarn" className="banner-new-dao-count-4" />
                <h4 className="mt-4">Promote & Earn</h4>
                <p className="text-justify" style={{ opacity: "0.8" }}>Our Promote & Earn initiative compensates you with DCX tokens for every interaction your social media activity generates. By sharing our vision, you not only contribute to our growth but also receive deserved rewards for your promotional efforts.</p>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card h-100">
              <div class="card-body p-0">
                <img src={ReferEarn} alt="ReferEarn" className="banner-new-dao-count-4" />
                <h4 className="mt-4">Refer & Earn</h4>
                <p style={{ opacity: "0.8" }} className="text-justify">Utilize your unique referral link to
                  invite new users and earn DCX
                  tokens as they engage with the
                  platform. Your rewards are a direct
                  reflection of the active participation
                  of your network, underscoring the
                  communal essence of our
                  ecosystem.</p>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card h-100">
              <div class="card-body p-0">
                <img src={ActiveEarn} alt="ActiveEarn" className="banner-new-dao-count-4" />
                <h4 className="mt-4">Active & Earn</h4>
                <p className="text-justify" style={{ opacity: "0.8" }}>Our Active Participation Rewards
                  program acknowledges and
                  remunerates your consistent
                  engagement across D-Ecosystem s
                  applications. Your routine interactions,
                  from transactions to explorations of
                  our platform s capabilities, are met
                  with DCX token accrual, incentivizing
                  your ongoing involvement.</p>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card h-100">
              <div class="card-body p-0">
                <img src={Lotterie} alt="Lotterie" className="banner-new-dao-count-4" />
                <h4 className="mt-4">Lottery</h4>
                <p className="text-justify" style={{ opacity: "0.8" }}>Participate in our regular draws for a
                  chance to win substantial DCX token
                  prizes. With transparent mechanics
                  and fair selection processes, our
                  lottery system is an exhilarating way to
                  potentially amplify your holdings while
                  enjoying the thrill of the game.</p>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card h-100">
              <div class="card-body p-0">
                <img src={Validator} alt="Validator" className="banner-new-dao-count-4" />
                <h4 className="mt-4">Validator</h4>
                <p className="text-justify" style={{ opacity: "0.8" }}>To acknowledge your vital
                  contributions, we offer substantial
                  rewards and incentives. Validators
                  receive DCX tokens for their service,
                  ensuring the network remains secure
                  and transactions are processed
                  smoothly.</p>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card h-100">
              <div class="card-body p-0">
                <img src={Vote} alt="Vote" className="banner-new-dao-count-4" />
                <h4 className="mt-4">Vote</h4>
                <p className="text-justify" style={{ opacity: "0.8" }}>By participating in governance votes,
                  you help steer the direction of the
                  platform. Active DAO members are
                  rewarded for their contributions to key
                  decision-making processes, ensuring
                  that our development aligns with the
                  community s vision.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
