import React, { useState, useEffect } from 'react';
import '../Assests/css/lottery.css';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { styled } from '@mui/material/styles';
import Gamesbanner from '../Assests/images/win/game.png';
import gamechange from '../Assests/images/Untitled-1.png'
// import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Navbar from "../Pages/Separate/Navbar";
import axios from 'axios';
import Web3 from 'web3';
import { DiUnitySmall } from 'react-icons/di';
import { FaFlagCheckered } from 'react-icons/fa';
import { IoTimerOutline } from 'react-icons/io5';
import config from '../config/config';
import { FaAngleLeft } from "react-icons/fa6";
import { MdGames } from "react-icons/md";
import { PiCoinsThin } from "react-icons/pi";
import { CgEditBlackPoint } from "react-icons/cg";

import {
    AiOutlinePlayCircle,
    AiOutlineFieldTime,
    AiOutlineArrowLeft,
    AiOutlineArrowRight,
    AiOutlineArrowUp,
    AiOutlineArrowDown,
} from "react-icons/ai";
import { useAccount } from 'wagmi';


const MySwal = withReactContent(Swal)

// const LightTooltip = styled(({ className, ...props }) => (<
//     Tooltip {...props}
//     classes={
//         { popper: className }}
// />
// ))(({ theme }) => ({
//     [`& .${tooltipClasses.tooltip}`]: {
//         backgroundColor: theme.palette.common.white,
//         color: 'rgba(0, 0, 0, 0.87)',
//         boxShadow: theme.shadows[1],
//         fontSize: 11,
//     },
// }));



function Games() {

    const [data, setData] = useState([]);
    const [claim, setclaim] = useState([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
const {address} = useAccount()

    const [useraccount, setAccount] = useState('')
    const web3js = window.ethereum
        ? new Web3(window.ethereum)
        : new Web3(new Web3.providers.HttpProvider(config.DCXRPC_URL));

    const userAccount = async () => {
        const web3 = new Web3(window.ethereum);

        const accounts = await window.ethereum.request({
            method: "eth_requestAccounts",
        });

        setAccount(accounts[0]);
        console.log("account===", accounts[0]);
    }

    const fetchData = async () => {

        try {
            // const userAddress = localStorage.getItem("accountAddress")
            console.log("user", address);
            const response = await axios.get(`${config.BACKEND_PREDICITON}/getusers?page=${page}&pageSize=${pageSize}`, {
                params: { userAddress: address }
            });

            console.log("Response data:", response.data.user);
            setData(response.data.user);
            // setclaim(response.data.user);
        } catch (error) {
            console.error('Error fetching data:', error);
            // Handle error or set a default value for data
        }
    };


    useEffect(() => {

        fetchData();
        userAccount()
    }, [useraccount]);

    setTimeout(() => {
        fetchData()
        console.log("calling time");
    }, 100 * 1000);

    const handleNextPage = () => {
        setPage(prevPage => prevPage + 1);
    };

    const handlePrevPage = () => {
        setPage(prevPage => (prevPage > 1 ? prevPage - 1 : 1));
    };
    return (
        <div>
            <Navbar />

            <div class="statistics-hero-section py-5">
                <a href='/win' className='statistics-banner-back-button mb-4'><FaAngleLeft className='mb-1' /> Back</a>
                <div class="container mt-4">
                    <div class="row align-items-center g-4 flex-lg-row-reverse">
                        <div class="col-lg-6">
                            <img className='games-banner-image-1' src={gamechange} />
                        </div>
                        <div class="col-lg-6">
                            <p className="statistics-text-1"> Game History</p>
                            {/* <p className="statistics-text-2 mt-3">Predict BTC Price - Win the DCX Pool</p> */}
                        </div>
                    </div>
                </div>
            </div>

            <div class="games-second-section py-5">
                <div class="container">
                    <div class="row align-items-center g-4 flex-lg-row-reverse">
                        <div class="col">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th scope="col">ROUND ID<MdGames className='ms-2 mb-1' /></th>
                                        <th scope="col">BET AMOUNT<PiCoinsThin className='ms-2 mb-1' /></th>
                                        <th scope="col">BET POSITION<CgEditBlackPoint className='ms-2 mb-1' /></th>
                                        <th scope="col">STATUS<IoTimerOutline className='ms-2 mb-1' /></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Array.isArray(data) && data.length > 0 ? (
                                        data.map((item) => (
                                            <tr key={item._id}>
                                                <td>{item.roundId}</td>
                                                <td>{Number(item.betamount) / 1e18} DCX</td>
                                                <td>{item.betPosition}</td>
                                                {item.result == "PENDING"
                                                    ? (<td><span className='colour-yellow'>Pending</span></td>)
                                                    : (<td>
                                                        <span className={item.result === 'WIN' ? 'colour-green' : item.result === 'NO RESULT' ? 'colour-white' : 'colour-red'}>
                                                            {item.result}
                                                        </span>
                                                    </td>
                                                    )
                                                }
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colspan="4" style={{ textAlign: "center" }}>NO DATA...</td>
                                        </tr>
                                    )}
                                    {/* <tr>
                                        <td>656253213123</td>
                                        <td>8.255</td>
                                        <td><span className='colour-red'>Loss</span></td>
                                    
                                    </tr>
                                    <tr>
                                        <td>656253213123</td>
                                        <td>7.235</td>
                                        <td><span className='colour-green'>Win</span></td>
                                   
                                    </tr>
                                    <tr>
                                        <td>656253213123</td>
                                        <td>5.555</td>
                                        <td><span className='colour-yellow'>Pending</span></td>
                                        
                                    </tr> */}
                                </tbody>
                            </table>

                            <div style={{ display: 'flex', gap: '20px' }} className='align-items-center'>
                                <button className='reward-arrow-button-new' onClick={handlePrevPage} style={{ background: 'transparent', cursor: 'pointer' }}><AiOutlineArrowLeft style={{ color: 'white' }} /></button>
                                {page}
                                {data.length >= pageSize ? (
                                    <button onClick={handleNextPage} style={{ background: 'transparent', border: 'none', cursor: 'pointer' }}><AiOutlineArrowRight style={{ color: 'white' }} /></button>
                                ) : (
                                    <button className='reward-arrow-button-new' style={{ background: 'transparent', cursor: 'pointer' }} disabled><AiOutlineArrowRight style={{ color: 'white' }} /></button>
                                )}
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    );
}

export default Games;
