let config = {};
let environment = "live";
if (environment == "local") {
  config = {
    FRONTEND_URL: "http://localhost:3000",
    BACKEND_URL: "http://localhost:5000/lottery",
    BACKEND_PREDICITON: "http://localhost:5000/prediction",
    DCXRPC_URL: "http://16.16.183.91:8545/",
    DCXCHAIN_ID: 5272,
    DCX_SYMBOL: "DCX",
    DCX_BLOCKEXPLORER: "https://mainnet-explorer.d-ecosystem.io",
    LOTTERY_TOKENADDRESS: "0x1f5ca9B8509e8766B9832A0B1d6A8f3489eABa97",
    Predictions_Token_Address: "0xcde4b27F4fbB0927eDC090F5CD197e43E35451c9",
    PROJECT_ID: "1f0b9efb280db82bff5edc4689ffa76a",
    API_CMC: "3343c305-4472-4c89-8d02-07541c3d3932",
    predictionContract: "0xcED66210Edd94d160c1152d0Fb875636000c56f8", //"0x2bd4A00301269c59E73Ee15a4e5439c15eF9B9b2", //"0x1e9a6Bd9F39bEF9Dd68Da8a83261cE4E3c19626A",//"0x6A0dDfCE9951c3eaCEf75DE10C7654E6758F489b"  //"0xc069b1710203Ca2fFbE36C6414DAED4Dc5A8Ae3a"
  };
} else if (environment == "demo") {
  config = {
    // FRONTEND_URL: "https://staging.lotteryandprediction.d-ecosystem.io",
    // FRONTEND_URL: "http://localhost:3000",
    BACKEND_URL:
      "https://staging.api.lotteryandprediction.d-ecosystem.io/lottery",
    BACKEND_PREDICITON:
      "https://staging.api.lotteryandprediction.d-ecosystem.io/prediction",
    DCXRPC_URL: "https://mainnetcoin.d-ecosystem.io/",
    DCXCHAIN_ID: 5272,
    DCX_SYMBOL: "DCX",
    DCX_BLOCKEXPLORER: "https://mainnet-explorer.d-ecosystem.io",
    LOTTERY_TOKENADDRESS: "0x6086134351A0EeE4a5AeD34c8ccd917F928c6aB2",
    PROJECT_ID: "1f0b9efb280db82bff5edc4689ffa76a",
    Predictions_Token_Address: "0xcde4b27F4fbB0927eDC090F5CD197e43E35451c9",
    predictionContract: "0xcED66210Edd94d160c1152d0Fb875636000c56f8", //"0x2D12054Fe9735dA5C85EC994BEb7C8eaD6E5cB00",//"0x1e9a6Bd9F39bEF9Dd68Da8a83261cE4E3c19626A",//"0x6A0dDfCE9951c3eaCEf75DE10C7654E6758F489b"  //"0xc069b1710203Ca2fFbE36C6414DAED4Dc5A8Ae3a"
  };
} else {
  config = {
    FRONTEND_URL: "https://dao.d-ecosystem.io/",
    BACKEND_URL: "https://api-dao.d-ecosystem.io/lottery",
    BACKEND_PREDICITON: "https://api-dao.d-ecosystem.io/prediction",
    DCXRPC_URL: "https://mainnetcoin.d-ecosystem.io/",

    DCXCHAIN_ID: 5272,
    DCX_SYMBOL: "DCX",
    DCX_BLOCKEXPLORER: "https://mainnet-explorer.d-ecosystem.io",
    LOTTERY_TOKENADDRESS: "0xb9aDb6C356aBf4050415eA1F3d214307F3b51609",
    PROJECT_ID: "1f0b9efb280db82bff5edc4689ffa76a",
    Predictions_Token_Address: "0xcde4b27F4fbB0927eDC090F5CD197e43E35451c9",
    predictionContract: "0xcED66210Edd94d160c1152d0Fb875636000c56f8", //"0x1e9a6Bd9F39bEF9Dd68Da8a83261cE4E3c19626A",//"0x6A0dDfCE9951c3eaCEf75DE10C7654E6758F489b"  //"0xc069b1710203Ca2fFbE36C6414DAED4Dc5A8Ae3a"
  };
}

export default config;
