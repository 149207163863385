import React, { useState, useEffect, useRef } from "react";
import "../Assests/css/lottery.css";
import { FaChevronDown, FaTicketAlt } from "react-icons/fa";
import { HiArrowRight, HiArrowLeft } from "react-icons/hi";
import {
  BiArrowToRight,
  BiLeftArrowAlt,
  BiRightArrowAlt,
} from "react-icons/bi";
import left from "../Assests/images/win/newleft.gif";
import right from "../Assests/images/win/newright.gif";
import buyticket from "../Assests/images/win/buyticket.png";
import onSaleSoon from "../Assests/images/win/onSaleSoon.png";
import Layerleft from "../Assests/images/win/Layerleft.png";
import Layerright from "../Assests/images/win/Layerright.png";
import Layeropen1 from "../Assests/images/win/Layeropen-1.png";
import Layeropen2 from "../Assests/images/win/Layeropen-2.png";
import explain from "../Assests/images/win/explain4.png";
import explain2 from "../Assests/images/win/explain3.png";
import Group2 from "../Assests/images/win/Group2.png";
import Web3 from "web3";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import axios from "axios";
import Navbar from "../Pages/Separate/Navbar";
import { Tooltip } from "react-tooltip";
import { CiCircleInfo } from "react-icons/ci";

import Bgimagewin from "../Assests/images/win/bg-image-win.jpg";
import {
  useAccount,
  useConnect,
  useDisconnect,
  useEnsAvatar,
  useEnsName,
  useNetwork,
  useSwitchNetwork,
} from "wagmi";
import config from "../config/config";
import lotteryAbi from "../abi/LotteryAbi.json";
import { EthereumProvider } from "@walletconnect/ethereum-provider";
import { FaQuestionCircle } from "react-icons/fa";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import { TfiGift } from "react-icons/tfi";
import { IoIosArrowForward } from "react-icons/io";
import { watchAccount } from "@wagmi/core";
import pdf from "../Assests/images/DAO.pdf"

const MySwal = withReactContent(Swal);

function Lottery() {
  const { address, isConnected, connector } = useAccount();
  const { connect, connectors, isLoading, pendingConnector } = useConnect();
  const { chain } = useNetwork();
  const { chains, pendingChainId, switchNetwork, error } = useSwitchNetwork();

  const [ticketCount, setTicketCount] = useState("");
  const [balance, setBalance] = useState(0);
  const [ticketAmount, setTicketAmount] = useState(0);
  const [ticketCost, setTicketCost] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [discountPercentage, setDiscountPercentage] = useState();
  const [total, setTotal] = useState(0);
  const [currentround, setcurrentRound] = useState(0);
  const [dcxprice, setdcxprice] = useState(0);
  const [numberofTicket, setNumberOfTicket] = useState(0);
  const [multiTicketRandNum, setMultiTicketRandNum] = useState();
  const [matchonedcxwin, setMatchonedcxwin] = useState(0);
  const [matchoneusdwin, setMatchoneusdwin] = useState(0);
  const [matchtwodcxwin, setMatchtwodcxwin] = useState(0);
  const [matchtwousdwin, setMatchtwousdwin] = useState(0);
  const [matchthreedcxwin, setMatchthreedcxwin] = useState(0);
  const [matchthreeusdwin, setMatchthreeusdwin] = useState(0);
  const [matchfourdcxwin, setMatchfourdcxwin] = useState(0);
  const [matchfourusdwin, setMatchfourusdwin] = useState(0);
  const [matchfivedcxwin, setMatchfivedcxwin] = useState(0);
  const [matchfiveusdwin, setMatchfiveusdwin] = useState(0);
  const [matchsixdcxwin, setMatchsixdcxwin] = useState(0);
  const [matchsixusdwin, setMatchsixusdwin] = useState(0);
  const [burndcxwin, setBurndcxwin] = useState(0);
  const [burnusdwin, setBurnusdwin] = useState(0);
  const [matchonedcx, setMatchonedcx] = useState(0);
  const [matchoneusd, setMatchoneusd] = useState(0);
  const [matchtwodcx, setMatchtwodcx] = useState(0);
  const [matchtwousd, setMatchtwousd] = useState(0);
  const [matchthreedcx, setMatchthreedcx] = useState(0);
  const [matchthreeusd, setMatchthreeusd] = useState(0);
  const [matchfourdcx, setMatchfourdcx] = useState(0);
  const [matchfourusd, setMatchfourusd] = useState(0);
  const [matchfivedcx, setMatchfivedcx] = useState(0);
  const [matchfiveusd, setMatchfiveusd] = useState(0);
  const [matchsixdcx, setMatchsixdcx] = useState(0);
  const [matchsixusd, setMatchsixusd] = useState(0);
  const [burndcx, setBurndcx] = useState(0);
  const [burnusd, setBurnusd] = useState(0);
  const [totalPlayers, setTotalPlayers] = useState(0);
  const [winninghistory, setWinningHistory] = useState([]);
  const [winningNumber, setWinningNumber] = useState([]);
  const [formattedDate, setFormattedDate] = useState();
  const [formattedDateDraw, setFormattedDateDraw] = useState();
  const [inputRound, setInputRound] = useState(0);
  const [open, setOpen] = useState(false);
  const [price, setPrice] = useState();
  const [Hours, setHours] = useState(0);
  const [Minutes, setMinutes] = useState(0);
  const [Seconds, setSeconds] = useState(0);
  const [count, setCount] = useState();
  const [Loading, setLoading] = useState(false);
  const [userHistory, setUserHistory] = useState([]);
  const [userTicketNumber, setUserTicketNumber] = useState([]);
  const [winningTicketDigit, setWinningTicketDigit] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [winningNumberInHistory, setWinningNumberInHistory] = useState([]);
  const [firstDigitWin, setFirstDigitWin] = useState(0);
  const [secondDigitWin, setSecondDigitWin] = useState(0);
  const [thirdDigitWin, setThirdDigitWin] = useState(0);
  const [fourthDigitWin, setFourthDigitWin] = useState(0);
  const [fifthDigitWin, setFifthDigitWin] = useState(0);
  const [sixDigitWin, setSixDigitWin] = useState(0);
  const [dcxRewards, setDcxRewards] = useState(0);
  const [usdRewards, setUsdRewards] = useState(0);
  const [winningTicketCount, setWinningTicketCount] = useState(0);
  const [claimLoading, setClaimLoading] = useState(false);
  const [lotteryEndTime, setLotteryEndTime] = useState();
  const [closeBuyTicket, setCloseBuyTicket] = useState(true);
  const [roundEnd, setRoundEnd] = useState();
  const [ticketImage, setTicketImage] = useState();
  const [matchFirst1, setMatchedFirst1] = useState(0);
  const [matchFirst2, setMatchedFirst2] = useState(0);
  const [matchFirst3, setMatchedFirst3] = useState(0);
  const [matchFirst4, setMatchedFirst4] = useState(0);
  const [matchFirst5, setMatchedFirst5] = useState(0);
  const [matchFirst6, setMatchedFirst6] = useState(0);

  const [dcxpriceusd, setdcxpriceusd] = useState(0);
  const [winpriceusd, setWinPriceUsd] = useState(0);
  const [oneDcxPriceUsd, setOneDcxPriceUsd] = useState(0);

  const navigate = useNavigate();
  const location = useLocation();

  function getFlooredFixed(v, d) {
    return (Math.floor(v * Math.pow(10, d)) / Math.pow(10, d)).toFixed(d);
  }

  const openModal = async () => {
    setTicketCount("");
    setTicketCost(0);
    setTicketAmount(0);
    setDiscount(0);
    setTotal(0);
  };

  const lotteryTime = async () => {
    const lotteryEnd = await axios.get(`${config.BACKEND_URL}/lotteryTime`);
    setLotteryEndTime(lotteryEnd.data.message);
    setRoundEnd(lotteryEnd.data.RoundEnd);
    setTicketImage(lotteryEnd.data.ticketImage);
    if (lotteryEnd.data.message == "lottery is running") {
      setCloseBuyTicket(false);
    }
  };

  const closeBuyTicketModal = () => {
    if (isConnected) {
      toast.warn(lotteryEndTime);
    } else {
      toast.error("Connect your wallet");
    }
  };

  const enterlotteryCount = async (e) => {
    // console.log(e.target.value);
    const inputValue = e.target.value;

    if (inputValue >= 1 && inputValue <= count) {
      setTicketCount(inputValue);
    } else if (inputValue != 0 && inputValue != "") {
      setTicketCount(count);
    } else {
      setTicketCount("");
    }
  };

  const handleClose = async () => {
    setTicketCount("");
    setTicketCost(0);
    setTicketAmount(0);
    setTotal(0);
  };

  const mintlottery = async () => {
    let userMetaAddress = localStorage.getItem("wagmi.store");
    if (userMetaAddress) {
      let userMeta = JSON.parse(userMetaAddress);
      let addr = userMeta.state.data.account;
      if (addr) {
        let chainid = userMeta.state.data.chain.id;
        if (ticketAmount > balance) {
          toast.error("Insufficient DCX balance");
        } else if (chainid != config.DCXCHAIN_ID) {
          switchNetwork?.(chains[0].id);
        } else {
          const response = await axios.post(`${config.BACKEND_URL}/getTicketCount`, {address,currentround});
          const ticketcount = response.data.TicketCount
          const sum = ticketcount.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
          const ticket = count - sum
          if( ticketCount > ticket ){
            if(ticket == 0){
              toast.error(`You already bought maximum number of tickets`)
            }else{
            toast.error(`You can only buy ${ticket} tickets`)
            }
          }else{
          try {
            const provider = await connector.getProvider();
            const web3 = new Web3(provider);
            const tokenaddress = web3.utils.toChecksumAddress(
              config.LOTTERY_TOKENADDRESS
            );
            const dcxcontract = await new web3.eth.Contract(
              lotteryAbi,
              tokenaddress
            );
            console.log(dcxcontract, "dcxcontract--");
            setLoading(true);
            const ticket = await dcxcontract.methods
              .buyTicket(ticketCount, currentround)
              .send({
                from: address,
                value: web3.utils.toWei(String(total), "ether"),
              });

            if (ticket.status) {
              // alert(ticket.status);
              handleTicketBought(ticket);
            } else {
              // alert("failed");
            }
          } catch (error) {
            // toast.error("User denied transaction signature");
            setLoading(false);
            // alert(error);
          }
        }
      }
      } else {
        toast.error("Connect your wallet");
      }
    }
  };

  const handleTicketBought = async (userlotterydata) => {
    try {
      const transactionHash = userlotterydata.transactionHash;
      const ticketCount =
        userlotterydata.events.TicketBought.returnValues.numTickets;
      const ticketNumber =
        userlotterydata.events.TicketBought.returnValues.ticketNumbers;
      const address = userlotterydata.events.TicketBought.returnValues.player;
      const roundNumber = currentround;
      const data = await axios.post(`${config.BACKEND_URL}/lottery`, {
        transactionHash,
        roundNumber,
        address,
        ticketCount,
        total,
        ticketNumber,
      });

      if (data) {
        console.log("success");
        toast.success("Lottery tickets purchased!");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (error) {
      console.error("Error in TicketBought event:", error);
      // alert(error);
    }
  };

  const getDcxBalance = async () => {
    try {
      var web3 = new Web3(config.DCXRPC_URL);
      if (isConnected) {
        const balance = await web3.eth.getBalance(address);
        const dcxbalance = await web3.utils.fromWei(balance, "ether");
        const dcxbalanceFormatted = getFlooredFixed(dcxbalance, 4);
        // console.log(dcxbalanceFormatted, "balance");
        setBalance(Number(dcxbalanceFormatted));
      }
    } catch (error) {
      console.error("Error fetching balance:", error);
    }
  };

  const getTicketAmount = async (round) => {
    // if(ticketCount == 1){
    // const response = await axios.get(`${config.BACKEND_URL}/getPriceApi`);
    // const dcxpriceusd = response.data.data;
    // const onedcxpriceusd = parseFloat(dcxpriceusd).toFixed(4);
    // // console.log(onedcxpriceusd,"onedcx");
    // const response = await axios.post(`${config.BACKEND_URL}/getdetails`, {
    //   currentround,
    // });
    // console.log(response.data, "res---");
    // if (response) {
    //   const getDetails = response.data.getDetails;
    //   if (getDetails && getDetails.length > 0) {
    //     // const count = getDetails[0].ticketCount;
    //     // setCount(count);
    //     const price = getDetails[0].ticketPrice;
    //     console.log(price);
    const web3 = new Web3(config.DCXRPC_URL);
    const tokenaddress = web3.utils.toChecksumAddress(
      config.LOTTERY_TOKENADDRESS
    );
    const dcxcontract = await new web3.eth.Contract(lotteryAbi, tokenaddress);
    // console.log(dcxcontract);
    const priceInEther = await dcxcontract.methods.viewLottery(round).call();
    // console.log(typeof(priceInEther[5]));
    const price = await web3.utils.fromWei(priceInEther[5].toString(), "ether");
    // console.log(price,"price");
    setPrice(price);
    const ticket = ticketCount * price;
    const ticketfixed = getFlooredFixed(ticket, 3);
    setTicketAmount(Number(ticketfixed));
    if (ticketCount >= 1) {
      setTicketCost(Number(ticketfixed));
    }
    // }
    // }
    // }
  };

  const ticketDiscount = async () => {
    if (ticketCount == 1 || ticketCount == 0) {
      const percentage = 0;
      setDiscountPercentage(percentage);
    } else if (ticketCount >= 2 && ticketCount <= 100) {
      const percentage = 0 + 0.05 * (ticketCount - 1);
      setDiscountPercentage(percentage.toFixed(2));
    }
    const dis = ticketCost * ((discountPercentage * 1) / 100);
    const discount = getFlooredFixed(dis, 8);
    setDiscount(discount);
    if (ticketCount >= 1) {
      const totalPrice = ticketCost - dis;
      const Total = getFlooredFixed(totalPrice, 5);
      setTotal(Total);
    }
  };

  const getpriceapi = async () => {
    try {
      const response = await axios.get(`${config.BACKEND_URL}/getPriceApi`);
      const result = response.data;
      const onedcxpriceusd = result;
      setOneDcxPriceUsd(onedcxpriceusd);
      // console.log(onedcxpriceusd,"priceusd");
      const web3 = new Web3(config.DCXRPC_URL);
      const tokenaddress = web3.utils.toChecksumAddress(
        config.LOTTERY_TOKENADDRESS
      );
      const dcxcontract = await new web3.eth.Contract(lotteryAbi, tokenaddress);
      const balance = await dcxcontract.methods.getLotteryBalance().call();
      const balanceEther = web3.utils.fromWei(balance.toString(), "ether");
      setdcxprice(balanceEther);
      // console.log(balanceEther, "balance--");
      const dcxtousd = balanceEther * onedcxpriceusd;
      const dcxtousdfixed = getFlooredFixed(dcxtousd, 4);
      // console.log(dcxtousdfixed, "dcxtousd");
      setdcxpriceusd(dcxtousdfixed);
      const players = await dcxcontract.methods
        .getPlayerList(currentround)
        .call();
      setTotalPlayers(players.length);
      // console.log(players);
    } catch (error) {
      console.log(error);
    }
  };

  const getWinner = async (round) => {
    try {
      // const roundNumber = round - 1;
      // // console.log(round,"round");
      // const history = await axios.post(`${config.BACKEND_URL}/getwinner`, {
      //   address,
      //   roundNumber,
      // });
      // const userhistory = history.data.getData;
      // // console.log(userhistory, "---");

      // const response = await axios.post(
      //   `${config.BACKEND_URL}/getwinninghistory`,
      //   { roundNumber }
      // );
      // const result = response.data.winninghistory;
      // // console.log(result,"result");
      // const winningTicket = result[0].winningNumber;

      // if (userhistory && userhistory.length > 0) {
      //   let number = [];
      //   for (let i = 0; i < userhistory.length; i++) {
      //     number = number.concat(userhistory[i].ticketNumber);
      //   }
      //   // console.log(number, "number--");

      //   let matchDigit = [];

      //   for (let i = 0; i < number.length; i++) {
      //     const alltickets = number[i].toString();
      //     // console.log(alltickets, "alltickets");
      //     // console.log(winningTicket, "winningTicket");
      //     const ticket = winningTicket.toString();
      //     let Digit = "No Match";

      //     for (let j = 0; j < ticket.length; j++) {
      //       if (alltickets[j] === ticket[j]) {
      //         Digit = `Matched first ${j + 1}`;
      //       } else {
      //         break;
      //       }
      //     }
      //     matchDigit.push(Digit);
      //   }
      // // console.log(matchDigit);
      // for (let k = 0; k < matchDigit.length; k++) {
      //   if (matchDigit[k] != "No Match") {
      //     // console.log("open");
      //     setOpen(true);
      //   }
      // }
      const web3 = new Web3(config.DCXRPC_URL);
      const tokenaddress = web3.utils.toChecksumAddress(
        config.LOTTERY_TOKENADDRESS
      );
      const dcxcontract = await new web3.eth.Contract(lotteryAbi, tokenaddress);
      const reward = await dcxcontract.methods.getclaimRewards(address).call();
      const rewarddcx = await web3.utils.fromWei(reward, "ether");
      const claimreward = getFlooredFixed(rewarddcx, 5);
      setDcxRewards(claimreward);
      const rewardpricedcxtousd = rewarddcx * oneDcxPriceUsd;
      const rewardusd = getFlooredFixed(rewardpricedcxtousd, 5);
      setUsdRewards(rewardusd);
      if (rewarddcx > 0) {
        setOpen(true);
      }
      // }
    } catch (error) {
      console.log(error);
    }
  };

  const checkNow = async () => {
    setModalOpen(true);
  };

  // const rewardsDetails = async () => {
  //   try {
  //     const web3 = new Web3(config.DCXRPC_URL);
  //     const tokenaddress = web3.utils.toChecksumAddress(
  //       config.LOTTERY_TOKENADDRESS
  //     );
  //     const dcxcontract = await new web3.eth.Contract(lotteryAbi, tokenaddress);
  //     const previousRound = currentround - 1
  //     const reward = await dcxcontract.methods.getclaimRewards(address,previousRound).call();
  //     const rewarddcx = await web3.utils.fromWei(reward, "ether");
  //     setDcxRewards(rewarddcx);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const getMatchWinningNumber = async () => {
    const matchonedcxprice = dcxprice * (2 / 100);
    const matchone = getFlooredFixed(matchonedcxprice, 5);
    setMatchonedcx(matchone);
    const matchoneusdprice = dcxpriceusd * (2 / 100);
    const matchoneusd = getFlooredFixed(matchoneusdprice, 5);
    setMatchoneusd(matchoneusd);
    const matchtwodcxprice = dcxprice * (3 / 100);
    const matchtwo = getFlooredFixed(matchtwodcxprice, 5);
    setMatchtwodcx(matchtwo);
    const matchtwousdprice = dcxpriceusd * (3 / 100);
    const matchtwousd = getFlooredFixed(matchtwousdprice, 5);
    setMatchtwousd(matchtwousd);
    const matchthreedcxprice = dcxprice * (5 / 100);
    const matchthree = getFlooredFixed(matchthreedcxprice, 5);
    setMatchthreedcx(matchthree);
    const matchthreeusdprice = dcxpriceusd * (5 / 100);
    const matchthreeusd = getFlooredFixed(matchthreeusdprice, 5);
    setMatchthreeusd(matchthreeusd);
    const matchfourdcxprice = dcxprice * (10 / 100);
    const matchfour = getFlooredFixed(matchfourdcxprice, 5);
    setMatchfourdcx(matchfour);
    const matchfourusdprice = dcxpriceusd * (10 / 100);
    const matchfourusd = getFlooredFixed(matchfourusdprice, 5);
    setMatchfourusd(matchfourusd);
    const matchfivedcxprice = dcxprice * (20 / 100);
    const matchfive = getFlooredFixed(matchfivedcxprice, 5);
    setMatchfivedcx(matchfive);
    const matchfiveusdprice = dcxpriceusd * (20 / 100);
    const matchfiveusd = getFlooredFixed(matchfiveusdprice, 5);
    setMatchfiveusd(matchfiveusd);
    const matchsixdcxprice = dcxprice * (40 / 100);
    const matchsix = getFlooredFixed(matchsixdcxprice, 5);
    setMatchsixdcx(matchsix);
    const matchsixusdprice = dcxpriceusd * (40 / 100);
    const matchsixusd = getFlooredFixed(matchsixusdprice, 5);
    setMatchsixusd(matchsixusd);
    const burndcxprice = dcxprice * (20 / 100);
    const burn = getFlooredFixed(burndcxprice, 5);
    setBurndcx(burn);
    const burnusdprice = dcxpriceusd * (20 / 100);
    const burnusd = getFlooredFixed(burnusdprice, 5);
    setBurnusd(burnusd);
  };

  const LeftArrow = async (event) => {
    const inputValue = Number(event);
    setInputRound(inputValue);
  };

  const RightArrow = async (event) => {
    const inputValue = Number(event);
    if (inputValue < Number(currentround)) {
      setInputRound(inputValue);
    } else if (currentround == 0) {
      setInputRound(currentround);
    } else {
      setInputRound(currentround - 1);
    }
  };

  const LastArrow = async (event) => {
    // console.log(Number(event), "event");
    const inputValue = Number(event);
    if (inputValue >= 0) {
      setInputRound(inputValue);
    }
  };

  const Round = async (event) => {
    // console.log(event, "event");

    const inputValue = Number(event);

    if (inputValue < Number(currentround)) {
      setInputRound(inputValue);
    } else if (currentround == 0) {
      setInputRound(currentround);
    } else {
      setInputRound(currentround - 1);
    }
  };

  const getWinningHistory = async () => {
    try {
      const roundNumber = inputRound;
      // console.log(roundNumber, "inputround");
      const response = await axios.post(
        `${config.BACKEND_URL}/getwinninghistory`,
        { roundNumber }
      );
      const result = response.data.winninghistory;
      setWinningHistory(result);
      if (result && result.length > 0) {
        const number = result[0].winningNumber;
        // console.log(number, "winningnumber");
        const arrOfDigits = Array.from(String(number), Number);
        setWinningNumber(arrOfDigits);

        const dateAndTime = result[0].TimeStamp;
        const dateObject = new Date(dateAndTime * 1000); // Convert seconds to milliseconds
        const options = {
          month: "short",
          day: "numeric",
          year: "numeric",
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        };

        const formatted = new Intl.DateTimeFormat("en-US", options).format(
          dateObject
        );

        setFormattedDate(`Drawn ${formatted}`);

        const firstDigit = result[0].firstDigitWin;
        setFirstDigitWin(firstDigit);
        const secondDigit = result[0].secondDigitWin;
        setSecondDigitWin(secondDigit);
        const thirdDigit = result[0].thirdDigitWin;
        setThirdDigitWin(thirdDigit);
        const fourthDigit = result[0].fourDigitWin;
        setFourthDigitWin(fourthDigit);
        const fifthDigit = result[0].fiveDigitWin;
        setFifthDigitWin(fifthDigit);
        const sixDigit = result[0].sixDigitWin;
        setSixDigitWin(sixDigit);

        const price = result[0].pricePot;
        const res = await axios.get(`${config.BACKEND_URL}/getPriceApi`);
        const resu = res.data;
        const onedcxpriceusd = resu;
        const usdconvert = price * onedcxpriceusd;
        const priceusd = getFlooredFixed(usdconvert, 4);
        setWinPriceUsd(priceusd);
        const matchonedcxprice = price * (2 / 100);
        const matchone = getFlooredFixed(matchonedcxprice, 5);
        setMatchonedcxwin(matchone);
        const matchoneusdprice = priceusd * (2 / 100);
        const matchoneusd = getFlooredFixed(matchoneusdprice, 5);
        setMatchoneusdwin(matchoneusd);
        const matchtwodcxprice = price * (3 / 100);
        const matchtwo = getFlooredFixed(matchtwodcxprice, 5);
        setMatchtwodcxwin(matchtwo);
        const matchtwousdprice = priceusd * (3 / 100);
        const matchtwousd = getFlooredFixed(matchtwousdprice, 5);
        setMatchtwousdwin(matchtwousd);
        const matchthreedcxprice = price * (5 / 100);
        const matchthree = getFlooredFixed(matchthreedcxprice, 5);
        setMatchthreedcxwin(matchthree);
        const matchthreeusdprice = priceusd * (5 / 100);
        const matchthreeusd = getFlooredFixed(matchthreeusdprice, 5);
        setMatchthreeusdwin(matchthreeusd);
        const matchfourdcxprice = price * (10 / 100);
        const matchfour = getFlooredFixed(matchfourdcxprice, 5);
        setMatchfourdcxwin(matchfour);
        const matchfourusdprice = priceusd * (10 / 100);
        const matchfourusd = getFlooredFixed(matchfourusdprice, 5);
        setMatchfourusdwin(matchfourusd);
        const matchfivedcxprice = price * (20 / 100);
        const matchfive = getFlooredFixed(matchfivedcxprice, 5);
        setMatchfivedcxwin(matchfive);
        const matchfiveusdprice = priceusd * (20 / 100);
        const matchfiveusd = getFlooredFixed(matchfiveusdprice, 5);
        setMatchfiveusdwin(matchfiveusd);
        const matchsixdcxprice = price * (40 / 100);
        const matchsix = getFlooredFixed(matchsixdcxprice, 5);
        setMatchsixdcxwin(matchsix);
        const matchsixusdprice = priceusd * (40 / 100);
        const matchsixusd = getFlooredFixed(matchsixusdprice, 5);
        setMatchsixusdwin(matchsixusd);
        const burndcxprice = price * (20 / 100);
        const burn = getFlooredFixed(burndcxprice, 5);
        setBurndcxwin(burn);
        const burnusdprice = priceusd * (20 / 100);
        const burnusd = getFlooredFixed(burnusdprice, 5);
        setBurnusdwin(burnusd);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const nextDraw = async (round) => {
    // console.log(round);
    //   if(round > 1){
    //   const roundNumber = round - 1;
    //   const response = await axios.post(
    //     `${config.BACKEND_URL}/getwinninghistory`,
    //     { roundNumber }
    //   );
    //   const result = response.data.winninghistory;
    //   if (result && result.length > 0) {
    //     const dateAndTime = result[0].createdDate;
    //     const dateObject = new Date(dateAndTime);
    //     const newDate = new Date(dateObject.getTime() + 12 * 60 * 60 * 1000);
    //     const optionsdraw = {
    //       month: "short",
    //       day: "numeric",
    //       year: "numeric",
    //       hour: "numeric",
    //       minute: "numeric",
    //       hour12: true,
    //     };
    //     const formatteddraw = newDate.toLocaleDateString("en-US", optionsdraw);

    //     setFormattedDateDraw(formatteddraw);
    //     console.log(formatteddraw);
    //   }
    // }else{
    const web3 = new Web3(config.DCXRPC_URL);
    const tokenaddress = web3.utils.toChecksumAddress(
      config.LOTTERY_TOKENADDRESS
    );
    const dcxcontract = await new web3.eth.Contract(lotteryAbi, tokenaddress);
    // console.log(dcxcontract);
    const endTime = await dcxcontract.methods.viewLottery(round).call();
    // console.log(endTime[3], "endtime");
    if (endTime[3] > 0) {
      const dateObject = new Date(endTime[3] * 1000); // Convert seconds to milliseconds
      const options = {
        month: "short",
        day: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      };

      const formatteddraw = new Intl.DateTimeFormat("en-US", options).format(
        dateObject
      );
      setFormattedDateDraw(formatteddraw);
    }
    // }
  };

  const getUserHistories = async () => {
    try {
      const userhistory = await axios.post(
        `${config.BACKEND_URL}/getuserlotteryhistory`,
        {
          address,
        }
      );
      setUserHistory(userhistory.data.getData);
    } catch (error) {
      console.log(error);
    }
  };

  const getUserHistory = async (round, id) => {
    // console.log(round, id);
    try {
      const roundNumber = round;
      const history = await axios.post(
        `${config.BACKEND_URL}/getuserlotterydetails`,
        {
          address,
          roundNumber,
          id,
        }
      );
      const userhistory = history.data.getData;

      const response = await axios.post(
        `${config.BACKEND_URL}/getwinninghistory`,
        { roundNumber }
      );
      const result = response.data.winninghistory;
      const winningTicket = result[0].winningNumber;
      const arrOfDigits = Array.from(String(winningTicket), Number);
      setWinningNumberInHistory(arrOfDigits);
      // console.log(userhistory,"---");
      if (userhistory && userhistory.length > 0) {
        const number = userhistory[0].ticketNumber;
        setUserTicketNumber(number);
        // console.log(number,"number--");
        let matchDigit = [];

        for (let i = 0; i < number.length; i++) {
          const alltickets = number[i].toString();
          // console.log(alltickets, "alltickets");

          const ticket = winningTicket.toString();
          let Digit = "No Match";

          for (let j = 0; j < ticket.length; j++) {
            if (alltickets[j] === ticket[j]) {
              Digit = `Matched first ${j + 1}`;
            } else {
              break;
            }
          }

          // console.log(Digit, "digit");
          matchDigit.push(Digit);
        }

        setWinningTicketDigit(matchDigit);
        let matchdigitIndex = [];
        for (let i = 0; i < matchDigit.length; i++) {
          if (matchDigit[i] != "No Match") {
            matchdigitIndex.push(i);
          }
        }
        setWinningTicketCount(matchdigitIndex.length);

        const matchedFirst1Count = matchDigit.filter(
          (item) => item === "Matched first 1"
        ).length;
        setMatchedFirst1(matchedFirst1Count);
        const matchedFirst2Count = matchDigit.filter(
          (item) => item === "Matched first 2"
        ).length;
        setMatchedFirst2(matchedFirst2Count);
        const matchedFirst3Count = matchDigit.filter(
          (item) => item === "Matched first 3"
        ).length;
        setMatchedFirst3(matchedFirst3Count);
        const matchedFirst4Count = matchDigit.filter(
          (item) => item === "Matched first 4"
        ).length;
        setMatchedFirst4(matchedFirst4Count);
        const matchedFirst5Count = matchDigit.filter(
          (item) => item === "Matched first 5"
        ).length;
        setMatchedFirst5(matchedFirst5Count);
        const matchedFirst6Count = matchDigit.filter(
          (item) => item === "Matched first 6"
        ).length;
        setMatchedFirst6(matchedFirst6Count);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const formatHistorydate = (historydate) => {
    const dateObject = new Date(historydate);
    const options = {
      month: "short",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    const formatted = dateObject.toLocaleDateString("en-US", options);
    return formatted;
  };

  const getRound = async () => {
    const web3 = new Web3(config.DCXRPC_URL);
    const tokenaddress = web3.utils.toChecksumAddress(
      config.LOTTERY_TOKENADDRESS
    );
    const dcxcontract = await new web3.eth.Contract(lotteryAbi, tokenaddress);
    const roundNumber = await dcxcontract.methods.LotteryId().call();
    const lotteryId = roundNumber - 1;
    setcurrentRound(lotteryId);
    if (currentround > 1 && isConnected) {
      getUserHistories();
      getWinner(lotteryId);
    }
    // console.log(roundNumber, "round");
    // const response = await axios.post(`${config.BACKEND_URL}/getticketnumber`, {
    //   address,
    //   roundNumber,
    // });
    // const result = response.data.data;
    if (isConnected) {
      const allTicketNumber = await dcxcontract.methods
        .getAllTicketNumbers(currentround, address)
        .call();
      let combinedTicket = [];
      for (let index = 0; index < allTicketNumber.length; index++) {
        combinedTicket = combinedTicket.concat(allTicketNumber[index]);
      }
      setMultiTicketRandNum(combinedTicket);
      setNumberOfTicket(combinedTicket.length);
    }
    const MaxticketPerPerson = await dcxcontract.methods
      .getMaxTicketsPerPerson(currentround)
      .call();
    setCount(Number(MaxticketPerPerson));
    // console.log(MaxticketPerPerson);
  };
  const ticketElements = multiTicketRandNum
    ? multiTicketRandNum.map((ticketNum, index) => (
        <>
          <div className="col">
            <div className="row">
              <div className="col-4">
                <h4 className="d-flex flex-row justify-content-evenly buy-ticket-input-section mt-3">
                  #{index + 1}
                </h4>
              </div>
              <div className="col-8">
                <div className="d-flex flex-row justify-content-evenly buy-ticket-input-section mt-3">
                  <div>
                    <h4>{ticketNum}</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ))
    : null;

  function calculateRemainingTime() {
    if (closeBuyTicket == true) {
      const originalTimestampMilliseconds = roundEnd * 1000;

      const originalDate = new Date(originalTimestampMilliseconds);

      originalDate.setMinutes(originalDate.getMinutes() + 5);

      const currentDate = new Date();
      const timeDifference = originalDate - currentDate;

      const minutes = Math.floor(timeDifference / (1000 * 60));
      const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

      setMinutes(minutes);
      setSeconds(seconds);

      if (minutes == 0 && seconds == 0) {
        window.location.reload();
      }
    } else {
      const targetDate = new Date(formattedDateDraw);

      // Check if the date is valid

      const currentDate = new Date();
      const timeDifference = targetDate - currentDate;

      const hours = Math.floor(timeDifference / (1000 * 60 * 60));
      const minutes = Math.floor(
        (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
      );
      const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

      setHours(hours);
      setMinutes(minutes);
      setSeconds(seconds);

      if (hours == -1 && minutes == -1 && seconds == -1) {
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    }
  }

  const disconnectAccount = async () => {
    const provider = await EthereumProvider.init({
      projectId: config.PROJECT_ID,
      chains: [config.DCXCHAIN_ID],
    });

    provider.on("disconnect", (code, reason) => {
      // console.log("WalletConnect disconnected:", code, reason);
      navigate("/dashboard");
      localStorage.clear();
    });
  };

  const claimRewards = async () => {
    try {
      let userMetaAddress = localStorage.getItem("wagmi.store");
      let userMeta = JSON.parse(userMetaAddress);
      let chainid = userMeta.state.data.chain.id;
      if (chainid != config.DCXCHAIN_ID) {
        switchNetwork?.(chains[0].id);
      } else {
        const provider = await connector.getProvider();
        const web3 = new Web3(provider);
        const tokenaddress = web3.utils.toChecksumAddress(
          config.LOTTERY_TOKENADDRESS
        );
        const dcxcontract = await new web3.eth.Contract(
          lotteryAbi,
          tokenaddress
        );
        console.log(dcxcontract, "dcxcontract--");
        setClaimLoading(true);
        const gasPrice = await web3.eth.getGasPrice();
        const reward = await dcxcontract.methods.claimRewards(address).send({
          from: address,
          gas: 2000000,
          gasPrice: gasPrice,
        });
        console.log(reward, "reward--");
        const tnxHash = reward.transactionHash;
        const Rewards = Number(reward.events.RewardClaimed.returnValues.amount);
        const claimedRewards = await web3.utils.fromWei(
          Rewards.toString(),
          "ether"
        );
        const rewards = await axios.post(
          `${config.BACKEND_URL}/claimrewardsdetails`,
          { currentround, address, tnxHash, claimedRewards }
        );
        if (rewards) {
          setClaimLoading(false);
          toast.success("rewards claimed successfully");
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
      }
    } catch (error) {
      console.log(error);
      setClaimLoading(false);
    }
  };
  useEffect(() => {
	  if(address){
		switchChain(5272)
	  }
	
	  
	},)
	const switchChain = async (targetChainId) => {
		console.log('calling');
		if (window.ethereum) {
			try {
				// Convert targetChainId to a hexadecimal string with '0x' prefix
				const hexTargetChainId = "0x" + targetChainId.toString(16);
	
				await window.ethereum.request({
					method: "wallet_switchEthereumChain",
					params: [{ chainId: hexTargetChainId }],
				});
	
				// console.log('Switched to chain with ID:', hexTargetChainId);
			} catch (error) {
				if (error.code === 4902) {
					// console.log('User rejected the chain switch');
				} else {
					console.error("Error switching chain:", error);
				}
			}
		} else {
			console.error("MetaMask not found");
		}
	};
  useEffect(() => {
    disconnectAccount();
    // if(chain?.id != config.DCXCHAIN_ID){
    // switchNetwork?.(chains[0].id)
    // }

    // const getpriceapi = async () => {
    //    const ethprice = await  axios.get("https://api.etherscan.com/api?module=stats&action=ethprice&apikey=Q15HRHD2546QF1HIY155EPTAGPGPKANIJU")
    //    const ethpriceusd = ethprice.data.result.ethusd;
    //    const ethpriceusdfixed = parseFloat(ethpriceusd).toFixed(4);
    //    setethpriceusd(ethpriceusdfixed);

    //   }

    getpriceapi();
    lotteryTime();

    getRound();
    const Round = currentround - 1;
    if (currentround == 0) {
      setInputRound(inputRound);
    } else {
      setInputRound(Round);
    }
  }, [currentround]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      calculateRemainingTime();
    }, 1000);

    // Cleanup the interval when the component unmounts
    return () => clearInterval(intervalId);
  });

  useEffect(() => {
    getDcxBalance();
    getTicketAmount(currentround);
    ticketDiscount();
    getMatchWinningNumber();
    if (currentround > 1) {
      getWinningHistory();
    }
    nextDraw(currentround);
  }, [
    inputRound,
    currentround,
    dcxprice,
    dcxpriceusd,
    address,
    ticketCount,
    ticketCost,
  ]);

  useEffect(() => {
		// Scroll to the top of the page when component mounts
		window.scrollTo(0, 0);
	  }, []);

  // window.scrollTo(0,0);
  return (
    <div>
      <Navbar />
      <ToastContainer position="top-right" />
      <div
        className="lottery-section-top-banner py-5"
        style={{ backgroundImage: "url(" + ticketImage + ")" }}
      >
        <div class="container">
          <div class="row align-items-center g-4 justify-content-center text-center">
            <div className="col-lg-3 col-2">
              <img src={left} className="buyticket-image" alt="left" />
            </div>

            <div class="col-lg-5 col-8">
              <p className="lottery-banner-text-2">The D - Lottery</p>
              {closeBuyTicket == true ? (
                <p class="lottery-banner-text-2 mt-3">Tickets on sale soon</p>
              ) : (
                <>
                  <h1 class="lottery-banner-text-1">${dcxpriceusd}</h1>
                  <p class="lottery-banner-text-2 mt-3">in prizes!</p>
                </>
              )}
              {closeBuyTicket == true ? (
                <button
                  type="button"
                  class="top-buy-ticket-button"
                  style={{ outline: "none", cursor: "not-allowed" }}
                >
                  <img className="" src={onSaleSoon} alt="buy-crypto" />
                </button>
              ) : (
                <button
                  type="button"
                  class="top-buy-ticket-button"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  onClick={openModal}
                  style={{ outline: "none" }}
                >
                  <img className="" src={buyticket} alt="buy-crypto" />
                </button>
              )}
            </div>
            <div className="col-lg-3 col-2">
              {/* <img src={right} alt="left" /> */}
              <img src={right} className="buyticket-image" alt="left" />
            </div>
          </div>
        </div>
      </div>

      <div className="lottery-section-second-banner py-5">
        <div class="container py-5">
          <div class="row align-items-center g-4 justify-content-center text-center">
            <div class="col-lg-12">
              <p className="lottery-banner-text-3">Get your tickets now!</p>
              <p>
                {Hours > 0 ? (
                  <>
                    <span className="lottery-banner-text-4">{Hours}</span>
                    <span className="lottery-banner-text-5">h</span>
                  </>
                ) : (
                  <></>
                )}
                {Minutes > 0 ? (
                  <>
                    <span className="lottery-banner-text-4 ms-2">
                      {Minutes}
                    </span>
                    <span className="lottery-banner-text-5">m</span>{" "}
                  </>
                ) : (
                  <></>
                )}
                {Seconds >= 0 ? (
                  <>
                    <span className="lottery-banner-text-4 ms-2">
                      {Seconds}
                    </span>
                    <span className="lottery-banner-text-5">s</span>{" "}
                  </>
                ) : (
                  <></>
                )}
                {closeBuyTicket == true ? (
                  <>
                    {lotteryEndTime == "Lottery hasn't begun yet" ? (
                      <span className="lottery-banner-text-6">
                        {" "}
                        Lottery hasn't begun yet !
                      </span>
                    ) : (
                      <span className="lottery-banner-text-6">
                        {" "}
                        Wait for the next round of the lottery
                      </span>
                    )}
                  </>
                ) : (
                  <span className="lottery-banner-text-6">until the draw</span>
                )}
              </p>
              <div class="card buy-ticket-card">
                <div class="card-header border-0 py-3">
                  <div class="d-flex flex-lg-row flex-column text-start">
                    <div class="">
                      <span className="lottery-banner-text-7">Next Draw</span>
                    </div>
                    <div class="ms-lg-auto">
                      <span className="lottery-banner-text-8">
                        #{currentround} | Draw: {formattedDateDraw}
                      </span>
                    </div>
                  </div>
                </div>

                <div class="card-body">
                  <div class="d-flex flex-lg-row flex-column text-lg-start text-center">
                    <div class="lottery-second-width">
                      <span className="lottery-banner-text-77">Prize Pot</span>
                    </div>
                    <div class="">
                      <span className="lottery-banner-text-9">
                        ~${dcxpriceusd}
                      </span>
                      <p className="lottery-banner-text-10">{dcxprice} DCX</p>
                    </div>
                  </div>
                  <div class="d-flex flex-lg-row flex-column text-lg-start text-center">
                    <div class="lottery-second-width">
                      <span className="lottery-banner-text-7">
                        Your tickets
                      </span>
                    </div>
                    <div class="">
                      <span className="lottery-banner-text-11">
                        You have{" "}
                        <span className="lottery-banner-text-6">
                          {numberofTicket}
                        </span>{" "}
                        ticket this round
                      </span>
                      {numberofTicket == 0 ? (
                        <></>
                      ) : (
                        <p className="">
                          <button
                            class="lotery-more-details text-small"
                            type="button"
                            data-bs-toggle="modal"
                            data-bs-target="#Modalviewtickets"
                          >
                            View Your Tickets
                          </button>
                        </p>
                      )}
                    </div>
                    {closeBuyTicket == true ? (
                      <div class="">
                        <button
                          type="button"
                          class="buy-ticket-button ms-lg-4 onsale-fade"
                        >
                          On Sale Soon!
                        </button>
                      </div>
                    ) : (
                      <button
                        type="button"
                        class="buy-ticket-button ms-lg-4"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        onClick={openModal}
                      >
                        Buy Tickets
                      </button>
                    )}
                  </div>
                </div>
                <div class="card-footer text-muted pt-3 pb-2">
                  <p>
                    <button
                      class="lotery-more-details"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseExample"
                      aria-expanded="false"
                      aria-controls="collapseExample"
                    >
                      Details <FaChevronDown />
                    </button>
                  </p>
                  <div class="collapse" id="collapseExample">
                    <h5 className="lottery-banner-text-20 text-start">
                      Match the winning number in the same order to share
                      prizes. Current prizes up for grabs:
                    </h5>
                    <div class="row row-cols-2 row-cols-lg-4 row-cols-md-4 g-4 lottery-more-details">
                      <div class="col">
                        <div class="card">
                          <h5 class="lottery-banner-text-12">Match first 1</h5>
                          <h5 class="lottery-banner-text-13">
                            {matchonedcx} DCX
                          </h5>
                          <span class="lottery-banner-text-14">
                            ~${matchoneusd}
                          </span>
                        </div>
                      </div>
                      <div class="col">
                        <div class="card">
                          <h5 class="lottery-banner-text-12">Match first 2</h5>
                          <h5 class="lottery-banner-text-13">
                            {matchtwodcx} DCX
                          </h5>
                          <span class="lottery-banner-text-14">
                            ~${matchtwousd}
                          </span>
                        </div>
                      </div>
                      <div class="col">
                        <div class="card">
                          <h5 class="lottery-banner-text-12">Match first 3</h5>
                          <h5 class="lottery-banner-text-13">
                            {matchthreedcx} DCX
                          </h5>
                          <span class="lottery-banner-text-14">
                            ~${matchthreeusd}
                          </span>
                        </div>
                      </div>
                      <div class="col">
                        <div class="card">
                          <h5 class="lottery-banner-text-12">Match first 4</h5>
                          <h5 class="lottery-banner-text-13">
                            {matchfourdcx} DCX
                          </h5>
                          <span class="lottery-banner-text-14">
                            ~${matchfourusd}
                          </span>
                        </div>
                      </div>
                      <div class="col">
                        <div class="card">
                          <h5 class="lottery-banner-text-12">Match first 5</h5>
                          <h5 class="lottery-banner-text-13">
                            {matchfivedcx} DCX
                          </h5>
                          <span class="lottery-banner-text-14">
                            ~${matchfiveusd}
                          </span>
                        </div>
                      </div>
                      <div class="col">
                        <div class="card">
                          <h5 class="lottery-banner-text-12">Match all 6</h5>
                          <h5 class="lottery-banner-text-13">
                            {matchsixdcx} DCX
                          </h5>
                          <span class="lottery-banner-text-14">
                            ~${matchsixusd}
                          </span>
                        </div>
                      </div>
                      <div class="col">
                        <div class="card">
                          <h5
                            class="lottery-banner-text-12"
                            style={{ color: "#ed4b9e" }}
                          >
                            Burn
                          </h5>
                          <h5 class="lottery-banner-text-13">{burndcx} DCX</h5>
                          <span class="lottery-banner-text-14">
                            ~${burnusd}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="hero-section-second-banner lottery-section-third-banner py-5">
        <div class="container">
          <div class="row g-4 py-4">
            <div class="d-flex flex-row justify-content-center text-center align-items-center">
              <div class="">
                <img
                  className="layer-winner-1"
                  src={Layerright}
                  alt="lottery"
                />
                {/* <img className='layer-winner-1' src={Layeropen1} alt="lottery" /> */}
              </div>
              <div class="mx-3">
                {open == true ? (
                  <>
                    <h5 className="lottery-banner-text-15 text-dark">
                      Are you a winner?
                    </h5>
                    <button
                      type="button"
                      class="check-now-button py-2 ms-lg-4 mt-3"
                      data-bs-toggle="modal"
                      data-bs-target="#Winnercheckmodal"
                      // onClick={rewardsDetails}
                    >
                      Claim Now
                    </button>
                  </>
                ) : (
                  <>
                    {open == false && modalOpen == false ? (
                      <>
                        <h5 className="lottery-banner-text-15 text-dark">
                          Are you a winner?
                        </h5>
                        <button
                          type="button"
                          class="check-now-button py-2 ms-lg-4 mt-3"
                          onClick={checkNow}
                        >
                          Claim Now
                        </button>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                )}
                {open == false && modalOpen == true ? (
                  <>
                    {isConnected ? (
                      <h5 className="lottery-banner-text-15 mt-3">
                        Better luck next time!
                      </h5>
                    ) : (
                      <h5 className="lottery-banner-text-15 mt-3">
                        Connect your wallet to <br />
                        check if you've won!
                      </h5>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </div>
              <div class="">
                <img className="layer-winner-2" src={Layerleft} alt="lottery" />
                {/* <img className='layer-winner-2' src={Layeropen2} alt="lottery" /> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="lottery-section-fourth-banner py-5">
        <div class="container">
          <div class="row g-4 ">
            <h1 className="lottery-banner-text-16 text-center">
              Finished Rounds
            </h1>
            <div className="col">
              <ul
                class="nav justify-content-center nav-pills mb-3 lottery-fourth-tabs-section"
                id="pills-tab"
                role="tablist"
              >
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link active"
                    id="pills-allhistory-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-allhistory"
                    type="button"
                    role="tab"
                    aria-controls="pills-allhistory"
                    aria-selected="true"
                  >
                    All History
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  {isConnected ? (
                    <button
                      class="nav-link"
                      id="pills-profile-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-profile"
                      type="button"
                      role="tab"
                      aria-controls="pills-profile"
                      aria-selected="false"
                      onClick={getUserHistories}
                    >
                      Your History
                    </button>
                  ) : (
                    <button
                      class="nav-link"
                      id="pills-profile-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-profile"
                      type="button"
                      role="tab"
                      aria-controls="pills-profile"
                      aria-selected="false"
                    >
                      Your History
                    </button>
                  )}
                </li>
              </ul>
              <div class="tab-content" id="pills-tabContent">
                <div
                  class="tab-pane fade show active"
                  id="pills-allhistory"
                  role="tabpanel"
                  aria-labelledby="pills-allhistory-tab"
                >
                  <div class="card all-history-cards">
                    <div class="card-header border-0 py-3">
                      <div class="d-flex flex-row align-items-center">
                        <div class="d-flex align-items-center">
                          <span className="lottery-banner-text-17 me-3">
                            ROUND
                          </span>
                          <input
                            type="text"
                            value={inputRound}
                            onChange={(e) => {
                              // Prevent updating the state if the entered value is "0"
                              if (e.target.value !== "0") {
                                Round(e.target.value);
                              }
                            }}
                            class="form-control form-control-round"
                            onKeyPress={(event) => {
                              if (
                                event.key === "." &&
                                event.target.value.includes(".")
                              ) {
                                event.preventDefault(); // Prevent entering multiple decimal points
                              }

                              const keyCode = event.which || event.keyCode;
                              const keyValue = String.fromCharCode(keyCode);

                              // Allow only numeric values (0-9), the backspace key (8), and a single decimal point
                              if (!/^[0-9\b]$/.test(keyValue)) {
                                event.preventDefault();
                              }
                            }}
                          />
                        </div>
                        <div class="ms-auto d-flex">
                          {inputRound <= currentround - 1 && inputRound > 1 ? (
                            <span className="lottery-banner-icon-1  blue-arrow">
                              <BiLeftArrowAlt
                                onClick={() => LeftArrow(inputRound - 1)}
                              />
                            </span>
                          ) : (
                            <span className="lottery-banner-icon-1">
                              <BiLeftArrowAlt />
                            </span>
                          )}
                          {inputRound == currentround ||
                          inputRound == currentround - 1 ? (
                            <span className="lottery-banner-icon-1">
                              <BiRightArrowAlt />
                            </span>
                          ) : (
                            <span className="lottery-banner-icon-1 blue-arrow">
                              <BiRightArrowAlt
                                onClick={() => RightArrow(inputRound + 1)}
                              />
                            </span>
                          )}
                          {inputRound == currentround ||
                          inputRound == currentround - 1 ? (
                            <span className="lottery-banner-icon-1">
                              <BiArrowToRight />
                            </span>
                          ) : (
                            <span className="lottery-banner-icon-1  blue-arrow">
                              <BiArrowToRight
                                onClick={() => LastArrow(currentround - 1)}
                              />
                            </span>
                          )}
                        </div>
                      </div>
                      {inputRound == "" ? (
                        <p></p>
                      ) : (
                        <p className="lottery-banner-text-18 mb-0">
                          {/* Drawn Oct 3, 2022, 5:30 AM */}
                          {formattedDate}
                        </p>
                      )}
                    </div>
                    <div class="card-body py-5 position-relative overflow-hidden">
                      {inputRound == currentround - 1 && inputRound != 0 ? (
                        <div className="lott-ribbon-css">Latest</div>
                      ) : (
                        <></>
                      )}
                      <div class="d-flex flex-lg-row flex-column  align-items-center flex-wrap text-center">
                        {currentround == 0 || currentround == 1 ? (
                          <></>
                        ) : (
                          <div class="">
                            <span className="lottery-banner-text-19">
                              Winning Number
                            </span>
                          </div>
                        )}
                        {inputRound == "" ? (
                          <>
                            {currentround == 0 || currentround == 1 ? (
                              <div
                                className=" mx-lg-auto"
                                style={{ width: "50%" }}
                              >
                                <p class="">
                                  You will get History when the 1st round
                                  completes!{" "}
                                </p>
                              </div>
                            ) : (
                              <div
                                className=" ms-lg-auto"
                                style={{ width: "50%" }}
                              >
                                <p class="">Please specify Round</p>
                                <FaQuestionCircle className="ques-icon" />{" "}
                              </div>
                            )}
                          </>
                        ) : (
                          <div class="ms-lg-auto mx-auto">
                            <div class="d-flex flex-row">
                              <div class="winning-number-1">
                                {winningNumber[0]}
                              </div>
                              <div class="winning-number-2">
                                {winningNumber[1]}
                              </div>
                              <div class="winning-number-3">
                                {winningNumber[2]}
                              </div>
                              <div class="winning-number-4">
                                {winningNumber[3]}
                              </div>
                              <div class="winning-number-5">
                                {winningNumber[4]}
                              </div>
                              <div class="winning-number-6">
                                {winningNumber[5]}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div class="card-footer text-muted border-0 pt-3 pb-2 text-center">
                      <p>
                        <button
                          class="lotery-more-details"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapsewinning"
                          aria-expanded="false"
                          aria-controls="collapseExample"
                        >
                          Details <FaChevronDown />
                        </button>
                      </p>
                      {inputRound == "" ? (
                        <p></p>
                      ) : (
                        <div class="collapse" id="collapsewinning">
                          <div className="row">
                            <div className="col-lg-4 col-md-4 text-start">
                              <h1 className="lottery-banner-text-21">
                                Prize pot
                              </h1>
                              <span className="lottery-banner-text-9">
                                ~${winpriceusd}
                              </span>
                              <p className="lottery-banner-text-10">
                                {winninghistory &&
                                  winninghistory[0] &&
                                  winninghistory[0].pricePot}{" "}
                                DCX
                              </p>
                              <h5 className="lottery-banner-text-20 mt-5">
                                Total players this round:{" "}
                                {winninghistory &&
                                  winninghistory[0] &&
                                  winninghistory[0].totalPlayer}
                              </h5>
                            </div>
                            <div className="col-lg-8 col-md-8 col-sm-8">
                              <h5 className="lottery-banner-text-20 text-start">
                                Match the winning number in the same order to
                                share prizes. Current prizes up for grabs:
                              </h5>
                              <div class="row row-cols-2 row-cols-lg-4 row-cols-md-4 g-4 lottery-more-details">
                                <div class="col">
                                  <div class="card">
                                    <h5 class="lottery-banner-text-12">
                                      Match first 1
                                    </h5>
                                    <h5 class="lottery-banner-text-13">
                                      {matchonedcxwin} DCX
                                    </h5>
                                    <span class="lottery-banner-text-14">
                                      ~${matchoneusdwin}
                                    </span>
                                    <span class="lottery-banner-text-14">
                                      {firstDigitWin} Winning Tickets
                                    </span>
                                  </div>
                                </div>
                                <div class="col">
                                  <div class="card">
                                    <h5 class="lottery-banner-text-12">
                                      Match first 2
                                    </h5>
                                    <h5 class="lottery-banner-text-13">
                                      {matchtwodcxwin} DCX
                                    </h5>
                                    <span class="lottery-banner-text-14">
                                      ~${matchtwousdwin}
                                    </span>
                                    <span class="lottery-banner-text-14">
                                      {secondDigitWin} Winning Tickets
                                    </span>
                                  </div>
                                </div>
                                <div class="col">
                                  <div class="card">
                                    <h5 class="lottery-banner-text-12">
                                      Match first 3
                                    </h5>
                                    <h5 class="lottery-banner-text-13">
                                      {matchthreedcxwin} DCX
                                    </h5>
                                    <span class="lottery-banner-text-14">
                                      ~${matchthreeusdwin}
                                    </span>
                                    <span class="lottery-banner-text-14">
                                      {thirdDigitWin} Winning Tickets
                                    </span>
                                  </div>
                                </div>
                                <div class="col">
                                  <div class="card">
                                    <h5 class="lottery-banner-text-12">
                                      Match first 4
                                    </h5>
                                    <h5 class="lottery-banner-text-13">
                                      {matchfourdcxwin} DCX
                                    </h5>
                                    <span class="lottery-banner-text-14">
                                      ~${matchfourusdwin}
                                    </span>
                                    <span class="lottery-banner-text-14">
                                      {fourthDigitWin} Winning Tickets
                                    </span>
                                  </div>
                                </div>
                                <div class="col">
                                  <div class="card">
                                    <h5 class="lottery-banner-text-12">
                                      Match first 5
                                    </h5>
                                    <h5 class="lottery-banner-text-13">
                                      {matchfivedcxwin} DCX
                                    </h5>
                                    <span class="lottery-banner-text-14">
                                      ~${matchfiveusdwin}
                                    </span>
                                    <span class="lottery-banner-text-14">
                                      {fifthDigitWin} Winning Tickets
                                    </span>
                                  </div>
                                </div>
                                <div class="col">
                                  <div class="card">
                                    <h5 class="lottery-banner-text-12">
                                      Match all 6
                                    </h5>
                                    <h5 class="lottery-banner-text-13">
                                      {matchsixdcxwin} DCX
                                    </h5>
                                    <span class="lottery-banner-text-14">
                                      ~${matchsixusdwin}
                                    </span>
                                    <span class="lottery-banner-text-14">
                                      {sixDigitWin} Winning Tickets
                                    </span>
                                  </div>
                                </div>
                                <div class="col">
                                  <div class="card">
                                    <h5
                                      class="lottery-banner-text-12"
                                      style={{ color: "#ed4b9e" }}
                                    >
                                      Burn
                                    </h5>
                                    <h5 class="lottery-banner-text-13">
                                      {burndcxwin} DCX
                                    </h5>
                                    <span class="lottery-banner-text-14">
                                      ~${burnusdwin}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="pills-profile"
                  role="tabpanel"
                  aria-labelledby="pills-profile-tab"
                >
                  <div class="card all-history-cards">
                    <div class="card-header border-0 py-3">
                      <div class="d-flex flex-row align-items-center">
                        <div class="d-flex align-items-center">
                          {/* <span className="lottery-banner-text-17 me-3">
                            ROUND
                          </span> */}
                          {/* <input type="text" class="form-control form-control-round" /> */}
                        </div>
                        {/* <div class="ms-auto"><span className='lottery-banner-icon-1'><BiLeftArrowAlt /></span><span className='lottery-banner-icon-1'><BiRightArrowAlt /></span><span className='lottery-banner-icon-1'><BiArrowToRight /></span></div> */}
                      </div>
                      {/* <p className='lottery-banner-text-18'>Drawn Oct 3, 2022, 5:30 AM</p> */}
                    </div>
                    <div class="card-body pb-5">
                      {userHistory.length > 4 ? (
                        <div className="new-lott-history-table">
                          {userHistory.length > 0 ? (
                            <table class="table text-white table-borderless">
                              <thead>
                                <tr>
                                  <th scope="col" style={{ width: "20%" }}>
                                    ROUNDS
                                  </th>
                                  <th scope="col" style={{ width: "30%" }}>
                                    DATE
                                  </th>
                                  <th scope="col" style={{ width: "50%" }}>
                                    YOUR TICKETS
                                  </th>
                                  <th scope="col" style={{ width: "50%" }}></th>
                                </tr>
                              </thead>
                              {Array.isArray(userHistory) &&
                                userHistory.map((data, index) => (
                                  <tbody
                                    style={{ opacity: "0.9" }}
                                    className="fw-light"
                                    key={index}
                                  >
                                    <tr
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        setInputRound(data.roundNumber);
                                        getUserHistory(
                                          data.roundNumber,
                                          data._id
                                        );
                                      }}
                                      data-bs-toggle="modal"
                                      data-bs-target="#finishedroundhistroytable"
                                    >
                                      <td>{data.roundNumber}</td>
                                      <td>
                                        {formatHistorydate(data.createdDate)}
                                      </td>
                                      <td>{data.ticketNumber.length}</td>
                                      <td>
                                        <IoIosArrowForward />
                                      </td>
                                    </tr>
                                  </tbody>
                                ))}
                            </table>
                          ) : (
                            <div class="d-flex flex-row align-items-center">
                              <div class="mx-auto text-center">
                                <span className="lottery-banner-text-19">
                                  No lottery history found
                                </span>
                                <br />
                                <span className="lottery-banner-text-20">
                                  Buy tickets for the next round!
                                </span>
                                <br />
                                {closeBuyTicket == true ? (
                                  <button
                                    type="button"
                                    class="buy-ticket-button mt-3 onsale-fade"
                                  >
                                    On Sale Soon!
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    class="buy-ticket-button mt-3"
                                    data-bs-toggle="modal"
                                    data-bs-target="#exampleModal"
                                    onClick={openModal}
                                  >
                                    Buy Tickets
                                  </button>
                                )}
                              </div>
                              {/* <div class="ms-auto">
                            <div class="d-flex flex-row">
                              <div class="winning-number-1">9</div>
                              <div class="winning-number-2">2</div>
                              <div class="winning-number-3">5</div>
                              <div class="winning-number-4">6</div>
                              <div class="winning-number-5">4</div>
                              <div class="winning-number-6">1</div>
                            </div>
                          </div> */}
                            </div>
                          )}
                        </div>
                      ) : (
                        <div className="new-lottsmall-history-table">
                          {userHistory.length > 0 ? (
                            <table class="table text-white table-borderless">
                              <thead>
                                <tr>
                                  <th scope="col" style={{ width: "20%" }}>
                                    ROUNDS
                                  </th>
                                  <th scope="col" style={{ width: "30%" }}>
                                    DATE
                                  </th>
                                  <th scope="col" style={{ width: "50%" }}>
                                    YOUR TICKETS
                                  </th>
                                  <th scope="col" style={{ width: "50%" }}></th>
                                </tr>
                              </thead>
                              {Array.isArray(userHistory) &&
                                userHistory.map((data, index) => (
                                  <tbody
                                    style={{ opacity: "0.9" }}
                                    className="fw-light"
                                    key={index}
                                  >
                                    <tr
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        setInputRound(data.roundNumber);
                                        getUserHistory(
                                          data.roundNumber,
                                          data._id
                                        );
                                      }}
                                      data-bs-toggle="modal"
                                      data-bs-target="#finishedroundhistroytable"
                                    >
                                      <td>{data.roundNumber}</td>
                                      <td>
                                        {formatHistorydate(data.createdDate)}
                                      </td>
                                      <td>{data.ticketNumber.length}</td>
                                      <td>
                                        <IoIosArrowForward />
                                      </td>
                                    </tr>
                                  </tbody>
                                ))}
                            </table>
                          ) : (
                            <div class="d-flex flex-row align-items-center">
                              <div class="mx-auto text-center">
                                <span className="lottery-banner-text-19">
                                  No lottery history found
                                </span>
                                <br />
                                <span className="lottery-banner-text-20">
                                  Buy tickets for the next round!
                                </span>
                                <br />
                                {closeBuyTicket == true ? (
                                  <button
                                    type="button"
                                    class="buy-ticket-button mt-3 onsale-fade"
                                  >
                                    On Sale Soon!
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    class="buy-ticket-button mt-3"
                                    data-bs-toggle="modal"
                                    data-bs-target="#exampleModal"
                                    onClick={openModal}
                                  >
                                    Buy Tickets
                                  </button>
                                )}
                              </div>
                              {/* <div class="ms-auto">
                            <div class="d-flex flex-row">
                              <div class="winning-number-1">9</div>
                              <div class="winning-number-2">2</div>
                              <div class="winning-number-3">5</div>
                              <div class="winning-number-4">6</div>
                              <div class="winning-number-5">4</div>
                              <div class="winning-number-6">1</div>
                            </div>
                          </div> */}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    <div class="card-footer text-muted border-0 pt-3 pb-2 text-center">
                      <p>
                        <button
                          class="lotery-more-details"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapsewinninghistory"
                          aria-expanded="false"
                          aria-controls="collapseExample"
                        >
                          {/* Only showing data for Lottery V2 */}
                        </button>
                      </p>
                      {/* <div class="collapse" id="collapsewinninghistory">
                          <div className='row'>
                            <div className='col-4 text-start'>
                              <h1 className='lottery-banner-text-21'>Prize pot</h1>
                              <span className='lottery-banner-text-9'>~$110,508</span>
                              <p className='lottery-banner-text-10'>23,779 CAKE</p>
                              <h5 className='lottery-banner-text-20 mt-5'>Total players this round: 400</h5>

                            </div>
                            <div className='col-8'>
                              <h5 className='lottery-banner-text-20 text-start'>Match the winning number in the same order to share prizes. Current prizes up for grabs:</h5>
                              <div class="row row-cols-2 row-cols-lg-4 g-4 lottery-more-details">
                                <div class="col">
                                  <div class="card">
                                    <h5 class="lottery-banner-text-12">Match first 1</h5>
                                    <h5 class="lottery-banner-text-13">477 Dcoin</h5>
                                    <span class="lottery-banner-text-14">~$2,220</span>
                                  </div>
                                </div>
                                <div class="col">
                                  <div class="card">
                                    <h5 class="lottery-banner-text-12">Match first 2</h5>
                                    <h5 class="lottery-banner-text-13">477 Dcoin</h5>
                                    <span class="lottery-banner-text-14">~$2,220</span>
                                  </div>
                                </div>
                                <div class="col">
                                  <div class="card">
                                    <h5 class="lottery-banner-text-12">Match first 3</h5>
                                    <h5 class="lottery-banner-text-13">477 Dcoin</h5>
                                    <span class="lottery-banner-text-14">~$2,220</span>
                                  </div>
                                </div>
                                <div class="col">
                                  <div class="card">
                                    <h5 class="lottery-banner-text-12">Match first 4</h5>
                                    <h5 class="lottery-banner-text-13">477 Dcoin</h5>
                                    <span class="lottery-banner-text-14">~$2,220</span>
                                  </div>
                                </div>
                                <div class="col">
                                  <div class="card">
                                    <h5 class="lottery-banner-text-12">Match first 5</h5>
                                    <h5 class="lottery-banner-text-13">477 Dcoin</h5>
                                    <span class="lottery-banner-text-14">~$2,220</span>
                                  </div>
                                </div>
                                <div class="col">
                                  <div class="card">
                                    <h5 class="lottery-banner-text-12">Match all 6</h5>
                                    <h5 class="lottery-banner-text-13">477 Dcoin</h5>
                                    <span class="lottery-banner-text-14">~$2,220</span>
                                  </div>
                                </div>
                                <div class="col">
                                  <div class="card">
                                    <h5 class="lottery-banner-text-12" style={{ color: "#ed4b9e" }}>Burn</h5>
                                    <h5 class="lottery-banner-text-13">477 Dcoin</h5>
                                    <span class="lottery-banner-text-14">~$2,220</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>


                        </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="lottery-section-fifth-banner pt-5">
        <div class="container lottery-section-border-bottom pb-5">
          <h1 className="lottery-banner-text-3 text-center lottery-play-tx">
            How to Play
          </h1>
          <p
            className="lottery-banner-text-22 text-center mb-5 mx-auto"
            style={{ width: "86%" }}
          >
            You win a portion of the prize pool. If the numbers on your tickets
            coincide with the winning numbers in the right sequence. Easy!
          </p>
          <div class="row row-cols-1 row-cols-lg-3 g-4">
            <div class="col">
              <div class="card h-100">
                <div class="card-body">
                  <p className="ms-auto lottery-banner-text-24">STEP 1</p>
                  <h5 class="lottery-banner-text-25">Buy Tickets</h5>
                  <p class="lottery-banner-text-26">
                    At the beginning of the round, prices are fixed in the top
                  </p>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card h-100">
                <div class="card-body">
                  <p className="ms-auto lottery-banner-text-24">STEP 2</p>
                  <h5 class="lottery-banner-text-25">Wait for the Draw</h5>
                  <p class="lottery-banner-text-26">
                    There is a single draw that takes place at 24 hrs UTC.
                  </p>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card h-100">
                <div class="card-body">
                  <p className="ms-auto lottery-banner-text-24">STEP 3</p>
                  <h5 class="lottery-banner-text-25">Check for Prizes</h5>
                  <p class="lottery-banner-text-26">
                    Return to the website when the round is over to see if you
                    won!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="lottery-section-fifth-banner pt-5">
        <div class="container lottery-section-border-bottom pb-5">
          <div class="row align-items-center flex-lg-row-reverse g-4">
            <div class="col-lg-5">
              <img src={explain} alt="lottery" />
            </div>
            <div class="col-lg-7">
              <h5 class="lottery-banner-text-25">Winning Criteria</h5>
              <p className="lottery-banner-text-27 mb-4">
                To win, the numbers on your ticket have to line up exactly.
              </p>
              <p className="lottery-banner-text-28 mb-3">
                Here’s an example lottery draw, with two tickets, A and B.
              </p>
              <ul class="b">
                <li>
                  <p className="lottery-banner-text-28">
                    Ticket A: This ticket only receives a "Match first 3" award
                    because the last two and the first three digits match, but
                    the fourth digit is incorrect.
                  </p>
                </li>
                <li>
                  <p className="lottery-banner-text-28">
                    Ticket B: This ticket does not win a prize since the first
                    digit is incorrect, even though the last five numbers match.
                  </p>
                </li>
              </ul>
              <p className="lottery-banner-text-28 mt-3">
                There is no "stacking" of reward brackets; if you match the
                first three digits in sequence, you will only win from the
                "Match 3" bracket and not from the "Match 1" or "Match 2"
                brackets.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="lottery-section-fifth-banner pt-5">
        <div class="container lottery-section-border-bottom pb-5">
          <div class="row align-items-center g-4 flex-lg-row-reverse">
            <div class="col-lg-5">
              <img src={explain2} alt="lottery" />
            </div>
            <div class="col-lg-7">
              <h5 class="lottery-banner-text-25">Prize Funds</h5>
              <p className="lottery-banner-text-28 mb-3">
                There are three sources of prizes for every lottery round:
              </p>
              <p className="lottery-banner-text-27 mb-3">Ticket Purchased</p>
              <ul class="b">
                <li>
                  <p className="lottery-banner-text-28">
                    The prize pools receive 100% of the DCX that ticket buyers
                    for that round pay.
                  </p>
                </li>
              </ul>
              <p className="lottery-banner-text-27 mb-3">Rollover Prizes</p>
              <ul class="b">
                <li>
                  <p className="lottery-banner-text-28">
                    Every round, the unclaimed DCX for a certain prize bracket
                    rolls over into the following round and is allocated among
                    the prize pools if no one wins in that bracket.
                  </p>
                </li>
              </ul>
              {/* <p className="lottery-banner-text-27 mb-3">DCX Injections</p> */}
              {/* <ul class="b">
                <li>
                  <p className="lottery-banner-text-28">
                    An average total of 35,000 DCX from the treasury is added to
                    lottery rounds over the course of a week. This DCX is of
                    course also included in rollovers! Read more in our guide to{" "}
                    <span className="lottery-banner-text-25">
                      DCX Tokenomics
                    </span>
                  </p>
                </li>
              </ul> */}
            </div>
          </div>
        </div>
      </div>

      <div className="lottery-section-fifth-banner py-5">
        <div class="container">
          <div class="row align-items-center g-4">
            <div class="row justify-content-center align-items-center text-lg-start text-center">
              <div class="col-lg-6 lottery-animation-image">
                <img className="" src={Group2} alt="lottery" />
              </div>
              <div class="col-lg-6">
                <p className="lottery-banner-text-27 mb-3 mt-lg-0 mt-3">
                  Still got questions?
                </p>
                <p className="lottery-banner-text-22">
                  Check our in-depth guide on{" "}
                  <span style={{ color: "#20b3e0" }}>
                  <a href = {pdf} target = "_blank">  How to Play <br /> the lottery!</a>
                  </span>
                  
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade buy-ticket-modal"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                BUY YOUR LOTTERY TICKETS
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleClose}
              ></button>
            </div>
            <div class="modal-body">
              {lotteryEndTime == "lottery is running" ? (
                <></>
              ) : (
                <span
                  className="buy-ticket-modal-text-1 d-flex justify-content-center"
                  style={{ color: "red" }}
                >
                  {lotteryEndTime}
                </span>
              )}
              <div class="d-flex flex-row">
                <div class="">
                  <span className="buy-ticket-modal-text-1">Buy:</span>
                </div>
                <div class="ms-auto">
                  <span className="buy-ticket-modal-text-2">
                    Tickets
                    <FaTicketAlt className="ms-2" />
                  </span>
                </div>
              </div>

              <div class="d-flex flex-column buy-ticket-input-section">
                <div class="">
                  <input
                    className="form-control"
                    type="text"
                    id="from_amount"
                    value={ticketCount}
                    placeholder="0"
                    onChange={(e) => enterlotteryCount(e)}
                    onKeyPress={(event) => {
                      const keyCode = event.which || event.keyCode;
                      const keyValue = String.fromCharCode(keyCode);

                      // Allow only numeric values (0-9) and the backspace key (8)
                      if (!/^[0-9\b]+$/.test(keyValue)) {
                        event.preventDefault();
                      }
                    }}
                  ></input>
                </div>
                <div class="ms-auto">
                  <span className="buy-ticket-modal-text-3">
                    ~{ticketAmount} DCX
                  </span>
                </div>
              </div>
              {isConnected ? (
                <div class="d-flex flex-column">
                  {ticketAmount > balance ? (
                    <div class="ms-auto">
                      <span className="buy-ticket-modal-text-4">
                        Insufficient DCX balance
                      </span>
                    </div>
                  ) : (
                    <></>
                  )}
                  <div class="ms-auto">
                    <span className="buy-ticket-modal-text-3">
                      DCX Balance:{balance}
                    </span>
                  </div>
                </div>
              ) : (
                <></>
              )}
              <div class="d-flex flex-row bd-highlight mt-3 lottery-section-border-bottom pb-2">
                <div class="">
                  <p className="buy-ticket-modal-text-5">Cost (DCX)</p>
                  <p className="buy-ticket-modal-text-5">
                    <span className="buy-ticket-modal-text-2">
                      {discountPercentage}%
                    </span>{" "}
                    Bulk discount
                  </p>
                </div>
                <div class="ms-auto text-end">
                  <p className="buy-ticket-modal-text-5">{ticketCost} DCX</p>
                  <p className="buy-ticket-modal-text-5">~ {discount} DCX</p>
                </div>
              </div>
              <div class="d-flex flex-row bd-highlight mt-3 pb-2">
                <div class="">
                  <p className="buy-ticket-modal-text-5">You pay</p>
                </div>
                <div class="ms-auto text-end">
                  <span className="buy-ticket-modal-text-2">~ {total} DCX</span>
                </div>
              </div>
              <div class="d-grid gap-2 mt-4 mb-3">
                {ticketCount == 0 || closeBuyTicket == true ? (
                  <button
                    class="btn check-now-button btn-disable"
                    type="button"
                  >
                    Buy Ticket
                  </button>
                ) : (
                  <>
                    {Loading ? (
                      <button class="btn check-now-button" type="button">
                        <Spinner animation="border" />
                      </button>
                    ) : (
                      <button
                        class="btn check-now-button"
                        type="button"
                        onClick={mintlottery}
                      >
                        Buy Ticket
                      </button>
                    )}
                  </>
                )}
              </div>
              <div class="d-flex flex-row mb-3">
                <span className="buy-ticket-modal-text-6 ">
                  "Buy Instantly" chooses random numbers, with no duplicates
                  among your tickets. Prices are set before each round starts,
                  equal to $5 at that time. Purchases are final.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade buy-ticket-modal"
        id="Modalviewtickets"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Round {currentround}
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="d-flex flex-row">
                <div class="">
                  <span className="buy-ticket-modal-text-2">
                    YOUR TICKETS
                    <FaTicketAlt className="ms-2" />
                  </span>
                </div>
              </div>
              {/* <div class="ms-auto">
                <span className="buy-ticket-modal-text-1">#1</span>
              </div> */}

              <div class="d-flex flex-row justify-content-evenly buy-ticket-input-section mt-3">
                {/* {multiTicketRandNum.map((name, index) => ( */}
                <div className="row row-cols-1 row-cols-lg-1 new-view-tickets-modal-height">
                  {ticketElements}
                </div>
                {/* ))} */}
              </div>
              {closeBuyTicket == true ? (
                <div class="d-grid gap-2 mt-5 mb-3">
                  <button class="btn check-now-button onsale-fade">
                    On Sale Soon!
                  </button>
                </div>
              ) : (
                <div class="d-grid gap-2 mt-5 mb-3">
                  <button
                    class="btn check-now-button"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                    onClick={openModal}
                  >
                    Buy Tickets
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade buy-ticket-modal"
        id="Winnercheckmodal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Collect Winnings
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="d-flex flex-row">
                <div class="">
                  <span className="buy-ticket-modal-text-2">
                    You Won
                    <FaTicketAlt className="ms-2" />
                  </span>
                </div>
              </div>

              <p
                class="lottery-banner-text-9 mb-0"
                style={{ fontSize: "3rem" }}
              >
                ${usdRewards}
              </p>
              <span className="buy-ticket-modal-text-2">
                ~ {dcxRewards} DCX
                <FaTicketAlt className="ms-2" />
              </span>

              <div class="">
                {/* <span className="buy-ticket-modal-text-1">
                  Round #{currentround - 1}
                </span> */}
              </div>

              <div class="d-grid gap-2 mt-5 mb-3">
                <button class="btn check-now-button" onClick={claimRewards}>
                  {claimLoading ? <Spinner animation="border" /> : "Claim"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade buy-ticket-modal"
        id="Winnercheckmodal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Collect Winnings
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="d-flex flex-row">
                <div class="">
                  <span className="buy-ticket-modal-text-2">
                    You Won
                    <FaTicketAlt className="ms-2" />
                  </span>
                </div>
              </div>

              <p
                class="lottery-banner-text-9 mb-0"
                style={{ fontSize: "3rem" }}
              >
                $0
              </p>
              <span className="buy-ticket-modal-text-2">
                ~ 23321.232 USD
                <FaTicketAlt className="ms-2" />
              </span>

              <div class="">
                <span className="buy-ticket-modal-text-1">Round #8</span>
              </div>

              <div class="d-grid gap-2 mt-5 mb-3">
                <button class="btn check-now-button">Claim</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade buy-ticket-modal"
        id="finishedroundhistroytable"
        tabindex="-1"
        aria-labelledby="finishedroundhistroytable"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <div className="">
                <h5 class="lottery-banner-text-25 mb-0">
                  Round{" "}
                  {winninghistory &&
                    winninghistory[0] &&
                    winninghistory[0].roundNumber}
                </h5>
                <p className="buy-ticket-modal-text-1 mb-0">{formattedDate}</p>
              </div>

              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="d-flex flex-row">
                <div class="">
                  <span className="buy-ticket-modal-text-2">
                    Winning Number
                  </span>
                </div>
              </div>

              <div class="ms-auto mt-2">
                <div class="d-flex flex-row">
                  <div
                    class="winning-number-1 p-0 d-flex align-items-center justify-content-center"
                    style={{ height: "50px", width: "50px", fontSize: "22px" }}
                  >
                    {winningNumberInHistory[0]}
                  </div>
                  <div
                    class="winning-number-2 p-0 d-flex align-items-center justify-content-center"
                    style={{ height: "50px", width: "50px", fontSize: "22px" }}
                  >
                    {winningNumberInHistory[1]}
                  </div>
                  <div
                    class="winning-number-3 p-0 d-flex align-items-center justify-content-center"
                    style={{ height: "50px", width: "50px", fontSize: "22px" }}
                  >
                    {winningNumberInHistory[2]}
                  </div>
                  <div
                    class="winning-number-4 p-0 d-flex align-items-center justify-content-center"
                    style={{ height: "50px", width: "50px", fontSize: "22px" }}
                  >
                    {winningNumberInHistory[3]}
                  </div>
                  <div
                    class="winning-number-5 p-0 d-flex align-items-center justify-content-center"
                    style={{ height: "50px", width: "50px", fontSize: "22px" }}
                  >
                    {winningNumberInHistory[4]}
                  </div>
                  <div
                    class="winning-number-6 p-0 d-flex align-items-center justify-content-center"
                    style={{ height: "50px", width: "50px", fontSize: "22px" }}
                  >
                    {winningNumberInHistory[5]}
                  </div>
                </div>
              </div>

              <div class="d-flex flex-row align-items-center my-4">
                <div class="mx-auto text-center">
                  <span className="lottery-banner-text-19">
                    You had {userTicketNumber.length} ticket this round
                  </span>
                  <br />
                  <button
                    data-bs-toggle="modal"
                    data-bs-target="#finishedroundhistroytickets"
                    style={{ color: "#20b3e0" }}
                    className="lotery-more-details"
                  >
                    View your tickets
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade buy-ticket-modal"
        id="finishedroundhistroytickets"
        tabindex="-1"
        aria-labelledby="finishedroundhistroytickets"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <div className="">
                <h5 class="lottery-banner-text-25 mb-0">
                  Round{" "}
                  {winninghistory &&
                    winninghistory[0] &&
                    winninghistory[0].roundNumber}
                </h5>
                <p className="buy-ticket-modal-text-1 mb-0">{formattedDate}</p>
              </div>

              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="d-flex flex-row">
                <div class="">
                  <span className="buy-ticket-modal-text-2">
                    WINNING NUMBER
                  </span>
                </div>
              </div>

              <div class="ms-auto mt-2">
                <div class="d-flex flex-row">
                  <div
                    class="winning-number-1 p-0 d-flex align-items-center justify-content-center"
                    style={{ height: "50px", width: "50px", fontSize: "22px" }}
                  >
                    {winningNumberInHistory[0]}
                  </div>
                  <div
                    class="winning-number-2 p-0 d-flex align-items-center justify-content-center"
                    style={{ height: "50px", width: "50px", fontSize: "22px" }}
                  >
                    {winningNumberInHistory[1]}
                  </div>
                  <div
                    class="winning-number-3 p-0 d-flex align-items-center justify-content-center"
                    style={{ height: "50px", width: "50px", fontSize: "22px" }}
                  >
                    {winningNumberInHistory[2]}
                  </div>
                  <div
                    class="winning-number-4 p-0 d-flex align-items-center justify-content-center"
                    style={{ height: "50px", width: "50px", fontSize: "22px" }}
                  >
                    {winningNumberInHistory[3]}
                  </div>
                  <div
                    class="winning-number-5 p-0 d-flex align-items-center justify-content-center"
                    style={{ height: "50px", width: "50px", fontSize: "22px" }}
                  >
                    {winningNumberInHistory[4]}
                  </div>
                  <div
                    class="winning-number-6 p-0 d-flex align-items-center justify-content-center"
                    style={{ height: "50px", width: "50px", fontSize: "22px" }}
                  >
                    {winningNumberInHistory[5]}
                  </div>
                </div>
              </div>

              <p className="buy-ticket-modal-text-1 mt-4 mb-1">Your Tickets</p>
              <div className="d-flex flex-row align-items-center w-75">
                <FaTicketAlt className="me-2" />
                <p className="buy-ticket-modal-text-2 mb-0">Total Tickets:</p>
                <p className="buy-ticket-modal-text-2 mb-0 ms-auto">
                  {userTicketNumber.length}
                </p>
                <p
                  className="buy-ticket-modal-text-2 mb-0 ms-lg-5 ms-3"
                  style={{ visibility: "hidden" }}
                >
                  <CiCircleInfo
                    style={{ fontSize: "1.4rem", top: "-3px" }}
                    className="position-relative"
                  />
                </p>
              </div>
              <div className="d-flex flex-row align-items-center w-75">
                <TfiGift className="me-2" />
                <p className="buy-ticket-modal-text-2 mb-0">Winning Tickets:</p>
                <p className="buy-ticket-modal-text-2 mb-0 ms-auto">
                  {winningTicketCount}
                </p>
                <div className=" ms-lg-5 ms-3">
                  {winningTicketCount > 0 ? (
                    <span className="view-history-tooltip ">
                      <CiCircleInfo
                        style={{ fontSize: "1.4rem", top: "-3px" }}
                        className="position-relative"
                      />
                    </span>
                  ) : (
                    <span className="view-history-tooltip ">
                      <CiCircleInfo
                        style={{
                          fontSize: "1.4rem",
                          top: "-3px",
                          visibility: "hidden",
                        }}
                        className="position-relative"
                      />
                    </span>
                  )}
                  {winningTicketCount > 0 ? (
                    <Tooltip
                      anchorSelect=".view-history-tooltip"
                      place="top"
                      style={{
                        backgroundColor: "#FFF",
                        color: "#222",
                        opacity: "1",
                      }}
                    >
                      <div className="view-history-tooltip">
                        <table class="table">
                          <tbody>
                            <tr>
                              <td>Matched</td>
                              <td>First</td>
                              <td>1</td>
                              <td>:</td>
                              <td>{matchFirst1}</td>
                            </tr>
                            <tr>
                              <td>Matched</td>
                              <td>First</td>
                              <td>2</td>
                              <td>:</td>
                              <td>{matchFirst2}</td>
                            </tr>
                            <tr>
                              <td>Matched</td>
                              <td>First</td>
                              <td>3</td>
                              <td>:</td>
                              <td>{matchFirst3}</td>
                            </tr>
                            <tr>
                              <td>Matched</td>
                              <td>First</td>
                              <td>4</td>
                              <td>:</td>
                              <td>{matchFirst4}</td>
                            </tr>
                            <tr>
                              <td>Matched</td>
                              <td>First</td>
                              <td>5</td>
                              <td>:</td>
                              <td>{matchFirst5}</td>
                            </tr>
                          </tbody>
                          <tfoot>
                            <tr>
                              <th>Matched</th>
                              <th>All</th>
                              <th></th>
                              <th>:</th>
                              <th>{matchFirst6}</th>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </Tooltip>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div
                className="px-2"
                style={{ maxHeight: "280px", overflowY: "scroll" }}
              >
                {Array.isArray(userTicketNumber) &&
                  userTicketNumber.map((data, index) => {
                    const ticket = Array.from(String(data), Number);
                    const matchedDigit =
                      winningTicketDigit[index] || "No Match";
                    return (
                      <div>
                        <div className="d-flex">
                          <span>#{index + 1}</span>
                          {matchedDigit == "No Match" ? (
                            <></>
                          ) : (
                            <span className="ms-auto">{matchedDigit}</span>
                          )}
                        </div>
                        <div class="d-flex flex-row justify-content-evenly buy-ticket-input-section my-3 gap-3 text-center">
                          {ticket.map((digit, i) => (
                            <div
                              key={i}
                              className={`col buy-ticket-input-section ${
                                (matchedDigit === `Matched first 1` &&
                                  i <= 0) ||
                                (matchedDigit === "Matched first 2" &&
                                  i <= 1) ||
                                (matchedDigit === "Matched first 3" &&
                                  i <= 2) ||
                                (matchedDigit === "Matched first 4" &&
                                  i <= 3) ||
                                (matchedDigit === "Matched first 5" &&
                                  i <= 4) ||
                                (matchedDigit === "Matched first 6" && i <= 5)
                                  ? "ticketNumber-bg"
                                  : ""
                              }`}
                            >
                              <h4 className="mb-0">{digit}</h4>
                            </div>
                          ))}

                          {/* <div className="col buy-ticket-input-section ">
                            <h4 className="mb-0">{ticket[0]}</h4>
                          </div>
                          <div className="col buy-ticket-input-section ">
                            <h4 className="mb-0">{ticket[1]}</h4>
                          </div>
                          <div className="col buy-ticket-input-section ">
                            <h4 className="mb-0">{ticket[2]}</h4>
                          </div>
                          <div className="col buy-ticket-input-section ">
                            <h4 className="mb-0">{ticket[3]}</h4>
                          </div>
                          <div className="col buy-ticket-input-section ">
                            <h4 className="mb-0">{ticket[4]}</h4>
                          </div>
                          <div className="col buy-ticket-input-section ">
                            <h4 className="mb-0">{ticket[5]}</h4>
                          </div> */}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Lottery;
