import Logo from "../../Assests/images/d-dao-logo.png";
import MetaMask from "../../Assests/images/logo/MetaMask.png";
import Ledger from "../../Assests/images/logo/ledger.png";
import WalletConnect from "../../Assests/images/logo/WalletConnect.png";
import Coinbase from "../../Assests/images/logo/Coinbase.png";
import TrustWallet from "../../Assests/images/logo/TrustWallet.png";
import {
  Connector,
  useAccount,
  useConnect,
  useDisconnect,
  useEnsAvatar,
  useEnsName,
  useNetwork,
  useSwitchNetwork,
} from "wagmi";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import config from "../../config/config";
import { useState, useEffect } from "react";
import $ from "jquery"

function App(props) {
  const { address, isConnected } = useAccount();
  const { data: ensName } = useEnsName({ address });
  const { data: ensAvatar } = useEnsAvatar({ name: ensName });
  const [isReferral, setIsReferral] = useState();
  const [userAddress, setUserAddress] = useState("")
  // const { ledger } = LedgerConnectors()
  const { connect, connectors, } = useConnect({
    onError(error, variables, context) {
      if (variables.connector.id === 'metaMask') {
        if(!window.ethereum){
          openInNewTab(`https://metamask.io/download/`);
        }
      } else if (variables.connector.id === 'walletConnect') {
        // toast.error("User rejected the walletconnect request");
      } else if (variables.connector.id === 'coinbaseWallet') {
        // toast.error("User rejected the coinbasewallet request");
      } else if (variables.connector.id === "injected") {
        if(!window.ethereum){
          openInNewTabTrust(`https://trustwallet.com/`);
        }
      } else {
        toast.error("Something went wrong!");
      }
    }
  });

  useEffect(() => {
    console.log("location---->", window.location.pathname.substring(10));
    if (window.location.pathname.includes("referral")) {
      $(".navbar").addClass("fixed-top");
      console.log("true");
      setIsReferral(true);
    } else {
      console.log("false");
      setIsReferral(false);
    }
  }, [])

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank");
    if (newWindow) {
      newWindow.focus();
    }
  };

  const openInNewTabTrust = (url) => {
    const newWindow = window.open(url, "_blank");
    if (newWindow) {
      newWindow.focus();
    }
  };

  const { disconnect } = useDisconnect();
  const { chains, pendingChainId, switchNetwork } = useSwitchNetwork();
  const connectmetamask = async() => {
    try {
      console.log(chains,'chains')
      await  switchChain(5272)
    
      connect({ connector: connectors[0] });
      setInterval(async() => {
        let userMetaAddress = localStorage.getItem("wagmi.store");
        if (userMetaAddress) {
          let userMeta = JSON.parse(userMetaAddress);
          let addr = userMeta.state.data.account;
          if (addr) {
            // console.log("metamask connected");
        
          
            switchNetwork?.(chains[0].id);
            // window.location.reload();
            if(isReferral == true) {
              window.location.href = "/dashboard";
            }
          }
        }
      }, 1000);
    } catch (error) {
      console.log("err", error);
      if (error.message === 'User rejected the request') {
        console.log('User rejected the request.');
        // You can choose to show a user-friendly message or perform other actions.
      } else {
        console.error('An unexpected error occurred:', error);
        // Handle other types of errors as needed.
      }
    }
  };
  const switchChain = async (targetChainId) => {
    if (window.ethereum) {
      try {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: targetChainId }],
        });
        // console.log('Switched to chain with ID:', targetChainId);
      } catch (error) {
        if (error.code === 4902) {
          //   console.log('User rejected the chain switch');
        } else {
          console.error("Error switching chain:", error);
        }
      }
    } else {
      console.error("MetaMask not found");
    }
  };
  const connecttrustwallet = () => {
    try {
      connect({ connector: connectors[3] })
      setInterval(() => {
        let userMetaAddress = localStorage.getItem("wagmi.store");
        if (userMetaAddress) {
          let userMeta = JSON.parse(userMetaAddress);
          let addr = userMeta.state.data.account;
          if (addr) {
            // console.log("trustwallet connected");
            switchNetwork?.(chains[0].id);
          }
        }
      }, 1000);
    } catch (error) {
      console.log("err", error);
      if (error.message === 'User rejected the request') {
        console.log('User rejected the request.');
        // You can choose to show a user-friendly message or perform other actions.
      } else {
        console.error('An unexpected error occurred:', error);
        // Handle other types of errors as needed.
      }
    }
  }

  const connectcoinbasewallet = () => {
    try {
      connect({ connector: connectors[2] });
      setInterval(() => {
        let userMetaAddress = localStorage.getItem("wagmi.store");
        if (userMetaAddress) {
          let userMeta = JSON.parse(userMetaAddress);
          let addr = userMeta.state.data.account;
          if (addr) {
            // console.log("coinbasewallet connected", addr);
            switchNetwork?.(chains[0].id);
          }
        }
      }, 1000);
    } catch (error) {
      console.log("err", error);
      if (error.message === 'User rejected the request') {
        console.log('User rejected the request.');
        // You can choose to show a user-friendly message or perform other actions.
      } else {
        console.error('An unexpected error occurred:', error);
        // Handle other types of errors as needed.
      }
    }
  };

  const disconnectWallet = () => {
    disconnect();
    window.location.reload();
  }

   //mobile

   function isMobileDevice() {
    let check = false;
    (function (a) {
      if (
        "ontouchstart" in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0 ||
        "onmsgesturechange" in window ||
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);

    return check;
  }

  const handleMobileConnectClick = async () => {

    try {
      if (typeof window.ethereum !== 'undefined' && window.ethereum.isMetaMask) {
        const accounts = await window.ethereum.request({ method: "eth_requestAccounts" });
        const userAddress = accounts[0];
        setUserAddress(userAddress)
        checkIfWalletIsConnected(setUserAddress);
        return;
      }
      else {
        const confirmed = window.confirm(
          "Connect your MetaMask wallet to this app?"
        );

        if (confirmed) {
          let dappUrl = `${config.FRONTEND_URL.PHONE_BINANCE_API}`;
          let metamaskAppDeepLink = `https://metamask.app.link/dapp/${dappUrl}`;
          window.location.href = metamaskAppDeepLink;
        }
      }

    } catch (error) {
      console.log("error", error);
    }

  };

  async function checkIfWalletIsConnected(onConnected) {
    if (window.ethereum) {
      const accounts = await window.ethereum.request({
        method: "eth_accounts",
      });

      if (accounts.length > 0) {
        const account = accounts[0];
        localStorage.setItem("address", account);
        onConnected(account);
        return;
      }

      if (isMobileDevice()) {
        try {
          await connect(onConnected);
        } catch (error) {
          console.error("Error adding SIITO chain:", error);
        }
      }
    }
  }


  return (
    <div className={'App new-dao-new-navbar-section ' + props.referralnav}>
      {/* <ToastContainer /> */}
      <nav className="navbar navbar-expand-lg navbar-light bg-transparent" id="new-navbar">
        <div className="container-fluid d-flex">
          <a className="navbar-brand dashboard-left-navbar-brand" href="/">
            <img src={Logo} alt="Logo" />
          </a>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav d-flex flex-lg-row ms-auto mb-2 mb-lg-0 gap-3 mt-lg-0 mt-3">
              {isConnected ? (
                <a
                  class="nav-link navbar-connect-wallet-button"
                  // href="#"
                  aria-expanded="false"
                >
                  {address.substring(0, 5)}...
                  {address.substring(address.length - 4)}
                </a>
              ) : (
                <li class="nav-item dropdown">
                  <a
                    class="nav-link navbar-connect-wallet-button"
                    // href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Connect Wallet
                  </a>
                  <ul class="dropdown-menu">
                    <li>
                      <a
                        class="dropdown-item"
                        // href="#"
                        onClick={() => {
                          // isMobileDevice() ?
                          // handleMobileConnectClick() : 
                          connectmetamask();
                        }}
                      >
                        <img
                          src={MetaMask}
                          alt="MetaMask"
                          className="navbar-connect-wallet-logos"
                        />
                        MetaMask
                      </a>
                    </li>
                    {/* <li>
                      <a
                        class="dropdown-item"
                        // href="#"
                        onClick={() => {
                          connect({ connector: connectors[1] })
                        }}
                      >
                        <img
                          src={Ledger}
                          alt="Ledger"
                          className="navbar-connect-wallet-logos"
                        />
                        Ledger
                      </a>
                    </li> */}
                    {/* <li>
                      <a
                        class="dropdown-item"
                        // href="#"
                        onClick={() => {
                          connect({ connector: connectors[1] });
                        }}
                      >
                        <img
                          src={WalletConnect}
                          alt="WalletConnect"
                          className="navbar-connect-wallet-logos"
                        />
                        WalletConnect
                      </a>
                    </li>
                    <li>
                      <a
                        class="dropdown-item"
                        // href="#"
                        onClick={() => {
                          connectcoinbasewallet()
                        }}
                      >
                        <img
                          src={Coinbase}
                          alt="Coinbase"
                          className="navbar-connect-wallet-logos"
                        />
                        Coinbase Wallet
                      </a>
                    </li>
                    <li>
                      <a
                        class="dropdown-item"
                        // href="#"
                        onClick={() => {
                          connecttrustwallet()
                        }}
                      >
                        <img
                          src={TrustWallet}
                          alt="TrustWallet"
                          className="navbar-connect-wallet-logos"
                        />
                        Trust Wallet
                      </a>
                    </li> */}
                  </ul>
                </li>
              )}
              {isConnected ? (
                <li class="nav-item dropdown">
                  <a
                    class="nav-link navbar-connect-wallet-button"
                    // href="#"
                    role="button"
                    aria-expanded="false"
                    onClick={() => {disconnectWallet();}}
                  >
                    Disconnect
                  </a>
                </li>
              ) : (
                <></>
              )}
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default App;