import React, { useState, useEffect } from 'react';
import '../Assests/css/lottery.css';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { styled } from '@mui/material/styles';
import Rewardbanner from '../Assests/images/win/reward.png';
import { DiUnitySmall } from 'react-icons/di';
import { FaSortAmountUpAlt } from 'react-icons/fa';
import { SiAdguard } from 'react-icons/si';
import Navbar from "../Pages/Separate/Navbar";
import Web3 from 'web3';
import axios from 'axios';
import config from '../config/config';
import predictionAbi from "../abi/predictionAbi.json";
import { FaAngleLeft } from "react-icons/fa6";
import { MdGames } from "react-icons/md";
import { PiCoinsThin, PiTrendUpFill } from "react-icons/pi";
import { CgEditBlackPoint } from "react-icons/cg";
import {
    AiOutlinePlayCircle,
    AiOutlineFieldTime,
    AiOutlineArrowLeft,
    AiOutlineArrowRight,
    AiOutlineArrowUp,
    AiOutlineArrowDown,
} from "react-icons/ai";
import { useAccount, useSwitchNetwork } from 'wagmi';
import Loader from "../Assests/images/loader.gif";
import { ToastContainer, toast } from 'react-toastify';

const MySwal = withReactContent(Swal);

function Reward() {
    const [claimdata, setClaimdata] = useState([]);
    const { chains, switchNetwork } = useSwitchNetwork();

    const [page, setPage] = useState(1);
    const { address } = useAccount();
    const [buttonloader, setButtonLoader] = useState(Array(10).fill(false)); // Assuming 10 buttons for loading states

    const web3js = window.ethereum
        ? new Web3(window.ethereum)
        : new Web3(new Web3.providers.HttpProvider(config.DCXRPC_URL));

    const userAccount = async () => {
        const web3 = new Web3(window.ethereum);
        const accounts = await window.ethereum.request({
            method: "eth_requestAccounts",
        });
        // setAccount(accounts[0]);
        // console.log("account===",account);
    };

    const fetchData = async () => {
        try {
            console.log(address);
            const response = await axios.get(`${config.BACKEND_PREDICITON}/claimuser?page=${page}&pageSize=10`, {
                params: { userAddress: address }
            });

            setClaimdata(response.data.claimdata);
            console.log('claimdata', claimdata);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchData();
        userAccount();
        // setAccount(address)
    }, [page, address]);

    const handleNextPage = () => {
        setPage(prevPage => prevPage + 1);
    };

    const handlePrevPage = () => {
        setPage(prevPage => (prevPage > 1 ? prevPage - 1 : 1));
    };

    const handleClaimButtonClick = async (roundId, index) => {
        try {
            setButtonLoader(prevLoadingStates => prevLoadingStates.map((state, i) => (i === index ? true : state)));
            const chainId = await (window.ethereum
                ? window.ethereum.request({ method: "eth_chainId" })
                : web3js.eth.net.getId());
            // console.log('chainId', chainId);

            if (chainId.toString() !== "0x1498") {
                switchNetwork?.(chains[0].id);
                toast.info("Change your Network to D-chain");
                setButtonLoader(prevLoadingStates => prevLoadingStates.map((state, i) => (i === index ? false : state)));
                return;
            }
            
            // console.log('chainId', chainId);


            const PredictionContract = new web3js.eth.Contract(
                predictionAbi,
                config.predictionContract
            );
            console.log('PredictionContract', PredictionContract)
            toast.info("Please Donot Refresh the Page While Claim Rewards")

            const result = await PredictionContract.methods.claim([roundId]).send({
                from: address,
            });

            // console.log("result", result);

            if (result && result.error) {
                setButtonLoader(prevLoadingStates => prevLoadingStates.map((state, i) => (i === index ? false : state)));
                console.log("Error in contract execution:", result.error);
                return;
            }

            if (result && result.transactionHash) {
                const transactionHash = result.transactionHash;
                console.log("Transaction Hash:", transactionHash);
                const response = await axios.post(`${config.BACKEND_PREDICITON}/updateclaim?userAddress=${address}&round=${roundId}`);

                if (response && response.data) {
                    console.log("Data saved to the database:", response.data);
                    fetchData();
                } else {
                    setButtonLoader(prevLoadingStates => prevLoadingStates.map((state, i) => (i === index ? false : state)));
                    console.log("Error saving data to the database or no response received");
                }
            } else {
                setButtonLoader(prevLoadingStates => prevLoadingStates.map((state, i) => (i === index ? false : state)));
                console.log("Error in contract execution or no transaction hash found");
            }

        

        } catch (error) {
            setButtonLoader(prevLoadingStates => prevLoadingStates.map((state, i) => (i === index ? false : state)));
            console.log("error", error);
        }
    };



    return (
        <div>
            <Navbar />
            <ToastContainer/>
    
            <div class="statistics-hero-section py-5">
        <a href='/win' className='statistics-banner-back-button'><FaAngleLeft className='mb-1' /> Back</a>
        <div class="container">
            <div class="row align-items-center g-4 flex-lg-row-reverse">
                <div class="col-lg-6">
                    <img className='games-banner-image-1' src={Rewardbanner} />
                </div>
                <div class="col-lg-6">
                    <p className="statistics-text-1">Reward</p>
                    <p className="statistics-text-2 mt-3">After the Battles, Come the Rewards</p>
                </div>
            </div>
        </div>
    </div>

    <div class="games-second-section py-5">
        <div class="container">
            <div class="row align-items-center g-4 flex-lg-row-reverse">
                <div class="col">
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">ROUND ID<MdGames className='ms-2 mb-1' /></th>
                                <th scope="col">REWARDED AMOUNT<PiCoinsThin className='ms-2 mb-1' /></th>
                                <th scope="col">BET POSITION<CgEditBlackPoint className='ms-2 mb-1' /></th>
                                <th scope="col">COLLECT <SiAdguard className='ms-2 mb-1' /></th>
                            </tr>
                        </thead>
                        <tbody>
                            {Array.isArray(claimdata) && claimdata.length > 0 ? (
                                claimdata.map((item, index) => (
                                    <tr key={item._id}>
                                        <td>{item.roundId}</td>
                                        <td>{item.rewardAmount ? `${item.rewardAmount.toFixed(7)} DCX` : "0 DCX"}</td>
                                        <td>{item.betPosition}</td>
                                        {item.claimed ? (
                                            <td><span className='colour-green'>Claimed</span></td>
                                        ) : (
                                            <td>
                                                <button
                                                    type="button"
                                                    className="reward-claim-button"
                                                    onClick={() => handleClaimButtonClick(item.roundId, index)}
                                                    disabled={buttonloader[index]}
                                                >
                                                    {buttonloader[index] ? (
                                                        <img
                                                            className="w-auto mx-auto Button-loader-small-claim"
                                                            src={Loader}
                                                        />
                                                    ) : (
                                                        "CLAIM"
                                                    )}
                                                </button>
                                            </td>
                                        )}
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colspan="4" style={{ textAlign: "center" }}>NO DATA...</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    <div style={{ display: 'flex', gap: '20px' }} className='align-items-center'>
                        <button className='reward-arrow-button-new' onClick={handlePrevPage} style={{ background: 'transparent', cursor: 'pointer' }}><AiOutlineArrowLeft style={{ color: 'white' }} /></button>
                        {page}
                        {claimdata.length >= 10 ? (
                            <button onClick={handleNextPage} style={{ background: 'transparent', border: 'none', cursor: 'pointer' }}><AiOutlineArrowRight style={{ color: 'white' }} /></button>
                        ) : (
                            <button className='reward-arrow-button-new' style={{ background: 'transparent', cursor: 'pointer' }} disabled><AiOutlineArrowRight style={{ color: 'white' }} /></button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
);
}

export default Reward;

    