import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../../config/config';
import {
  AiOutlinePlayCircle,
  AiOutlineFieldTime,
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
  AiOutlineArrowUp,
  AiOutlineArrowDown,
} from "react-icons/ai";
import Web3 from 'web3';
import predictionAbi from "../../abi/predictionAbi.json";


const Roundhistry = () => {
  const [data, setData] = useState([]);
  const [claim, setclaim] = useState([]);
  const [page, setPage] = useState(1);
  
  const [pageSize, setPageSize] = useState(10);

  const web3js = window.ethereum
		? new Web3(window.ethereum)
		: new Web3(new Web3.providers.HttpProvider(config.DCXRPC_URL));

    

  useEffect(() => {
    const fetchData = async () => {

      try {
        const userAddress = "0x400f79117E5e82C7dd997c02C1aEdAa06BA4c345"
        const response = await axios.get(`${config.BACKEND_PREDICITON}/getusers?page=${page}&pageSize=${pageSize}`, {
          params: { userAddress }
        });

        // console.log("Response data:", response.data);
        setData(response.data.user);
        setclaim(response.data.user); 
      } catch (error) {
        console.error('Error fetching data:', error);
        // Handle error or set a default value for data
      }
    };

    fetchData();
  }, [page, pageSize]);

  const handleNextPage = () => {
    setPage(prevPage => prevPage + 1);
  };

  const handlePrevPage = () => {
    setPage(prevPage => (prevPage > 1 ? prevPage - 1 : 1));
  };


  useEffect(() => {
  
  
  }, [])
  
  const Handleclaimuser=async(e)=>{
    console.log(e);
    const accounts = await web3js.eth.getAccounts();
    console.log("accounts",accounts);
    const PredictionContract = new web3js.eth.Contract(
      predictionAbi,
      config.predictionContract
    );
    
    const result = await PredictionContract.methods.claim([1]).send({
      from: accounts[0],
    });
    console.log("result",result)
  }

  function shortenAddress(address) {
    if (address.length > 8) {
      return address.slice(0, 6) + "...." + address.slice(-6);
    } else {
      return address; // Return as it is if the length is less than or equal to 8
    }
  }
  const originalAddress = "0x400f79117E5e82C7dd997c02C1aEdAa06BA4c345"
  shortenAddress(originalAddress);

  return (
    <div>

      <div class="offcanvas-body">
        <h5 className="">Rounds</h5>
        <div className="d-flex flex-lg-row flex-column align-items-center mb-4">
          <h3 className="fw-bold mb-0">21332.213</h3>
          <button className="new-predic-histroy-check ms-auto">
            Check
          </button>
        </div>
        <p
          className="mb-0"
          style={{ fontSize: "11px", letterSpacing: "1px" }}
        >
          Filter
        </p>
        <div className="new-predic-histroy-tabs">
          <ul
            class="nav nav-pills mb-3 nav-justified"
            id="pills-tab"
            role="tablist"
          >
            <li class="nav-item" role="presentation">
              <button
                class="nav-link active"
                id="pills-all-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-all"
                type="button"
                role="tab"
                aria-controls="pills-all"
                aria-selected="true"
              >
                All
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="pills-Collected-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-Collected"
                type="button"
                role="tab"
                aria-controls="pills-Collected"
                aria-selected="false"
              >
                Collected
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="pills-Uncollected-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-Uncollected"
                type="button"
                role="tab"
                aria-controls="pills-Uncollected"
                aria-selected="false"
              >
                Uncollected
              </button>
            </li>
          </ul>
        </div>

        {/* all reacord */}
        <div class="tab-content" id="pills-tabContent">
          <div
            class="tab-pane fade show active"
            id="pills-all"
            role="tabpanel"
            aria-labelledby="pills-all-tab"
            tabindex="0"
          >
            <table class="table text-white">
              <thead>
                <tr>
                  <th>Rounds</th>
                  <th>Predictions</th>
                  <th>Result</th>
                </tr>
              </thead>
              <tbody>
                {data.map(item => (
                  <tr key={item._id}>
                    <td>{item.roundId}</td>
                    <td>{item.betamount}</td>
                    <td>{item.roundstatus}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div style={{ display: 'flex', gap: '10px' }}>
              <button onClick={handlePrevPage} style={{ background: 'transparent', border: 'none', cursor: 'pointer' }}><AiOutlineArrowLeft style={{ color: 'white' }} /></button>
              <button onClick={handleNextPage} style={{ background: 'transparent', border: 'none', cursor: 'pointer' }}><AiOutlineArrowRight style={{ color: 'white' }} /></button>
            </div>

          </div>

          {/* claim data */}
          <div
            class="tab-pane fade"
            id="pills-Collected"
            role="tabpanel"
            aria-labelledby="pills-Collected-tab"
            tabindex="0"
          >
            <table class="table text-white">
              <thead>
                <tr>
                  <th scope="col">Rounds</th>
                  <th scope="col">Prediction</th>
                  <th scope="col">Result</th>
                  <th scope="col">Reward</th>
                </tr>
              </thead>
              <tbody>
                {
                  Array.isArray(claim) && claim.length > 0 && claim.map((dts, ind) => (
                  <tr>    
                  {dts.roundstatus === "win" ? (
                      <>
                        <td>{dts.roundId}</td>
                        <td>{dts.betamount}</td>
                        <td>{dts.roundstatus}</td>
                        <td>{!dts.claimed ? "claimed" : null}</td>
                      </>
                    )
                  :
                  null}
                  </tr>            
                  ))
                }
              </tbody>
            </table>
            <div style={{ display: 'flex', gap: '10px' }}>
              <button onClick={handlePrevPage} style={{ background: 'transparent', border: 'none', cursor: 'pointer' }}><AiOutlineArrowLeft style={{ color: 'white' }} /></button>
              <button onClick={handleNextPage} style={{ background: 'transparent', border: 'none', cursor: 'pointer' }}><AiOutlineArrowRight style={{ color: 'white' }} /></button>
            </div>
          </div>

          {/* unclaim data  */}
          <div
            class="tab-pane fade"
            id="pills-Uncollected"
            role="tabpanel"
            aria-labelledby="pills-Uncollected-tab"
            tabindex="0"
          >
            <div className="text-center mt-4">
              <button className="new-predic-histroy-claim " onClick={()=>Handleclaimuser("5")}>
                Claim
              </button>
            </div>
          </div>
        </div>

        <div className="text-center mt-4">
          <h5 className="fw-bold">No prediction history available</h5>
          <p className="win-text-21">
            If you are sure you should see history here, make sure
            you’re connected to the correct wallet and try again.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Roundhistry;
