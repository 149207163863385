import Error from "../Assests/images/new/error404.png"

function App() {
  return (
    <div className="App">
         <div className="container-fluid dao-404-error-page-design">
        <div className="row min-vh-100 align-items-center justify-content-center">
          <div className="col-lg-12 text-center ">
           <img src={Error} style={{width:"50%"}} alt="Error" /><br/>
           <a href="/"  className="btn btn-outline-light">Back to Home</a>
          </div>


        </div>
      </div>
    </div>
  );
}

export default App;
