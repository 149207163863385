import React, { useState, useEffect, useRef } from "react";
import "../Assests/css/lottery.css";
import "../Assests/css/Style.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Mousewheel, Autoplay } from "swiper/modules";
// import 'swiper/swiper-bundle.min.css'
// import 'swiper/swiper.min.css'
import "swiper/css";
import wingif from "../Assests/images/Animation - 1707131995939.gif";
import losegif from "../Assests/images/Animation - 1707138664857.gif";
import {
  AiOutlinePlayCircle,
  AiOutlineFieldTime,
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
  AiOutlineArrowUp,
  AiOutlineArrowDown,
} from "react-icons/ai";
import { SlGameController } from "react-icons/sl";
import logoblack from "../Assests/images/dimage.svg";
import { Range, getTrackBackground } from "react-range";
import leftarrow from "../Assests/images/win/left-arrow.png";
import rightarrow from "../Assests/images/win/right-arrow.png";
import Ticket from "../Assests/images/win/ticket.png";
import clock from "../Assests/images/win/clock.png";
import { AiOutlineQuestionCircle, AiOutlineClockCircle } from "react-icons/ai";
import { IoDownload, IoLogoWindows, IoTrophyOutline } from "react-icons/io5";
import { MdOutlineLeaderboard } from "react-icons/md";
import Web3 from "web3";
import $, { error, event, timers } from "jquery";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Navbar from "../Pages/Separate/Navbar";
import axios from "axios";
import bsctestAbi from "../abi/testbsc.json";
import Roundhistry from "./history/userhistry";
import ProgressBar from "react-bootstrap/ProgressBar";
import predictionAbi from "../abi/predictionAbi.json";
import config from "../config/config";

import Tradingview from "./Chart/Tradingview";
import Chainlink from "./Chart/Chainlink";
import Loader from "../Assests/images/loader.gif";
import SwiperCore from "swiper";
import { useStepContext } from "@mui/material";
import { useAsyncError, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { PiSealCheckFill } from "react-icons/pi";
import { VscCircleSlash } from "react-icons/vsc";
import {
  useAccount,
  useConnect,
  useDisconnect,
  useEnsAvatar,
  useEnsName,
  useNetwork,
  useSwitchNetwork,
} from "wagmi";
import { Tooltip } from "react-tooltip";
import { add } from "date-fns";
import pdf from "../Assests/images/DAOPrediction.pdf";

const MySwal = withReactContent(Swal);

const STEP = 0.1;
const MIN = 0;
const MAX = 100;

function Win() {
  const [swiper, setSwiper] = useState(null);
  const goToFirstPage = () => swiper.slideTo(1);
  const { address, isConnected, connector } = useAccount();
  const { connect, connectors, isLoading, pendingConnector } = useConnect();
  const { chain } = useNetwork();
  const { chains, pendingChainId, switchNetwork, error } = useSwitchNetwork();
  // goToFirstPage()
  const [upcard, setupcard] = useState("");
  const [userethbalance, setuserethbalance] = useState(0);
  const [dragvalues, setdragvalues] = useState("0");
  const [targetval, settargetval] = useState("");

  const [rangeerror, setRangeError] = useState(false);

  const [seconds, setSeconds] = useState(0);
  const [timer, setTimer] = useState("");

  const [currentRoundId, setCurrentRoundId] = useState(0);
  const [firstprevround, setFirstprevround] = useState();
  const [prevround, setprevround] = useState(0);
  const [currentround, setCurrentround] = useState(0);
  const [nextround, setnextround] = useState(1);
  const [later, setLater] = useState(2);
  const [secondlater, setSecondlater] = useState();
  const [changestate, setchangestate] = useState("");
  const [mode, setMode] = useState("");
  const [account, setAccount] = useState("");
  const [latersec, setLatersec] = useState();
  const [nexttime, setnexttime] = useState("10:00");
  const [roundStartTime, setRoundStartTime] = useState(1702278921);
  const [roundLockTime, setRoundLockTime] = useState(0);
  const [roundCloseTime, setRoundCloseTime] = useState(1702285355);
  const [isloader, setIsloader] = useState(true);
  const [startprice, setStartprice] = useState(0);
  const [predictrate, setPredictrate] = useState(0);
  const [currentTimeUnix, setCurrentTimeUnix] = useState(0);
  const [dcxprice, setDcxprice] = useState("");
  const [firstRoundDetails, setfirstRoundDetails] = useState([]);
  const [secondCardDetails, setSecondCardDetails] = useState([]);
  const [isdisable, setIsdisable] = useState(false);
  const [buttonloader, setbuttonloader] = useState(false);
  const [totalpoolprice, settotalpool] = useState(0);
  const [livebear, setlivebear] = useState(0);
  const [livebull, setlivebull] = useState(0);
  const [livetotalpool, setlivetotalpool] = useState(0);
  const [secondbull, setsecondbull] = useState(0);
  const [secondbear, setsecondbear] = useState(0);
  const [firstbull, setfirstbull] = useState(0);
  const [firstbear, setfirstbear] = useState(0);
  const [currentbull, setcurrentbull] = useState(0);
  const [currentbear, setcurrentbear] = useState(0);
  const [datas, setData] = useState(false);
  const [livestart, setlivestart] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const [firstennter, setfirstenter] = useState(false);
  const [secondenter, setsecondenter] = useState(false);
  const [liveenter, setliveenter] = useState(false);
  const [currententer, setcurrententer] = useState(false);
  const [imageloader, setimageloader] = useState(true);
  const [firstwin, setfirstwin] = useState(false);
  const [secondwin, setsecondwin] = useState(false);

  const web3js = window.ethereum
    ? new Web3(window.ethereum)
    : new Web3(new Web3.providers.HttpProvider(config.DCXRPC_URL));

  useEffect(() => {
    const updateCurrentTime = () => {
      const unixTime = Math.floor(Date.now() / 1000);
      setCurrentTimeUnix(unixTime);
    };

    const intervalId = setInterval(updateCurrentTime, 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (address) {
      switchChain(5272);
    }
  }, []);

  const switchChain = async (targetChainId) => {
    if (window.ethereum) {
      try {
        // Convert targetChainId to a hexadecimal string with '0x' prefix
        const hexTargetChainId = "0x" + targetChainId.toString(16);

        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: hexTargetChainId }],
        });

        // console.log('Switched to chain with ID:', hexTargetChainId);
      } catch (error) {
        if (error.code === 4902) {
          // console.log('User rejected the chain switch');
        } else {
          console.error("Error switching chain:", error);
        }
      }
    } else {
      console.error("MetaMask not found");
    }
  };

  const CurrentDetails = async () => {
    try {
      const response = await axios
        .get(`${config.BACKEND_PREDICITON}/currentRound`)
        .then((res) => {
          setCurrentRoundId(res.data.data[0].currentRound);
          setRoundStartTime(res.data.data[0].startTime);
          setRoundLockTime(res.data.data[0].locktime);
          setRoundCloseTime(res.data.data[0].closetime);
          // console.log('setCurrentRoundId', currentRoundId)
          // console.log('res.data.data[0].locktime', res.data.data[0].locktime)
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    CurrentDetails();
  }, [seconds]);

  const enterup = (e) => {
    setupcard("UP");
  };

  const enterdown = () => {
    setupcard("DOWN");
  };

  const changePosition = () => {
    if (upcard == "UP") {
      setupcard("DOWN");
    } else {
      setupcard("UP");
    }
  };
  const win = async () => {
    const response = await axios.get(
      `${config.BACKEND_PREDICITON}/getusers?page=${1}&pageSize=${5}`,
      {
        params: { userAddress: address },
      }
    );
    let userData = response.data.user;
    // Check if there is a user with roundId 463 and result "WIN"
    const sfirstwin = userData.some(
      (user) => user.roundId === currentRoundId - 3 && user.result === "WIN"
    );
    const ssecondwin = userData.some(
      (user) => user.roundId === currentRoundId - 2 && user.result === "WIN"
    );
    setfirstwin(sfirstwin);
    setsecondwin(ssecondwin);
  };
  //   win()
  const userbet = async () => {
    try {
      const web3js = window.ethereum
        ? new Web3(window.ethereum)
        : new Web3(
            new Web3.providers.HttpProvider(
              "https://mainnetcoin.d-ecosystem.io/"
            )
          );

      const BetContract = new web3js.eth.Contract(
        predictionAbi,
        config.predictionContract
      );
      const currentround = await BetContract.methods.currentEpoch().call();
      // console.log(currentround,'currentround')

      // Fetch data from the current round
      const currentRoundData = await BetContract.methods
        .ledger(currentround, address)
        .call();

      // Fetch data from the previous round
      const previousRoundData1 = await BetContract.methods
        .ledger(currentround - 1, address)
        .call();

      // Fetch data from the second previous round
      const previousRoundData2 = await BetContract.methods
        .ledger(currentround - 2, address)
        .call();

      // Fetch data from the third previous round
      const previousRoundData3 = await BetContract.methods
        .ledger(currentround - 3, address)
        .call();
      if (currentRoundData.amount > 0) {
        setcurrententer(true);
      } else {
        setcurrententer(false);
      }

      if (previousRoundData1.amount > 0) {
        setliveenter(true);
      } else {
        setliveenter(false);
      }

      if (previousRoundData2.amount > 0) {
        setsecondenter(true);
      } else {
        setsecondenter(false);
      }

      if (previousRoundData3.amount > 0) {
        setfirstenter(true);
      } else {
        setfirstenter(false);
      }
      setimageloader(false);
    } catch (error) {
      // console.log('error', error);
    }
  };

  // useEffect(()=>{
  // 	setInterval(() => {
  // 		userbet()
  // 	}, 1000*5);

  // },[address])

  const predictionBet = async () => {
    await switchChain(5272);
    try {
      setbuttonloader(true);
      if (!address) {
        toast.error("Please Connect Your Wallet ");
        setbuttonloader(false);
        return;
      }
      if (targetval == 0) {
        toast.error("enter betamount");
        setbuttonloader(false);
        return;
      }
      let userMetaAddress = localStorage.getItem("wagmi.store");
      if (userMetaAddress) {
        let userMeta = JSON.parse(userMetaAddress);
        let addr = userMeta.state.data;
        if (addr) {
          let chainid = userMeta.state.data.chain.id;

          const web3js = window.ethereum
            ? new Web3(window.ethereum)
            : new Web3(
                new Web3.providers.HttpProvider(
                  "https://mainnetcoin.d-ecosystem.io/"
                )
              );
          if (chainid != config.DCXCHAIN_ID) {
            switchNetwork?.(chains[0].id);
            setbuttonloader(false);
            toast.info("Change your Network to D-chain");
          } else {
            const BetContract = new web3js.eth.Contract(
              predictionAbi,
              config.predictionContract
            );
            const betAmount = web3js.utils.toWei(targetval.toString(), "ether");
            const transactionOptions = {
              from: `${address}`, // Replace with the sender's Ethereum address
              value: betAmount,
            };
            let betMethod;
            if (upcard === "DOWN") {
              betMethod = BetContract.methods.betBear(currentRoundId);
            } else if (upcard === "UP") {
              betMethod = BetContract.methods.betBull(currentRoundId);
            }

            const predictionBetting = betMethod
              .send(transactionOptions)
              .then(async (res) => {
                // await calc();

                // setIsdisable(true);
                // toast.success("bet Success");
                const accountList =
                  JSON.parse(localStorage.getItem("betting")) || [];
                if (!accountList.includes(address)) {
                  accountList.push(address); // Add the current account if not already present
                  localStorage.setItem("betting", JSON.stringify(accountList));
                }
                const payload = {
                  roundId: currentRoundId,
                  currentPrice: dcxprice,
                  userAddress: address,
                  betPosition: upcard,
                  betamount: betAmount,
                  betSuccess: true,
                };
                const backendcall = await axios
                  .post(
                    `${config.BACKEND_PREDICITON}/addPredictionData`,
                    payload
                  )
                  .then(async (response) => {
                    if (response) {
                      // setIsdisable(true);
                      setbuttonloader(false);
                      toast.success("Betting Success");
                      $(".flip-card-section-1").removeClass("flip-card-rotate");
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                    setbuttonloader(false);
                    toast.error("something went wrong");
                  });
              })
              .catch((err) => {
                console.log(err);
                setbuttonloader(false);
                toast.error("Betting Cancelled");
              });
          }
        }
      }
    } catch (error) {
      console.log(error);
      toast.error("something went wrong");
    }
  };
  // predictionBet()
  const percentamount = async (event) => {
    const swichval = event;

    switch (swichval) {
      case "10":
      case "20":
      case "25":
      case "50":
      case "75":
        const switchpercent = (userethbalance * swichval) / 100;
        setdragvalues(swichval);
        setRangeError(switchpercent >= userethbalance);
        settargetval(switchpercent.toFixed(2));
        break;
      case "max":
        const dcxamt = userethbalance * 1.0;
        var maxval = 100;
        setdragvalues(maxval);
        setRangeError(dcxamt > userethbalance);
        settargetval(dcxamt.toFixed(3));
        break;
      default:
        return;
    }
  };

  const percentageamountdrag = (event) => {
    const valuespercent = (userethbalance * event[0]) / 100;
    const percentvalues = parseFloat(valuespercent).toFixed(3);
    setdragvalues(event[0]);
    // setuserethbalance(percentvalues)

    if (percentvalues > userethbalance) {
      settargetval(percentvalues);
      setRangeError(true);
    } else {
      settargetval(percentvalues);
      setRangeError(false);
    }
    // settargetval(percentvalues)
  };

  const getvaluechange = async (e) => {
    e.preventDefault();
    try {
      const valuenum = e.target.value.match(/\d*\.?\d*/);
      // settargetval(valuenum)

      if (!isNaN(valuenum)) {
        const percentVal = (valuenum * 100) / userethbalance;
        // setdragvalues(percentVal.toFixed(2));
        if (Number(valuenum) >= Number(userethbalance)) {
          setdragvalues(0);
          setRangeError(true);
        } else {
          settargetval(valuenum);
          setRangeError(false);
          setdragvalues(percentVal.toFixed(2));
        }
      }
      // settargetval(valuenum)
    } catch (error) {
      console.log("error", error);
    }
  };

  const userAccount = async () => {
    try {
      const web3 = new Web3(window.ethereum);
      // bsc mainet contract for time set
      const bsc = new Web3(
        new Web3.providers.HttpProvider("https://mainnetcoin.d-ecosystem.io/")
      );
      let userMetaAddress = localStorage.getItem("wagmi.store");
      if (userMetaAddress) {
        let userMeta = JSON.parse(userMetaAddress);
        let addr = userMeta.state.data;
        if (addr) {
          let chainid = userMeta.state.data.chain.id;
          if (chainid == config.DCXCHAIN_ID) {
            const accounts = await window.ethereum.request({
              method: "eth_requestAccounts",
            });

            // setAccount(accounts[0]);

            const bal = await web3.eth.getBalance(address);

            const balanceof = web3.utils.fromWei(bal, "ether");
            var ballof = Number(balanceof);

            // const sampletestbal = 50;
            setuserethbalance(ballof.toFixed(4));
          } else {
            setuserethbalance(0);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const roundset = () => {
    if (currentround) {
      setMode("live");
    } else if (nextround) {
      setMode("Next");
    } else {
      setMode("");
    }
  };

  const convertUnixTimestamp = (unixTimestamp) => {
    // Create a new Date object
    var date = new Date(unixTimestamp * 1000);

    return date;
  };
  let seconds2;
  let minutes;
  let seconds3;
  useEffect(() => {
    const roundtime =
      convertUnixTimestamp(roundLockTime) -
      convertUnixTimestamp(currentTimeUnix) / 60;

    seconds2 = Math.floor(
      (convertUnixTimestamp(roundLockTime) -
        convertUnixTimestamp(currentTimeUnix)) /
        1000
    );
    seconds3 = Math.floor(
      (convertUnixTimestamp(roundLockTime) -
        convertUnixTimestamp(currentTimeUnix)) /
        1000
    );
    setSeconds(seconds3);

    minutes = Math.floor(seconds2 / 60);
    var hours = Math.floor(minutes / 60);
    hours %= 24;
    minutes %= 60;
    seconds2 %= 60;

    minutes = minutes < 0 ? 0 : minutes;
    seconds2 = seconds2 < 0 ? 0 : seconds2;

    if (minutes > 0 || seconds2 > 0) {
      setIsloader(false);
      setimageloader(false);

      setTimer(minutes + ":" + seconds2);
    } else {
      if (document.readyState === "complete") {
        localStorage.removeItem("betting");

        setIsloader(true);
        setTimer(0);
        setSeconds(0);
        setLatersec(0);

        localStorage.removeItem("timerSeconds");
      }
    }
    const totalSeconds = minutes * 60 + seconds;

    // // Calculate nexttime as two times of the timer
    // const nextTotalSeconds = totalSeconds;

    // // Convert nextTotalSeconds back to minutes and seconds
    // const nextMinutes = Math.floor(nextTotalSeconds / 60);
    // const nextSeconds = nextTotalSeconds % 60;

    // // Set the nexttime in the desired format
    // const nextTimeFormatted = `${nextMinutes}:${nextSeconds}`;

    const countdown = setInterval(() => {
      // const totalSeconds = minutes * 60 + seconds;
      // 	const min = Math.floor(totalSeconds / 60);
      // 	const remainingSec = totalSeconds % 60;
      // 	const nextformattedTime = `${min}:${String(remainingSec).padStart(
      // 		2,
      // 		"0"
      // 	)}`;
      // 	setnexttime(nextTimeFormatted);

      if (seconds > 0) {
        setSeconds(seconds - 1);
        // setLatersec(latersec - 1);
      } else {
        setSeconds(300);
        // setLatersec(600);
      }
    }, 1000);

    roundset();

    return () => clearInterval(countdown);
  }, [seconds]);

  useEffect(() => {
    var seconds4 = Math.floor(
      (convertUnixTimestamp(roundLockTime) -
        convertUnixTimestamp(currentTimeUnix)) /
        1000
    );

    var laterminutes = Math.floor(seconds4 / 60);
    var hours = Math.floor(laterminutes / 60);
    hours %= 24;
    laterminutes %= 60;
    seconds4 %= 60;

    laterminutes = laterminutes < 0 ? 0 : laterminutes;
    seconds4 = seconds4 < 0 ? 0 : seconds4;
    // // setLatersec(seconds4)

    // if (laterminutes > 0 || seconds4 > 0) {
    // 	setIsloader(false);

    // 	// setTimer(minutes + ":" + seconds2);
    // } else {
    // 	// localStorage.removeItem("betting")
    // 	// setIsloader(true);
    // 	// setTimer(0);
    //     // setSeconds(0)
    // }
    const countdown = setInterval(() => {
      const totalSeconds = laterminutes * 60 + seconds4;
      const min = Math.max(Math.floor(totalSeconds / 60), 0) + 5;
      const remainingSec = totalSeconds % 60;
      const nextformattedTime = `${min}:${String(remainingSec).padStart(
        2,
        "0"
      )}`;
      setnexttime(nextformattedTime);

      if (latersec > 0) {
        setLatersec(latersec - 1);
      } else {
        setLatersec(600);
      }
    }, 1000);

    roundset();

    return () => clearInterval(countdown);
  }, [latersec]);

  const calculateProgress = () => {
    const progress = ((300 - seconds) / 300) * 100;
    return progress.toFixed(1);
  };

  let predictionStyle = {};

  const setpricedata = async () => {
    const prerate = dcxprice - startprice;
    setPredictrate(prerate.toFixed(4));

    if (prerate < 0) {
      predictionStyle = {
        backgroundColor: "red",
        color: "white",
      };
    } else {
      predictionStyle = {
        backgroundColor: "green",
        color: "white",
      };
    }
  };
  useEffect(() => {
    setpricedata();

    const accountList = JSON.parse(localStorage.getItem("betting")) || [];
    const hasBet = accountList.includes(address);

    if (hasBet) {
      setIsdisable(true);
    } else {
      setIsdisable(false);
    }
  }, [seconds, isdisable]);

  const fetchPrice = async () => {
    try {
      const priceval = await axios.get(`${config.BACKEND_PREDICITON}/dcxprice`);

      const getprice = priceval.data.data[0].dcxprice;
      // console.log('getprice', getprice)
      setDcxprice(getprice.toFixed(4));
    } catch (error) {}
  };

  useEffect(() => {
    let userMetaAddress = localStorage.getItem("wagmi.store");
    let userMeta = JSON.parse(userMetaAddress);
    let addr = userMeta.state.data.account;
    // if (!addr && location.pathname == "/win") {
    // 	navigate("/dashboard");
    // }

    fetchPrice();

    // Fetch every 30 seconds
  }, [seconds]);

  useEffect(() => {
    // setpricedata();
    userAccount();
  }, [address, seconds]);

  useEffect(() => {
    $(".btn-win-up-1").click(function () {
      $(".flip-card-section-1").addClass("flip-card-rotate");
    });
    $(".btn-win-down-1").click(function () {
      $(".flip-card-section-1").addClass("flip-card-rotate");
    });
    $(".win-icon-3").click(function () {
      $(".flip-card-section-1").removeClass("flip-card-rotate");
    });
  });

  useEffect(() => {
    // getFromContract();

    firstCarddetails();
    secondCardDetail();
  }, [seconds]);

  const findlive = async () => {
    try {
      // const current = currentRoundId
      const PredictionContract = new web3js.eth.Contract(
        predictionAbi,
        config.predictionContract
      );
      const curenround = await PredictionContract.methods.currentEpoch().call();
      const currentPrice = await PredictionContract.methods
        .rounds(Number(curenround) - 1)
        .call();
      setStartprice(Number(currentPrice.lockPrice) / 1e18);

      const totalAmount = Number(currentPrice.totalAmount) / 1e18;
      const bullAmount = Number(currentPrice.bullAmount) / 1e18;
      const bearAmount = Number(currentPrice.bearAmount) / 1e18;

      // const totalAmount = 6;
      // const bullAmount = 2;
      // const bearAmount = 4;

      if (totalAmount > 0) {
        const reward = await rewardCalc(totalAmount, bearAmount, bullAmount);

        if (reward.bearreward == 1 || reward.bullreward == 1) {
          setlivebull(1);
          setlivebear(1);
        } else {
          setlivebull(reward.bullreward.toFixed(2));
          setlivebear(reward.bearreward.toFixed(2));
        }

        setlivetotalpool(totalAmount);
      } else {
        // const reward = await rewardCalc(totalAmount, bearAmount, bearAmount);
        setlivebull(0);
        setlivebear(0);
        setlivetotalpool(totalAmount);
      }
    } catch (error) {
      // console.log("error", error);
    }
  };

  const findcurrent = async () => {
    try {
      // const current = currentRoundId
      const PredictionContract = new web3js.eth.Contract(
        predictionAbi,
        config.predictionContract
      );
      const curenround = await PredictionContract.methods.currentEpoch().call();
      const currentPrice = await PredictionContract.methods
        .rounds(Number(curenround))
        .call();
      const totalAmount = Number(currentPrice.totalAmount) / 1e18;
      const bullAmount = Number(currentPrice.bullAmount) / 1e18;
      const bearAmount = Number(currentPrice.bearAmount) / 1e18;

      // const totalAmount = 6;
      if (totalAmount > 0) {
        const reward = await rewardCalc(totalAmount, bearAmount, bullAmount);
        if (reward.bullreward == 1 || reward.bearreward == 1) {
          setcurrentbull(reward.bullreward);
          setcurrentbear(reward.bearreward);
        }
        setcurrentbull(reward.bullreward.toFixed(2));
        setcurrentbear(reward.bearreward.toFixed(2));
        settotalpool(totalAmount);
      } else {
        // const reward = await rewardCalc(totalAmount, bearAmount, bearAmount);
        setcurrentbull(0);
        setcurrentbear(0);
        settotalpool(totalAmount);
      }
    } catch (error) {
      // console.log("error", error);
    }
  };

  // setInterval(() => {
  // 	findlive();

  // }, 1000 * 10);
  useEffect(() => {
    const get = setInterval(() => {
      findlive();
      findcurrent();
      userbet();
      win();
    }, 2 * 1000);

    return () => clearInterval(get);
  }, [address, livebear, currentbear]);
  const rewardCalc = async (totalamount, bearamount, bullamount) => {
    var bullreward;
    var bearreward;
    if (bullamount == 0 || bearamount == 0) {
      bullreward = 1;
      bearreward = 1;
    } else {
      bullreward = totalamount / bullamount;
      bearreward = totalamount / bearamount;
    }
    return { bullreward, bearreward };
  };

  const firstCarddetails = async () => {
    try {
      const response = await axios.post(
        `${config.BACKEND_PREDICITON}/getRoundDetails`,
        { currentRound: currentRoundId - 3 }
      );
      setfirstRoundDetails(response.data.data);

      const bullamount = response.data.data[0].bullAmount
        ? response.data.data[0].bullAmount / 1e18
        : 0;
      const bearAmount = response.data.data[0].bearAmount
        ? response.data.data[0].bearAmount / 1e18
        : 0;
      const total = response.data.data[0].totalpoolprice
        ? response.data.data[0].totalpoolprice / 1e18
        : 0;
      if (total > 0) {
        const reward = await rewardCalc(total, bearAmount, bullamount);

        if (reward.bullreward == 1 || reward.bearreward == 1) {
          setfirstbull(1);
          setfirstbear(1);
        } else {
          setfirstbull(reward.bullreward.toFixed(2));
          setfirstbear(reward.bearreward.toFixed(2));
        }
      } else {
        setfirstbull(0);
        setfirstbear(0);
      }
    } catch (error) {
      // console.log(error);
    }
  };

  const secondCardDetail = async () => {
    try {
      const response = await axios.post(
        `${config.BACKEND_PREDICITON}/getRoundDetails`,
        { currentRound: currentRoundId - 2 }
      );

      setSecondCardDetails(response.data.data);
      const bullamount = response.data.data[0].bullAmount
        ? response.data.data[0].bullAmount / 1e18
        : 0;
      const bearAmount = response.data.data[0].bearAmount
        ? response.data.data[0].bearAmount / 1e18
        : 0;
      const total = response.data.data[0].totalpoolprice
        ? response.data.data[0].totalpoolprice / 1e18
        : 0;
      if (total > 0) {
        const reward = await rewardCalc(total, bearAmount, bullamount);
        if (reward.bullreward == 1 || reward.bearreward == 1) {
          setsecondbear(1);
          setsecondbull(1);
        } else {
          setsecondbull(reward.bullreward.toFixed(2));
          setsecondbear(reward.bearreward.toFixed(2));
        }
      } else {
        setsecondbear(0);
        setsecondbull(0);
      }
    } catch (error) {
      // console.log(error);
    }
  };

  // useEffect(() => {
  // 	window.scrollTo(0,0);
  // });

  useEffect(() => {
    // Scroll to the top of the page when component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Navbar />
      <ToastContainer />

      <div className="pb-5">
        <div className="container-fluid">
          <div className="row align-items-center justify-content-center g-4">
            <h2
              className="text-center fw-bold mb-2"
              style={{ letterSpacing: "1px" }}
            >
              Crypto Price Prediction
            </h2>
            <p className="text-center p-0 m-0 mb-3">
              Predict BTC Price - Win the DCX Pool
            </p>
            <div className="col-lg-12 ">
              <div class="d-flex flex-lg-row flex-wrap gap-3  justify-content-lg-between justify-content-center align-items-center">
                <div className="d-flex order-xl-0 order-0 order-md-0">
                  <div class="win-top-balance-1">
                    <span className="win-text-23">BTC/USDT</span>
                    <span className="win-text-24">${dcxprice}</span>
                  </div>
                  <Tooltip
                    anchorSelect=".my-anchor-element"
                    place="top"
                    style={{ background: "#FFF", color: "#3989f9" }}
                  >
                    Games
                  </Tooltip>
                  <a href="/games" className="ms-auto my-anchor-element">
                    <div class="win-top-balance-4 ms-2">
                      <a
                        data-tooltip-id="my-tooltip2"
                        data-tooltip-content="Games"
                      >
                        <span>
                          <SlGameController
                            className="win-top-icon-1"
                            data-tooltip-id="my-tooltip2"
                            data-tooltip-content="Games"
                          />
                        </span>
                      </a>
                    </div>
                  </a>
                  <Tooltip
                    anchorSelect=".anchor-element"
                    place="top"
                    style={{ background: "#FFF", color: "#3989f9" }}
                  >
                    Rewards
                  </Tooltip>
                  <a href="/reward" className="anchor-element">
                    <div class="win-top-balance-4 ms-2 ">
                      <span>
                        <IoTrophyOutline className="win-top-icon-1" />
                      </span>
                    </div>
                  </a>
                </div>

                <div class="win-top-balance-2 order-xl-1 order-2 order-md-1 mobile-hidden-swiper-arrows d-md-block">
                  <button
                    type="button"
                    class="win-top-balance-image win-top-leftarrow"
                    id="prev_slide"
                  >
                    <img src={leftarrow} className=" me-4" />
                  </button>
                  <img
                    src={Ticket}
                    className="win-card-balance-image "
                    onClick={goToFirstPage}
                    style={{ cursor: "pointer" }}
                  />
                  <button
                    type="button"
                    class="win-top-balance-image win-top-leftarrow"
                    id="next_slide"
                  >
                    <img src={rightarrow} className=" me-4" />
                  </button>
                </div>
                <div class="order-xl-2 order-1 order-md-2">
                  <div class="d-flex flex-row align-item-center">
                    <div class="win-top-balance-3 me-2">
                      <span className="win-text-25">{timer}</span>
                      <span className="win-text-24">5m</span>
                      <img className="win-card-clock-image" src={clock} />
                    </div>
                    <div class="win-top-balance-4 me-2">
                      <Tooltip
                        anchorSelect=".anchor-element-faq"
                        place="top"
                        style={{ background: "#FFF", color: "#3989f9" }}
                      >
                        guideline
                      </Tooltip>
                      <span>
                        <a
                          href={pdf}
                          target="_blank"
                          className="anchor-element-faq"
                        >
                          {" "}
                          <AiOutlineQuestionCircle className="win-top-icon-1" />{" "}
                        </a>
                      </span>
                    </div>
                    <Tooltip
                      anchorSelect=".anchor-element-lead"
                      place="top"
                      style={{ background: "#FFF", color: "#3989f9" }}
                    >
                      Leaderboard
                    </Tooltip>
                    <a href="/leadership" className="anchor-element-lead">
                      <div class="win-top-balance-4 me-2">
                        <span>
                          <MdOutlineLeaderboard className="win-top-icon-1" />
                        </span>
                      </div>
                    </a>
                    {/* <div class="win-top-balance-5">
											<span>
												<AiOutlineClockCircle
													className="win-top-icon-2"
													data-bs-toggle="offcanvas"
													data-bs-target="#offcanvashistroypre"
													aria-controls="offcanvasRight"
													style={{ cursor: "pointer" }}
												/>
											</span> */}
                    {/* </div> */}
                  </div>
                </div>
              </div>
              <br />

              {/* <h5>{seconds} seconds</h5> */}

              {imageloader ? (
                <div
                  className="text-center"
                  style={{ paddingTop: "60px", paddingBottom: "60px" }}
                >
                  {" "}
                  <img
                    src={Loader}
                    className="loader-gif-new-pre"
                    alt="Loader"
                  />
                </div>
              ) : (
                <Swiper
                  onSwiper={setSwiper}
                  direction={"horizontal"}
                  // slidesPerView={1}
                  spaceBetween={30}
                  // initialSlide={1}
                  freeMode={true}
                  // loop={true}
                  mousewheel={true}
                  // autoplay={{
                  // 	delay:1000,
                  // 	disableOnInteraction:false
                  // }}
                  breakpoints={{
                    160: {
                      slidesPerView: 1,
                      spaceBetween: 10,
                      initialSlide: 4,
                    },
                    320: {
                      slidesPerView: 1,
                      spaceBetween: 10,
                      initialSlide: 4,
                    },
                    640: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                      initialSlide: 4,
                    },
                    768: {
                      slidesPerView: 2,
                      spaceBetween: 30,
                      initialSlide: 4,
                    },
                    1100: {
                      slidesPerView: 3,
                      spaceBetween: 30,
                      initialSlide: 3,
                    },
                    1200: {
                      slidesPerView: 4,
                      spaceBetween: 30,
                      initialSlide: 4,
                    },
                    1400: {
                      slidesPerView: 5,
                      spaceBetween: 30,
                      initialSlide: 3,
                    },
                  }}
                  // navigation={true}
                  navigation={{
                    prevEl: "#prev_slide",
                    nextEl: "#next_slide",
                  }}
                  modules={[Navigation, Mousewheel]}
                  className="mySwiper"
                >
                  {firstRoundDetails.map((data, index) => (
                    <SwiperSlide>
                      <div class="flip-card flip-card-expired-status">
                        <div class="flip-card-inner">
                          <div class="flip-card-front">
                            <div class="d-flex flex-row win-section-top-1">
                              <div class="">
                                <span>
                                  <VscCircleSlash className="win-icon-1" />
                                  <span className="win-text-1">Expired</span>
                                </span>
                              </div>
                              <div class="ms-auto">
                                <span className="win-text-1">
                                  #{data.round}
                                </span>
                              </div>
                            </div>

                            <div
                              className={`d-flex flex-column win-section-top-2 ${
                                data.predictionrate / 1e18 > 0
                                  ? "up-bg-active"
                                  : ""
                              }`}
                              style={predictionStyle}
                            >
                              <div class="text-center mt-auto">
                                <p className="win-text-2">UP</p>
                                <span className="win-text-3">{firstbull}x</span>
                                <span className="win-text-4">Payout</span>
                              </div>
                            </div>
                            <div class="win-section-top-4">
                              <div className="win-section-top-41">
                                <div class="d-flex flex-row mb-1">
                                  <span className="win-text-19">
                                    Closed Price
                                  </span>
                                </div>
                                <div class="d-flex flex-row">
                                  <div class="">
                                    <span className="win-text-10">
                                      {(data.closingprice / 1e18).toFixed(2)}
                                    </span>
                                  </div>
                                  <div class="ms-auto">
                                    <span
                                      className={`win-text-20 ${
                                        data.predictionrate / 1e18 <= 0
                                          ? "down-bg"
                                          : "up-bg"
                                      }`}
                                      style={predictionStyle}
                                    >
                                      {data.predictionrate <= 0 ? (
                                        <AiOutlineArrowDown className="win-icon-5" />
                                      ) : (
                                        <AiOutlineArrowUp className="win-icon-5" />
                                      )}
                                      ${(data.predictionrate / 1e18).toFixed(3)}
                                    </span>
                                    {/* <span className="win-text-20 up-bg">
																		<AiOutlineArrowUp className="win-icon-5" />$
																		{data.predictionrate / 1e18}
																	</span> */}
                                  </div>
                                </div>

                                <div class="d-flex flex-row mt-4">
                                  <div class="">
                                    <span className="win-text-21">
                                      Locked Price:
                                    </span>
                                  </div>
                                  <div class="ms-auto">
                                    <span className="win-text-21">
                                      {(data.lockingPrice / 1e18).toFixed(2)}
                                    </span>
                                  </div>
                                </div>

                                <div class="d-flex flex-row">
                                  <div class="">
                                    <span className="win-text-22">
                                      Prize Pool:
                                    </span>
                                  </div>
                                  <div class="ms-auto">
                                    <span className="win-text-22">
                                      ${data.totalpoolprice / 1e18} dcx
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className={`d-flex flex-column win-section-top-3 ${
                                data.predictionrate / 1e18 <= 0
                                  ? "down-bg-active"
                                  : ""
                              }`}
                              style={predictionStyle}
                            >
                              <div class="text-center">
                                <span className="win-text-7">{firstbear}x</span>
                                <span className="win-text-8">Payout</span>
                                <p className="win-text-6">DOWN</p>
                              </div>
                            </div>
                            {firstennter && (
                              <div className="win-section-top-3-1">
                                <a
                                  data-tooltip-id="my-tooltip"
                                  data-tooltip-content={
                                    firstwin == true ? "You Win" : "Loss"
                                  }
                                >
                                  <img
                                    src={firstwin == true ? wingif : losegif}
                                    alt={firstwin == true ? "Win" : "Lose"}
                                  />
                                </a>
                                <Tooltip
                                  id="my-tooltip"
                                  style={{
                                    background:
                                      "radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #ad8324 62.5%, #D1B464 100%)",
                                    color: "#FFF",
                                    opacity: 1,
                                    fontSize: "14px",
                                    willChange: "none",
                                    backdropFilter: "blur(44px)",
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}

                  {secondCardDetails.map((data, index) => (
                    <SwiperSlide>
                      <div class="flip-card flip-card-expired-status">
                        <div class="flip-card-inner">
                          <div class="flip-card-front">
                            <div class="d-flex flex-row win-section-top-1">
                              <div class="">
                                <span>
                                  <VscCircleSlash className="win-icon-1" />
                                  <span className="win-text-1">prev</span>
                                </span>
                              </div>
                              <div class="ms-auto">
                                <span className="win-text-1">
                                  #{data.round}
                                </span>
                              </div>
                            </div>
                            {/* <div class="progress">
                          <div class="progress-bar" role="progressbar" aria-label="Basic example" style={{ width: "25%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                        </div> */}
                            <div
                              className={`d-flex flex-column win-section-top-2 ${
                                data.predictionrate / 1e18 > 0
                                  ? "up-bg-active"
                                  : ""
                              }`}
                              style={predictionStyle}
                            >
                              <div class="text-center mt-auto">
                                <p className="win-text-2">UP</p>
                                <span className="win-text-3">
                                  {secondbull}x
                                </span>
                                <span className="win-text-4">Payout</span>
                              </div>
                            </div>
                            <div class="win-section-top-4">
                              <div className="win-section-top-41">
                                <div class="d-flex flex-row mb-1">
                                  <span className="win-text-19">
                                    Closed Price
                                  </span>
                                </div>
                                <div class="d-flex flex-row">
                                  <div class="">
                                    <span className="win-text-10">
                                      ${(data.closingprice / 1e18).toFixed(2)}
                                    </span>
                                  </div>
                                  <div class="ms-auto">
                                    <span
                                      className={`win-text-20 ${
                                        data.predictionrate / 1e18 <= 0
                                          ? "down-bg"
                                          : "up-bg"
                                      }`}
                                      style={predictionStyle}
                                    >
                                      {data.predictionrate <= 0 ? (
                                        <AiOutlineArrowDown className="win-icon-5" />
                                      ) : (
                                        <AiOutlineArrowUp className="win-icon-5" />
                                      )}
                                      ${(data.predictionrate / 1e18).toFixed(3)}
                                    </span>
                                  </div>
                                </div>

                                <div class="d-flex flex-row mt-4">
                                  <div class="">
                                    <span className="win-text-21">
                                      Locked Price:
                                    </span>
                                  </div>
                                  <div class="ms-auto">
                                    <span className="win-text-21">
                                      ${(data.lockingPrice / 1e18).toFixed(2)}
                                    </span>
                                  </div>
                                </div>

                                <div class="d-flex flex-row">
                                  <div class="">
                                    <span className="win-text-22">
                                      PrizePool:
                                    </span>
                                  </div>
                                  <div class="ms-auto">
                                    <span className="win-text-22">
                                      ${data.totalpoolprice / 1e18} dcx
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className={`d-flex flex-column win-section-top-3 ${
                                data.predictionrate / 1e18 <= 0
                                  ? "down-bg-active"
                                  : ""
                              }`}
                              style={predictionStyle}
                            >
                              <div
                                class={`text-center${
                                  data.predictionrate / 1e18 <= 0
                                    ? "down-bg"
                                    : ""
                                }`}
                                style={predictionStyle}
                              >
                                <span className="win-text-7">
                                  {secondbear}x
                                </span>
                                <span className="win-text-8">Payout</span>
                                <p className="win-text-6">DOWN</p>
                              </div>
                            </div>
                            {secondenter && (
                              <div className="win-section-top-3-1">
                                <a
                                  data-tooltip-id="my-tooltip"
                                  data-tooltip-content={
                                    secondwin == true ? "You Win" : "Loss"
                                  }
                                >
                                  <img
                                    src={secondwin == true ? wingif : losegif}
                                    alt={secondwin == true ? "Win" : "Lose"}
                                  />
                                </a>
                                <Tooltip
                                  id="my-tooltip"
                                  style={{
                                    background:
                                      "radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #ad8324 62.5%, #D1B464 100%)",
                                    color: "#FFF",
                                    opacity: 1,
                                    fontSize: "14px",
                                    willChange: "none",
                                    backdropFilter: "blur(44px)",
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}

                  {isloader ? (
                    <SwiperSlide>
                      <div class="flip-card ">
                        <div class="flip-card-inner ">
                          <div class="flip-card-front">
                            <div class="d-flex flex-row win-section-top-1">
                              <div class="">
                                <span>
                                  <AiOutlineFieldTime className="win-icon-2" />
                                  <span className="win-text-1">Live</span>
                                </span>
                              </div>
                              <div class="ms-auto">
                                {/* <span className="win-text-1">#{secondlater}</span> */}
                              </div>
                            </div>

                            <div class="d-flex flex-column win-section-top-2">
                              <div class="text-center mt-auto">
                                {/* <p className="win-text-11">UP</p> */}
                              </div>
                            </div>
                            <div class="win-section-top-4">
                              <div className="d-flex  flex-row win-section-top-41 align-items-center">
                                <img
                                  src={Loader}
                                  className="loader-gif-new-pre"
                                  alt="Loader"
                                />
                              </div>
                            </div>
                            <div class="d-flex flex-column win-section-top-3">
                              <div class="text-center">
                                {/* <p className="win-text-12">DOWN</p> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  ) : (
                    <SwiperSlide>
                      <div class="flip-card entered-flip-card-css">
                        <div class="flip-card-inner">
                          <div class="flip-card-front">
                            <div class="d-flex flex-row win-section-top-1">
                              <div class="">
                                <span>
                                  <AiOutlinePlayCircle className="win-icon-1" />
                                  <span className="win-text-1">Live</span>
                                </span>
                              </div>
                              <div class="ms-auto">
                                <span className="win-text-1">
                                  #{currentRoundId - 1}
                                </span>
                              </div>
                            </div>
                            <div>
                              <ProgressBar now={calculateProgress()} />
                            </div>
                            <div
                              className={`d-flex flex-column win-section-top-2 ${
                                predictrate / 1e18 > 0 ? "up-bg-active" : ""
                              }`}
                              style={predictionStyle}
                            >
                              <div class="text-center mt-auto">
                                <p className="win-text-2">UP</p>
                                <span className="win-text-3">{livebull}x</span>
                                <span className="win-text-4">Payout</span>
                              </div>
                            </div>

                            <div class="win-section-top-4">
                              <div className="win-section-top-41">
                                <div class="d-flex flex-row mb-1">
                                  <span className="win-text-19">
                                    LAST PRICE
                                  </span>
                                </div>
                                <div class="d-flex flex-row">
                                  <div class="">
                                    <span className="win-text-10">
                                      ${Number(dcxprice).toFixed(2)}
                                    </span>
                                  </div>
                                  <div class="ms-auto">
                                    <span
                                      className={`win-text-20 ${
                                        predictrate <= 0 ? "down-bg" : "up-bg"
                                      }`}
                                      style={predictionStyle}
                                    >
                                      {predictrate <= 0 ? (
                                        <AiOutlineArrowDown className="win-icon-5" />
                                      ) : (
                                        <AiOutlineArrowUp className="win-icon-5" />
                                      )}
                                      ${predictrate}
                                    </span>
                                  </div>
                                </div>

                                <div class="d-flex flex-row mt-4">
                                  <div class="">
                                    <span className="win-text-21">
                                      Locked Price:
                                    </span>
                                  </div>
                                  <div class="ms-auto">
                                    <span className="win-text-21">
                                      ${startprice.toFixed(2)}
                                    </span>
                                  </div>
                                </div>

                                <div class="d-flex flex-row">
                                  <div class="">
                                    <span className="win-text-22">
                                      Prize Pool:
                                    </span>
                                  </div>
                                  <div class="ms-auto">
                                    <span className="win-text-22">
                                      {livetotalpool} DCX
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              className={`d-flex flex-column win-section-top-3 ${
                                predictrate / 1e18 <= 0 ? "down-bg-active" : ""
                              }`}
                              style={predictionStyle}
                            >
                              <div class="text-center">
                                <span className="win-text-7">{livebear}x</span>
                                <span className="win-text-8">Payout</span>
                                <p className="win-text-6">DOWN</p>
                              </div>
                            </div>
                            {liveenter ? (
                              <div className="win-section-top-3-1">
                                <a
                                  data-tooltip-id="my-tooltip"
                                  data-tooltip-content="Entered"
                                >
                                  <PiSealCheckFill />
                                </a>
                                <Tooltip
                                  id="my-tooltip"
                                  style={{
                                    background:
                                      "radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #ad8324 62.5%, #D1B464 100%)",
                                    color: "#FFF",
                                    opacity: 1,
                                    fontSize: "14px",
                                    willChange: "none",
                                    backdropFilter: "blur(44px)",
                                  }}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  )}

                  <SwiperSlide>
                    <div
                      class={
                        isloader == true
                          ? "flip-card flip-card-deactive"
                          : "flip-card"
                      }
                    >
                      <div class="flip-card-inner flip-card-section-1">
                        <div class="flip-card-front">
                          <div class="d-flex flex-row win-section-top-1">
                            <div class="">
                              <span>
                                <AiOutlinePlayCircle className="win-icon-1" />
                                <span className="win-text-1">Next</span>
                              </span>
                            </div>
                            <div class="ms-auto">
                              <span className="win-text-1">
                                #{Number(currentRoundId)}
                              </span>
                            </div>
                          </div>
                          {/* <div class="progress">
                            <div class="progress-bar" role="progressbar" aria-label="Basic example" style={{ width: "25%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                          </div> */}
                          <div class="d-flex flex-column win-section-top-2">
                            <div class="text-center mt-auto">
                              <p className="win-text-2">UP</p>
                              <span className="win-text-3">{currentbull}x</span>
                              <span className="win-text-4">Payout</span>
                            </div>
                          </div>
                          <div class="win-section-top-4">
                            <div className="win-section-top-41">
                              <div class="text-center">
                                <div class="d-flex flex-row mb-1">
                                  <div class="">
                                    <span className="win-text-5">
                                      Prize Pool:
                                    </span>
                                  </div>
                                  <div class="ms-auto">
                                    <span className="win-text-5">
                                      {totalpoolprice} DCX
                                    </span>
                                  </div>
                                </div>
                                <div class="d-grid gap-2">
                                  <button
                                    class="btn-win-up btn-win-up-1"
                                    type="button"
                                    onClick={() => enterup("UP")}
                                  >
                                    Enter UP
                                  </button>
                                  <button
                                    class="btn-win-down btn-win-down-1"
                                    type="button"
                                    onClick={() => enterdown("DOWN")}
                                  >
                                    Enter DOWN
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="d-flex flex-column win-section-top-3">
                            <div class="text-center">
                              <span className="win-text-7">{currentbear}x</span>
                              <span className="win-text-8">Payout</span>
                              <p className="win-text-6">DOWN</p>
                            </div>
                          </div>
                          {currententer ? (
                            <div className="win-section-top-3-1">
                              <a
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content="Entered"
                              >
                                <PiSealCheckFill />
                              </a>
                              <Tooltip
                                id="my-tooltip"
                                style={{
                                  background:
                                    "radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #ad8324 62.5%, #D1B464 100%)",
                                  color: "#FFF",
                                  opacity: 1,
                                  fontSize: "14px",
                                  willChange: "none",
                                  backdropFilter: "blur(44px)",
                                }}
                              />
                            </div>
                          ) : (
                            ""
                          )}
                        </div>

                        <div class="flip-card-back">
                          <div class="d-flex flex-row win-section-top-5">
                            <div class="">
                              <span>
                                <AiOutlineArrowLeft className="win-icon-3" />
                                <span className="win-text-14">
                                  Set Position
                                </span>
                              </span>
                            </div>
                            <div class="ms-auto">
                              {upcard == "UP" ? (
                                <button
                                  type="button"
                                  className="btn-back-win-up"
                                  onClick={changePosition}
                                >
                                  <AiOutlineArrowUp className="win-icon-4" /> UP
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  className="btn-back-win-down"
                                  onClick={changePosition}
                                >
                                  <AiOutlineArrowDown className="win-icon-4" />{" "}
                                  DOWN
                                </button>
                              )}

                              {/* <button type="button" class="btn-back-win-up" ><AiOutlineArrowUp className='win-icon-4' />{upcard == "UP" ?  "UP" : "DOWN"}</button>  */}

                              {/* <button type="button" class="btn-back-win-down" ><AiOutlineArrowDown className='win-icon-4' />{upcard == "DOWN" ? "DOWN" : "UP"}</button> */}
                            </div>
                          </div>
                          <div className="flip-card-back-margin">
                            <div class="d-flex flex-row mt-3 mb-2">
                              <div class="">
                                <span className="win-text-15">Commit:</span>
                              </div>
                              <div class="ms-auto win-text-image-1 d-flex align-items-center">
                                <span className="">
                                  <img
                                    class="logo-dark"
                                    src={logoblack}
                                    alt="logo"
                                  />
                                  {/* <img class="logo-white" src="" alt="logo" /> */}
                                </span>
                                <span className="win-text-16">DCX</span>
                              </div>
                            </div>
                            {isdisable ? <h5>bet Booked</h5> : ""}
                            <input
                              type="text"
                              class={`form-control-win-section ${
                                isdisable ? "disabled" : ""
                              }`}
                              placeholder="0"
                              onChange={(e) => getvaluechange(e)}
                              value={targetval}
                              disabled={isdisable}
                            />
                            <div class="d-flex flex-row">
                              <div class="ms-auto">
                                <span className="win-text-17">
                                  Balance: {userethbalance}
                                </span>
                              </div>
                            </div>
                            {rangeerror && (
                              <span
                                style={{
                                  color: "red",
                                  lineHeight: 1,
                                  padding: 20,
                                  fontSize: "13px",
                                }}
                              >
                                Insufficient balance
                              </span>
                            )}
                            <div class="swiper-no-swiping mt-2 mb-4 mx-3">
                              <Range
                                values={[dragvalues]}
                                min={MIN}
                                max={MAX}
                                step={STEP}
                                onChange={(e) => {
                                  percentageamountdrag(e);
                                }}
                                renderTrack={({ props, children }) => (
                                  <div
                                    onMouseDown={props.onMouseDown}
                                    onTouchStart={props.onTouchStart}
                                    style={{
                                      ...props.style,
                                      height: "25px",
                                      display: "flex",
                                      width: "100%",
                                    }}
                                  >
                                    <div
                                      ref={props.ref}
                                      style={{
                                        height: "5px",
                                        width: "100%",
                                        borderRadius: "4px",
                                        background: getTrackBackground({
                                          values: [dragvalues],
                                          colors: ["#20b3e0", "#ccc"],
                                          min: MIN,
                                          max: MAX,
                                        }),
                                        alignSelf: "center",
                                      }}
                                    >
                                      {children}
                                    </div>
                                  </div>
                                )}
                                renderThumb={({ props, isDragged }) => (
                                  <>
                                    <div
                                      {...props}
                                      style={{
                                        ...props.style,
                                        height: "18px",
                                        width: "18px",
                                        borderRadius: "50%",
                                        backgroundColor: "#20b3e0",
                                        outline: "none",
                                      }}
                                    >
                                      <span className="range-value-text">
                                        {" "}
                                        {[dragvalues]}%
                                      </span>
                                    </div>
                                  </>
                                )}
                              />
                            </div>
                            <div class="d-flex flex-row bd-highlight mb-3">
                              <button
                                type="button"
                                class="btn-back-invest-button"
                                onClick={() => percentamount("10")}
                              >
                                10%
                              </button>
                              <button
                                type="button"
                                class="btn-back-invest-button"
                                onClick={() => percentamount("25")}
                              >
                                25%
                              </button>
                              <button
                                type="button"
                                class="btn-back-invest-button"
                                onClick={() => percentamount("50")}
                              >
                                50%
                              </button>
                              <button
                                type="button"
                                class="btn-back-invest-button"
                                onClick={() => percentamount("75")}
                              >
                                75%
                              </button>
                              <button
                                type="button"
                                class="btn-back-invest-button"
                                onClick={() => percentamount("max")}
                              >
                                Max
                              </button>
                            </div>
                            <button
                              class={`btn-win-enable ${
                                isdisable ? "disabled" : ""
                              }`}
                              type="button"
                              onClick={predictionBet}
                              disabled={buttonloader || isdisable}
                            >
                              {buttonloader ? (
                                <img
                                  className="w-auto mx-auto Button-loader-small"
                                  src={Loader}
                                />
                              ) : (
                                "CONFIRM BET"
                              )}
                            </button>
                            <div
                              class="d-flex flex-row text-start"
                              style={{ marginTop: "8px" }}
                            >
                              <span className="win-text-18">
                                You won’t be able to remove or change your
                                position once you enter it.
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>

                  <SwiperSlide>
                    <div class="flip-card flip-card-expired-status ">
                      <div class="flip-card-inner ">
                        <div class="flip-card-front">
                          <div class="d-flex flex-row win-section-top-1">
                            <div class="">
                              <span>
                                <AiOutlineFieldTime className="win-icon-2" />
                                <span className="win-text-1">Later</span>
                              </span>
                            </div>
                            <div class="ms-auto">
                              <span className="win-text-1">
                                #{Number(currentRoundId) + 1}
                              </span>
                            </div>
                          </div>
                          {/* <div class="progress">
                            <div class="progress-bar" role="progressbar" aria-label="Basic example" style={{ width: "25%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                          </div> */}
                          <div class="d-flex flex-column win-section-top-2">
                            <div class="text-center mt-auto">
                              <p className="win-text-11">UP</p>
                            </div>
                          </div>
                          <div class="win-section-top-4">
                            <div className="d-flex  flex-row win-section-top-41 align-items-center">
                              <div class="mx-auto">
                                <span className="win-text-13 text-center">
                                  Entry Starts
                                </span>
                                <br />
                                <span className="win-text-13 mb-0">
                                  ~<span id="ten-countdown">{timer}</span>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div class="d-flex flex-column win-section-top-3">
                            <div class="text-center">
                              <p className="win-text-12">DOWN</p>
                            </div>
                          </div>
                        </div>
                        <div class="flip-card-back">
                          <div class="d-flex flex-row win-section-top-1">
                            <div class="">
                              <span>
                                <AiOutlinePlayCircle className="win-icon-1" />
                                <span className="win-text-1">Next</span>
                              </span>
                            </div>
                            <div class="ms-auto">
                              <span className="win-text-1">#23213</span>
                            </div>
                          </div>
                          <div class="progress">
                            <div
                              class="progress-bar"
                              role="progressbar"
                              aria-label="Basic example"
                              style={{ width: "25%" }}
                              aria-valuenow="25"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>

                  <SwiperSlide>
                    <div class="flip-card flip-card-expired-status">
                      <div class="flip-card-inner ">
                        <div class="flip-card-front">
                          <div class="d-flex flex-row win-section-top-1">
                            <div class="">
                              <span>
                                <AiOutlineFieldTime className="win-icon-2" />
                                <span className="win-text-1">Later</span>
                              </span>
                            </div>
                            <div class="ms-auto">
                              <span className="win-text-1">
                                #{Number(currentRoundId) + 2}
                              </span>
                            </div>
                          </div>

                          <div class="d-flex flex-column win-section-top-2">
                            <div class="text-center mt-auto">
                              <p className="win-text-11">UP</p>
                            </div>
                          </div>
                          <div class="win-section-top-4">
                            <div className="d-flex  flex-row win-section-top-41 align-items-center">
                              <div class="mx-auto">
                                <span className="win-text-13 text-center">
                                  Entry Starts
                                </span>
                                <br />
                                <span className="win-text-13 mb-0">
                                  ~<span id="ten-countdown">{nexttime}</span>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div class="d-flex flex-column win-section-top-3">
                            <div class="text-center">
                              <p className="win-text-12">DOWN</p>
                            </div>
                          </div>
                        </div>
                        <div class="flip-card-back">
                          <div class="d-flex flex-row win-section-top-1">
                            <div class="">
                              <span>
                                <AiOutlinePlayCircle className="win-icon-1" />
                                <span className="win-text-1">Next</span>
                              </span>
                            </div>
                            <div class="ms-auto">
                              <span className="win-text-1">#23213</span>
                            </div>
                          </div>
                          <div class="progress">
                            <div
                              class="progress-bar"
                              role="progressbar"
                              aria-label="Basic example"
                              style={{ width: "25%" }}
                              aria-valuenow="25"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              )}
              <div
                className="new-win-offcanvas-postion"
                style={{ padding: "10px" }}
              >
                <button
                  class="win-top-balance-2-new border-0 text-white"
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasTradingview"
                  aria-controls="offcanvasBottom"
                >
                  BarChart
                </button>
                &nbsp;&nbsp;&nbsp;
                <button
                  class="win-top-balance-2-new border-0 text-white"
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasChainlink"
                  aria-controls="offcanvasBottom"
                >
                  Chainlink
                </button>
              </div>

              <div
                class="offcanvas offcanvas-bottom"
                tabindex="-1"
                id="offcanvasTradingview"
                aria-labelledby="offcanvasBottomLabel"
              >
                <div class="offcanvas-header">
                  <h5 class="offcanvas-title" id="offcanvasExampleLabel"></h5>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="offcanvas-body small">
                  <Tradingview />
                </div>
              </div>
              <div
                class="offcanvas offcanvas-bottom"
                tabindex="-1"
                id="offcanvasChainlink"
                aria-labelledby="offcanvasBottomLabel"
              >
                <div class="offcanvas-header">
                  <h5 class="offcanvas-title" id="offcanvasExampleLabel"></h5>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="offcanvas-body small">
                  <Chainlink />
                </div>
              </div>

              <div
                class="new-predic-histroy-offcanvas offcanvas offcanvas-end"
                tabindex="-1"
                id="offcanvashistroypre"
                aria-labelledby="offcanvasRightLabel"
              >
                <div class="offcanvas-header">
                  <h4 class="fw-bold" id="offcanvasRightLabel" className="mb-0">
                    History{" "}
                  </h4>
                  <button
                    type="button"
                    class="bg-transparent border-0 text-white"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  >
                    Close <AiOutlineArrowRight />
                  </button>
                </div>
                <Roundhistry />
              </div>
            </div>
          </div>
        </div>
        <div class=" pt-lg-2 d-flex flex-lg-row flex-wrap gap-3 d-md-none d-lg-none justify-content-center align-items-center">
          <div class="win-top-balance-2   ">
            <button
              type="button"
              class="win-top-balance-image win-top-leftarrow"
              id="prev_slide"
            >
              <img src={leftarrow} className=" me-4" />
            </button>
            <img
              src={Ticket}
              className="win-card-balance-image "
              onClick={goToFirstPage}
              style={{ cursor: "pointer" }}
            />
            <button
              type="button"
              class="win-top-balance-image win-top-leftarrow"
              id="next_slide"
            >
              <img src={rightarrow} className=" me-4" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Win;
